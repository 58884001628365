import React, { Component } from "react";
import { connect } from "react-redux";
import Firms from 'components/Firms';

import "./FirmPage.scss";

class FirmPage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <div className="FirmPage">
                <div className="header"></div>
                <div className="firms">
                    <Firms yax={this.props.yax} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    selectedInstrument: state.selectedInstrument
});
export default connect(mapStateToProps)(FirmPage);
