import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Root from "./Root";
import * as serviceWorker from "./serviceWorker";
import packageJson from "../package.json";

/* Bootstrap v4.4.1 */
import "bootstrap/dist/css/bootstrap.min.css";

/* AG-GRID */
/* since we have plugin support for SCSS we can include the file directly */
import "./styles/ag-grid.scss";
import "./styles/ag-theme-blue2/sass/ag-theme-blue.scss";

global.appVersion = packageJson.version;
console.log("Current YAX client version is " + global.appVersion);

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
