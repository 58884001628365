import React, { Component } from 'react';
import './TopBar.css';
import {connect} from 'react-redux';
// import SelectInstrument from './SelectInstrument';

class TopBar extends Component {

	constructor(props) {
		super(props);
		this.state = {
			market_state: "",
			current_message: "",
			current_time: "--:--"
		}

		this.instrumentList= [];

		this.timer = setInterval(() => {
			var currentdate = new Date();
			let y = currentdate.getFullYear();
			let months = currentdate.getMonth()+1;
			let M = ('0'+ months).slice(-2);
			let days = currentdate.getDate();
			let d = ('0'+ days).slice(-2);
			let hours = currentdate.getHours();
			let h = ('0'+ hours).slice(-2);
			let minutes = currentdate.getMinutes();
			let m = ('0'+ minutes).slice(-2);
			let seconds = currentdate.getSeconds();
			let s = ('0'+ seconds).slice(-2);

			var datetime = y + "-" + M + "-" + d + " " + h + ":" + m + ":" + s; 
			this.setState({current_time: datetime});
		}, 1000);

		// this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		let st = this.props.yax.getTradingState();
		if (this.state.market_state !== st.state) {
			this.setState({market_state: st.state});
		}

		this.props.yax.addMsgSubscriber("TradingStateEvent", (msg) => {
			if (this.state.market_state !== msg.state) {
				this.setState({market_state: msg.state});
			}
		});
	}

	componentDidUpdate(previousProps, previousState, snapshot) {
	}

	// handleThemeChange(evt) {
	// 	console.log(JSON.stringify(evt));
	// 	this.props.dispatch({type: "set_theme", "theme" : evt.value});
	// }

	render() {

		let market = process.env.REACT_APP_SERVERURL;
		market = market.trim();
		
		return (
			<div className="TopBar">
				<span className="state">Market on {market} is {this.state.market_state}</span>
				<span className="message">
				</span>
				
				<span className="time">{this.state.current_time}</span>
			</div>
		);
	}

	componentWillUnmount() {
		console.log("TopBar will unmount now");
		clearInterval(this.timer);
	}
}

// export default OrderLine;
const mapStateToProps = (state) => ({
	user:	state.user
});
export default connect(mapStateToProps)(TopBar);