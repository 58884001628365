import React, { Component } from "react";
import { connect } from "react-redux";

/* CUSTOM */
import "./OwnPositionForInstrument.scss";

class OwnPositionForInstrument extends Component {

	constructor(props) {
		super(props);

		this.state = {
            position: 0
		};
	}


	/* ------------------------------------------------------------------------ */

	static getDerivedStateFromProps(props, state) {
        if (props.selectedInstrument !== state.selectedInstrument) {

            let positions = props.yax.getAllPositions();
            let instrpos = positions.get(props.selectedInstrument);
            if (instrpos) {
                let pos = instrpos.position;
                return {
                    selectedInstrument: props.selectedInstrument,
                    position: pos
                };
            }
            else {
                return {
                    selectedInstrument: props.selectedInstrument,
                    position: 0
                };
            }
          }
    }

	/* ------------------------------------------------------------------------ */

	render() {
		return (
			<div className="OwnPositionForInstrument">
                <h5>Current position: {this.state.position}</h5>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	selectedInstrument: state.selectedInstrument
});
export default connect(mapStateToProps)(OwnPositionForInstrument);
