import React, { Component } from 'react';
import './ModifyOrder.scss';
import { connect } from 'react-redux';
import NumericInput from 'react-numeric-input';
import { Input } from 'react-bootstrap';
import Flag from "react-flags";

const uuid = require("uuid/v4");

class ModifyOrder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			instrumentId: "",
			tickSize: 1,
			price: 0,
			quantity: 0,
			isBuy: false,
			clientOrderId: "",
			submit_text: "",
			timeInForce: "DAY",
			visible_state: ""  // was default "hidden"
		}

		this.setPrice = this.setPrice.bind(this);
		this.setQuantity = this.setQuantity.bind(this);
		this.handleModify = this.handleModify.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.setTimeInForce = this.setTimeInForce.bind(this);
	}

	setPrice(evt) {
		console.log(evt);
		this.setState({
			price: evt
		},
			() => {
				console.log("price set");
			});
	}

	setQuantity(evt) {
		console.log(evt);
		this.setState({
			quantity: evt
		},
			() => {
				console.log("quantity set");
			});
	}

	setTimeInForce(evt) {
		console.log(evt);
		this.setState({
			timeInForce: evt.target.value
		},
			() => {
				console.log("timeinforce set");
			});
	}

	handleModify(event) {
		let o = {
			messageType: "ModifyOrderRequest",
			instrumentId: this.props.selectedOrder.instrumentId,
			clientOrderId: this.props.selectedOrder.clientOrderId,
			timeInForce: this.state.timeInForce,
			quantityType: "ABSOLUTE",
			price: this.state.price,
			quantity: this.state.quantity,
			orderType: "LIMIT"  // since we can't modify an market order anyway...
		};
		this.props.yax.sendModifyOrderRequest(o);
		event.preventDefault();
	}

	handleCancel(event) {
		this.props.yax.cancelOrder(this.props.selectedOrder.instrumentId, this.props.selectedOrder.clientOrderId);
		event.preventDefault();
	}

	/*
		if you need to change state from proerties multiple times, use this
	*/
	static getDerivedStateFromProps(nextProps, prevState) {

		// We detect a new order by checking the client order id
		if (nextProps.selectedOrder !== undefined && nextProps.selectedOrder.clientOrderId !== prevState.clientOrderId) {
			return {
				instrumentId: nextProps.selectedOrder.instrumentId,
				tickSize: nextProps.yax.getInstrument(nextProps.selectedOrder.instrumentId).tickSize,
				price: nextProps.selectedOrder.price,
				quantity: nextProps.selectedOrder.remainingQuantity,
				timeInForce: nextProps.selectedOrder.timeInForce,
				isBuy: nextProps.selectedOrder.isBuy,
				clientOrderId: nextProps.selectedOrder.clientOrderId,
			}
		}

		return null; // no change
	}

	generateNewClientorderId() {
		return uuid();
	}

	render() {

		return (
			<div className={"ModifyOrder " + this.state.visible_state}>
				{this.props.selectedOrder !== undefined ?

					<form onSubmit={this.handleSubmit} className="form-inline instrument">

						<label className="instrument">
							<Flag title="myflag" name="SE" format="png" pngSize={32} shiny={true} basePath="/img/flags" alt="Sweden" />
							{this.state.instrumentId}
						</label>

						<label>Qty:</label>
						<NumericInput className="qtyInput"
							step={1}
							precision={0}
							onChange={this.setQuantity}
							value={this.state.quantity} />

						<label>Price:</label>
						<NumericInput className="priceInput"
							step={this.state.tickSize}
							precision={2}
							onChange={this.setPrice}
							value={this.state.price} />

						<label>TimeInForce:</label>
						<select value={this.state.timeInForce} onChange={this.setTimeInForce}>
							<option value="DAY">DAY</option>
							<option value="GTC">GTC</option>
						</select>

						<div className="buysell">
							<button className="btn modifyButton" type="submit" onClick={this.handleModify}>Modify</button>
							<button className="btn cancelButton" type="submit" onClick={this.handleCancel}>Delete</button>
						</div>
					</form>
					:
					<div></div>
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({


});
export default connect(mapStateToProps)(ModifyOrder);