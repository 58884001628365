import React, { Component } from 'react';
import { FormCheck, Checkbox, Card, CardImg, Cardbody, Cardtitle, Cardsubtitle, Cardtext, Badge, ButtonToolbar, Row, Col, Form, Button } from "react-bootstrap";
import { withAlert } from 'react-alert'
import FormCheckInput from 'react-bootstrap/FormCheckInput';
import FormCheckLabel from 'react-bootstrap/FormCheckLabel';

/*  --------------------------------------------------------------------
    



    --------------------------------------------------------------------
*/
class SetUserProperties extends Component {

    constructor(props) {
        super(props);
        this.state = {
            validateOrders: true,
            soundOnTrade: false,
            soundOnMarketMessage: false
        };
        this.onChangeValidateOrders = this.onChangeValidateOrders.bind(this);
        this.onChangeSoundOnTrade = this.onChangeSoundOnTrade.bind(this);
        this.onChangeSoundOnMarketMessage = this.onChangeSoundOnMarketMessage.bind(this);

    }

    componentDidMount() {

        let validate = this.props.yax.getUserPropertyAsBoolean("validateOrders");
        let soundOnTrade = this.props.yax.getUserPropertyAsBoolean("soundOnTrade");
        let soundOnMarketMessage = this.props.yax.getUserPropertyAsBoolean("soundOnMarketMessage");
        console.log("*** PROP *** validate = " + validate + " of type " + typeof (validate));
        console.log("*** PROP *** soundOnTrade = " + soundOnTrade + " of type " + typeof (soundOnTrade));
        console.log("*** PROP *** soundOnMarketMessage = " + soundOnMarketMessage + " of type " + typeof (soundOnMarketMessage));
        this.setState({
            validateOrders: validate,
            soundOnTrade: soundOnTrade,
            soundOnMarketMessage: soundOnMarketMessage
        });
    }

    button(field, width, handler) {
        return (
            <Form.Group as={Col} xs={width} controlId={"form" + field}>
                <Button variant="primary" type="submit" onClick={handler}>
                    {field}
                </Button>
            </Form.Group>
        )
    }

    entry(field, width) {
        return (
            <Form.Group as={Col} xs={width} controlId={"form" + field}>
                <Form.Label>{field}</Form.Label>
                <Form.Control type="text" name={field} value={this.state[field]} onChange={this.onChange} />
            </Form.Group>
        )
    }

    // this.setState({
    //     [evt.target.name]: evt.target.value
    // },
    //     () => {
    //         console.log(this.state);
    //     });

    onChangeValidateOrders(evt) {
        console.log("*** PROP *** onChangeValidateOrders " + evt.target);
        this.setState({ validateOrders: evt.target.checked });
        this.props.yax.setUserPropertyBoolean("validateOrders", evt.target.checked);
    }

    onChangeSoundOnTrade(evt) {
        console.log("*** PROP *** onChangeSoundOnTrade " + evt.target);
        this.setState({ soundOnTrade: evt.target.checked });
        this.props.yax.setUserPropertyBoolean("soundOnTrade", evt.target.checked);
    }

    onChangeSoundOnMarketMessage(evt) {
        console.log("*** PROP *** onChangeSoundOnMarketMessage " + evt.target);
        this.setState({ soundOnMarketMessage: evt.target.checked });
        this.props.yax.setUserPropertyBoolean("soundOnMarketMessage", evt.target.checked);
    }

    nothing() {
        return <></>
    }

    // propertyInput() {

    //     return this.state.properties.map((item, index) => {
    //         console.log(item);
    //         console.log(index);
    //         return <Form.Group as={Col} controlId={"form" + item.key}>
    //             <Form.Label>{item.key}</Form.Label>
    //             <Form.Control type="checkbox" name={item.key} value={item.value} onChange={this.onChange(item.key)} />
    //         </Form.Group>
    //     });
    // }

    render() {
        return (
            <Form autoComplete="off" className="SetUserProperties">

                <Row>
                    <h5>User settings</h5>
                </Row>
                <Row>

                    <Form.Group as={Col} controlId={"formValidateOrders"}>
                        <Form.Check>
                            <FormCheckInput type='checkbox'
                                name={"validateOrders"}
                                checked={this.state.validateOrders}
                                onChange={this.onChangeValidateOrders} >
                            </FormCheckInput>
                            <FormCheckLabel >
                                {this.state.validateOrders ? "Verify orders" : "Do not verify orders"}
                            </FormCheckLabel>
                        </Form.Check>
                    </Form.Group>

                    <Form.Group as={Col} controlId={"formSoundOnTrade"}>
                        <Form.Check>
                            <FormCheckInput type='checkbox'
                                name={"soundOnTrade"}
                                checked={this.state.soundOnTrade}
                                onChange={this.onChangeSoundOnTrade} >
                            </FormCheckInput>
                            <FormCheckLabel >
                                {this.state.soundOnTrade ? "Sound on trade" : "No sound on trade"}
                            </FormCheckLabel>
                        </Form.Check>
                    </Form.Group>

                    <Form.Group as={Col} controlId={"formSoundOnMarketMessage"}>
                        <Form.Check>
                            <FormCheckInput type='checkbox'
                                name={"marketMessage"}
                                checked={this.state.soundOnMarketMessage}
                                onChange={this.onChangeSoundOnMarketMessage} >
                            </FormCheckInput>
                            <FormCheckLabel >
                                {this.state.soundOnMarketMessage ? "Sound on market message" : "No sound on market message"}
                            </FormCheckLabel>
                        </Form.Check>
                    </Form.Group>

                </Row>
                <Row>
                    <a>(If on, will validate orders with quantity >= 10 or prices outside range: 200 to 5000)</a>
                </Row>
            </Form >
        );
    }
}

export default withAlert()(SetUserProperties);