/*	-------------------------------------------------------------------------------




	-------------------------------------------------------------------------------
*/
import React, { Component } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import PriceBlinkRenderer from "renderers/PriceBlinkRenderer";
import { connect } from "react-redux";
import "./Users.scss";
import { withRouter } from "react-router-dom";
import Utils from "utils/utils";
import ButtonRenderer2 from "renderers/ButtonRenderer2";

/*	-------------------------------------------------------------------------------




	-------------------------------------------------------------------------------
*/
class Users extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modules: AllModules, // AllCommunityModules,
			rowData: [],
			components: {
				priceBlinkRenderer: PriceBlinkRenderer
			}
		};

		this.is_live = false;
		this.firms = [];
		this.handleUserEventUpdate = this.handleUserEventUpdate.bind(this);

		this.defaultColDef = {
			resizable: true,
			cellStyle: { "text-align": "right" },
			suppressMovable: true,
			suppressMenu: true
		};

		this.columnDefs = [
			{
				headerName: "Timestamp",
				headerTooltip: "Timestamp of last update",
				field: "timestamp",
				cellStyle: { "text-align": "left" },
				valueGetter: param => {
					return Utils.timeStampToStr(param.data.timestamp);
				}
			},
			// { headerName: "Seq", headerTooltip: "Sequence number", field: "sequenceNumber" },
			{
				headerName: "Firm",
				sortable: true,
				sort: "asc",
				sortingOrder: ["asc", "desc"],
				headerTooltip: "Firm",
				field: "firm",
				cellStyle: { "text-align": "left" },
				comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
					return valueA.localeCompare(valueB);
				}
				// filter: "agTextColumnFilter",
				// filterParams: { defaultOption: "startsWith", resetButton: true, applyButton: true, debounceMs: 200 }
			},
			{
				headerName: "Username",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				headerTooltip: "Username",
				field: "username",
				cellStyle: { "text-align": "left" },
				comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
					return valueA.localeCompare(valueB);
				}
			},
			{
				headerName: "Usertype",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				headerTooltip: "Usertype (Can be TRADER or ADMIN)",
				field: "type",
				cellStyle: params => {
					if (params.data.type === "ADMIN") {
						return { color: "red", "text-align": "left" };
					}
					return { color: "black", "text-align": "left" };
				},
				comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
					return valueA.localeCompare(valueB);
				}
			},
			{
				headerName: "Enabled",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				headerTooltip: "Enabled",
				field: "enabled",
				valueFormatter: param => {
					return param.data.enabled ? "Enabled" : "Disabled";
				},
				cellStyle: params => {
					if (!params.data.enabled === true) {
						return { color: "white", backgroundColor: "red", "text-align": "left" };
					}
					return { color: "black", backgroundColor: "transparent", "text-align": "left" };
				}
			},
			{
				headerName: "Logged in",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				headerTooltip: "Logged in",
				field: "loggedIn",
				cellStyle: { "text-align": "left" },
				valueFormatter: param => {
					return param.data.loggedIn === true ? "Logged in" : "";
				},
				cellStyle: params => {
					if (params.data.loggedIn === true) {
						return { color: "white", backgroundColor: "green", "text-align": "left" };
					}
					return { color: "black", backgroundColor: "transparent", "text-align": "left" };
				}
			},
			{
				headerName: "Session ID",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				headerTooltip: "Session ID",
				field: "sessionId",
				cellStyle: { "text-align": "left" }
			},
			{
				headerName: "Client Version",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				headerTooltip: "Client Version",
				field: "clientVersion",
				cellStyle: { "text-align": "left" }
			}
		];
	}

	render() {
		return (
			<div className="User">
				<div className="ga-header">
					<h5>{this.props.yax.isAdmin() ? "Users" : "User"}</h5>
					<a href="#" onClick={this.onNewUser.bind(this)}>
						Create new user
					</a>
				</div>

				<div className={"ga-grid " + this.props.view_options.theme}>
					<AgGridReact
						autoSizePadding={6}
						domLayout="autoHeight"
						/*** Definition ***/
						modules={this.state.modules}
						defaultColDef={this.defaultColDef}
						columnDefs={this.columnDefs}
						rowData={this.state.rowData}
						/*** Events ***/
						onGridReady={this.onGridReady.bind(this)}
						// rowSelection={"multiple"}
						// rowDeselection={true}
						/*** Customization ***/
						// components={this.state.components}
						// context={{}}
						onCellDoubleClicked={this.onGridCellDoubleClicked.bind(this)}
						getRowNodeId={data => {
							return data.firm + "/" + data.username;
						}}
						frameworkComponents={{
							buttonRenderer2: ButtonRenderer2
						}}
						getContextMenuItems={this.getContextMenuItems.bind(this)}
					/>
				</div>

				<a>Doubleclick to edit</a>
			</div>
		);
	}

	getContextMenuItems(params) {
		let that = this;
		var result = [
			{
				name: "Edit user",
				action: () => {
					window.localStorage.setItem("edit-user-firm", params.node.data.firm);
					window.localStorage.setItem("edit-user-username", params.node.data.username);
					window.localStorage.setItem("edit-user-enabled", params.node.data.enabled);
					window.localStorage.setItem("edit-user-usertype", params.node.data.type);
					this.props.history.push("/edit-user");
				}
			},
			{
				name: "Set password",
				action: () => {
					window.localStorage.setItem("edit-user-firm", params.node.data.firm);
					window.localStorage.setItem("edit-user-username", params.node.data.username);
					this.props.history.push("/set-user-password");
				},
				cssClasses: ["redFont", "bold"]
			},
			{
				name: "Cancel all orders for " + params.node.data.firm,
				action: () => {
					this.props.yax.sendCancelAllOrdersRequest(params.node.data.firm);
				},
				cssClasses: ["redFont", "bold"]
			}
		];
		return result;
	}

	autoSizeAll() {
		if (!this.gridColumnApi) {
			console.error("*** USER *** gridColumnApi not available yet!");
			return;
		}

		let allColumnIds = [];
		this.gridColumnApi.getAllColumns().forEach(function(column) {
			allColumnIds.push(column.colId);
		});
		this.gridColumnApi.autoSizeColumns(allColumnIds);

		// this.gridApi.sizeColumnsToFit();
	}

	onNewUser(evt) {
		evt.preventDefault();
		this.props.history.push("/new-user");
	}

	onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		console.log("*** USER *** onGridReady()");
		this.props.yax.addUserEventSubscriber(this.handleUserEventUpdate);
	};

	onGridCellDoubleClicked(params) {
		window.localStorage.setItem("edit-user-firm", params.data.firm);
		window.localStorage.setItem("edit-user-username", params.data.username);
		window.localStorage.setItem("edit-user-enabled", params.data.enabled);
		window.localStorage.setItem("edit-user-usertype", params.data.type);
		this.props.history.push("/edit-user");
	}

	handleUserEventUpdate(action, obj) {
		switch (action) {
			case "init":
			case "mod":
				let firm_user = obj.firm + "/" + obj.username;
				if (this.firms.includes(firm_user)) {
					this.gridApi.updateRowData({ update: [obj] });
				} else {
					this.gridApi.updateRowData({ add: [obj] });
					this.firms.push(firm_user);
				}

				if (this.is_live) {
					this.autoSizeAll();
				}
				break;

			case "live":
				this.is_live = true;
				this.autoSizeAll();
				break;
		}

		if (action !== "live") {
		}
	}

	componentDidMount() {}

	componentDidUpdate(previousProps, previousState) {}

	componentWillUnmount() {
		this.props.yax.removeUserEventSubscriber(this.handleUserEventUpdate);
	}
}

const mapStateToProps = state => ({
	view_options: state.view_options
});
export default connect(mapStateToProps)(withRouter(Users));
