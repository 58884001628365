import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardImg, Cardbody, Cardtitle, Cardsubtitle, Cardtext, Badge, ButtonToolbar, Row, Col, Form, Button } from "react-bootstrap";

class EditUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                firm: "",
                username: "",
                usertype: "",
                enabled: false,
                loggedIn: false,
                sessionId: ""
            },
            dirty: false
        };
    }

    componentDidMount() {
        // window.localStorage.setItem("edit-user", params.data.username);
        let f = window.localStorage.getItem("edit-user-firm");
        let u = window.localStorage.getItem("edit-user-username");
        let t = window.localStorage.getItem("edit-user-usertype");
        let e = window.localStorage.getItem("edit-user-enabled") === 'true';

        console.log("*** EDUS *** f=" + f + " u=" + u + " e=" + e + "t=" + t + "!");
        this.setState({
            user: {
                firm: f,
                username: u,
                usertype: t,
                enabled: e
            }
        });
    }

    onSetUserType(evt) {
        if (this.state.user.usertype !== evt.target.value) {
            this.setState({
                dirty: true,
                user: {
                    ...this.state.user,
                    usertype: evt.target.value
                }
            });
        }
    }

    onEnable(evt) {
        this.setState({
            dirty: true,
            user: {
                ...this.state.user,
                enabled: evt.target.checked
            }
        });
    }

    onSave(evt) {
        this.props.yax.sendModifyUserRequest(this.state.user.firm, this.state.user.username, this.state.user.usertype, this.state.user.enabled);
        this.props.history.push("/users");
    }

    onCancel(evt) {
        this.props.history.push("/users")
    }


    render() {

        console.log("*** EDUS ***");
        console.log(this.state.user);

        return (
            <Form className="demoForm">

                <div className="underlyingPart">

                    <Form.Row>

                        {/* these 2 are readonly */}

                        <Form.Group as={Col} controlId="formFirm">
                            <Form.Label>Firm</Form.Label>
                            <Form.Control value={this.state.user.firm} readOnly />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formUsername">
                            <Form.Label>User Name</Form.Label>
                            <Form.Control value={this.state.user.username} readOnly />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formUserType">
                            <Form.Label>User Type</Form.Label>
                            <Form.Control as="select" value={this.state.user.usertype} onChange={this.onSetUserType.bind(this)}>
                                <option key={1} value={"TRADER"}>Trader</option>
                                <option key={2} value={"ADMIN"}>Admin</option>
                                <option key={3} value={"OBSERVER"}>Observer</option>
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formEnabled">
                            <Form.Check type="checkbox" label="Enabled" checked={this.state.user.enabled} onChange={this.onEnable.bind(this)} />
                        </Form.Group>

                    </Form.Row>


                    <Form.Row>
                        {!this.state.dirty ?
                            <Form.Group as={Col} controlId="formUnderlyingButtons">
                                <Button variant="primary" onClick={this.onCancel.bind(this)} type="submit">Close</Button>
                            </Form.Group>
                            :
                            <>
                                <Form.Group as={Col} controlId="formUnderlyingButtons">
                                    <ButtonToolbar>
                                        <Button style={{ marginRight: "10px" }} variant="primary" onClick={this.onSave.bind(this)} type="submit">Save</Button>
                                        <Button variant="secondary" onClick={this.onCancel.bind(this)} type="submit">Cancel</Button>
                                    </ButtonToolbar>
                                </Form.Group>
                            </>}
                    </Form.Row>

                </div>

            </Form>
        );
    }
}


const mapStateToProps = state => ({
    selectedInstrument: state.selectedInstrument
});
export default connect(mapStateToProps)(withRouter(EditUser));
