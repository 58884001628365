import React, { Component } from 'react';
import { Container, Form, FormControl, Modal, Button } from 'react-bootstrap';
import NumericInput from 'react-numeric-input';
import Utils from "utils/utils";
const uuid = require("uuid/v4");

class PlaceOrderModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showModalPrice: 0,
            showModalQty: 0,
            validity: "DAY",
            comment: ""
        }

        this.handleSubmitBuy = this.handleSubmitBuy.bind(this);
        this.handleSubmitSell = this.handleSubmitSell.bind(this);

        this.myRef = React.createRef();
    }

    componentDidUpdate(previousProps, previousState, snapshot) {
		/*
			Component was updated
			If we detect a prop change, we update state from here!
			This will generate another componentDidupdate, but then we do nothing
		*/
        const newProps = this.props;
        let newState = {};
        let changed = false;

        if (previousProps.showModalPrice !== newProps.showModalPrice) {
            newState = {
                showModalPrice: newProps.showModalPrice,
            }
            changed = true;
        }
        if (previousProps.showModalQty !== newProps.showModalQty) {
            newState = { ...newState, showModalQty: newProps.showModalQty }
            changed = true;
        }

        if (changed) {
            this.setState({ ...newState });
        }
    }


    handleSubmitBuy(event) {
        this.sendOrder(true);
        event.preventDefault();
        this.props.hideMe();
    }

    handleSubmitSell(event) {
        this.sendOrder(false);
        event.preventDefault();
        this.props.hideMe();
    }

    sendOrder(isBuy) {
        let o = {
            instrumentId: this.props.instrumentId,
            price: this.state.showModalPrice,
            quantity: this.state.showModalQty,
            isBuy: isBuy,
            clientOrderId: this.generateNewClientorderId(),
            timeInForce: this.state.validity,
            text: this.state.comment,
            orderType: "LIMIT"
        };

        // NOTE: Booleans are stored as strings
        let validate = this.props.yax.getUserPropertyAsBoolean("validateOrders");
        if (validate) {
            let instr = this.props.yax.getInstrument(o.instrumentId);
            Utils.validateOrder(instr, o.isBuy, o.price, o.quantity,
                () => {
                    this.props.yax.sendInsertOrderRequest(o);
                },
                () => {
                    /* do nothing */
                });
        }
        else {
            this.props.yax.sendInsertOrderRequest(o);
        }
    }


    generateNewClientorderId() {
        return uuid();
    }

    componentDidMount() {

    }

    render() {
        let tickSize = 0.1; /* safeguard */
        let sellButtonStyle = this.props.title === "Buy" ? { "display": "none" } : {}
        let buyButtonStyle = this.props.title === "Sell" ? { "display": "none" } : {}

        // console.log("*** PLOM : render()! qty = " + this.state.showModalQty);

        return (
            <>
                {/* <Button variant="primary" onClick={() => {this.props.showMe()}}>Launch demo modal</Button> */}

                <Modal onEntered={() => this.myRef.current.focus()} autoFocus={false} show={this.props.showModal} onHide={() => this.props.hideMe()}>

                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title} {this.props.instrumentId}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body autoFocus={false} >

                        <Container>

                            <Form className="PlaceOrder">

                                <div className="quantitylabel">Quantity</div>

                                <Form.Group controlId="formQty1" className={"quantity"}>

                                    <Form.Control


                                        ref={this.myRef}
                                        name="quantity"
                                        type="number"
                                        step={1}
                                        min={0}
                                        precision={0}

                                        autoFocus={true}

                                        onChange={(evt) => {
                                            this.setState({
                                                showModalQty: evt.target.value
                                            });
                                        }}
                                        value={this.state.showModalQty} />
                                </Form.Group>


                                {
                                    /* Doesnt work with autoFocus! */
                                /* <Form.Group controlId="formQty" className={"quantity"}>
                                    <NumericInput className="form-control"

                                        ref={this.myRef}
                                        type="number"

                                        name="quantity"
                                        step={1}
                                        min={0}
                                        precision={0}

                                        onChange={(valueAsNumber, valueAsString, elem) => {

                                            console.log("Setting new qty = " + valueAsNumber);
                                            this.setState({
                                                showModalQty: valueAsNumber
                                            });
                                        }}
                                        value={this.state.showModalQty} />
                                </Form.Group> */}

                                <div className="pricelabel">Price</div>
                                <Form.Group controlId="formPrice" className={"price"}>
                                    <NumericInput className="form-control"
                                        name="price"
                                        step={tickSize}
                                        min={0}
                                        precision={2}

                                        onChange={(valueAsNumber, valueAsString, elem) => {
                                            this.setState({
                                                showModalPrice: valueAsNumber
                                            });
                                        }}
                                        value={this.state.showModalPrice} />
                                </Form.Group>

                                <div className="validitylabel">Valid until</div>
                                <Form.Group controlId="formValid" className={"validity"}>
                                    <Form.Control
                                        as="select"
                                        name="validity"
                                        value={this.state.validity}
                                        onChange={(evt) => {
                                            this.setState({
                                                validity: evt.target.value
                                            });
                                        }}
                                        type="text"
                                    >
                                        <option value="DAY">Today (DAY)</option>
                                        <option value="GTC">Until Cancelled (GTC)</option>
                                        <option value="FAK">Fill and Kill (FAK)</option>
                                        <option value="FOK">Fill or Kill (FOK)</option>
                                    </Form.Control>
                                </Form.Group>

                                <div className="commentlabel">Comment</div>
                                <Form.Group controlId="formComment" className={"comment"}>
                                    <FormControl
                                        type="text"
                                        name="comment"
                                        onChange={(evt) => {
                                            this.setState({
                                                comment: evt.target.value
                                            });
                                        }}
                                        value={this.state.comment} />
                                </Form.Group>

                            </Form>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <div style={buyButtonStyle}>
                            <Button className="btn buybutton" variant="primary" type="submit" onClick={this.handleSubmitBuy} >Buy</Button>
                        </div>
                        <div style={sellButtonStyle}>
                            <Button className="btn sellbutton" variant="primary" type="submit" onClick={this.handleSubmitSell} >
                                Sell
                        </Button>
                        </div>
                        <Button variant="secondary" onClick={() => this.props.hideMe()}>Close</Button>
                        {/* <Button variant="primary" onClick={() => this.props.hideMe()}>Save Changes</Button> */}
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default PlaceOrderModal;