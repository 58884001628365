import React, { Component } from "react";
import { connect } from "react-redux";
import Utils from 'utils/utils';

/* CUSTOM */
import "./ProductInfo.scss";

/*  --------------------------------------------------------------


      --------------------------------------------------------------
   */
class ProductInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidUpdate(previousProps, previousState, snapshot) {
		console.log("*** PRDI *** componentDidUpdate");
	}

	componentDidMount() {
		console.log("*** PRDI *** componentDidMount");
	}

	render() {

		let xx = this.props.yax.getInstrument(this.props.selectedInstrument);
    	let {instrumentId, name, country, publisher, site, position, iabCodes, contractSize, tickSize, lastTradingDate, lastSettlementDate} = xx;

		return (
			<div className="ProductInfo">

				<h5>Product information</h5>
				<table>
					<tbody>
						<tr>
							<td className="infolabel">Symbol</td>
							<td>{instrumentId}</td>
						</tr>
						<tr>
							<td className="infolabel">Name</td>
							<td>{name}</td>
						</tr>
						<tr>
							<td className="infolabel">Country</td>
							<td>{country}</td>
						</tr>
						<tr>
							<td className="infolabel">Publisher</td>
							<td>{publisher}</td>
						</tr>
						<tr>
							<td className="infolabel">Site</td>
							<td>{site}</td>
						</tr>
						<tr>
							<td className="infolabel">Position</td>
							<td>{position}</td>
						</tr>
						<tr>
							<td className="infolabel">Contract Size</td>
							<td>{contractSize}</td>
						</tr>
						<tr>
							<td className="infolabel">Tick Size</td>
							<td>{tickSize}</td>
						</tr>
						<tr>
							<td className="infolabel">IAB Category</td>
							<td>{iabCodes}</td>
						</tr>
						<tr>
							<td className="infolabel">LastTradingDate</td>
							<td>{Utils.timeStampToStr(lastTradingDate)} ({"UTC: " + lastTradingDate})</td>
						</tr>
						<tr>
							<td className="infolabel">LastSettlementDate</td>
							<td>{Utils.timeStampToStr(lastSettlementDate)} ({"UTC: " + lastSettlementDate})</td>
						</tr>

					</tbody>
				</table>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	selectedInstrument: state.selectedInstrument,
	selectedMarketOrder: state.selectedMarketOrder,
	view_options: state.view_options
});
export default connect(mapStateToProps)(ProductInfo);
