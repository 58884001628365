import moment from "moment";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class Utils {
	constructor(idObj) {}

	/**
	 * Validates order and shows a confirmation dialog if necessary
	 * @param   {Object} instr an instrument instance from the InstrumentDatabase
	 * @param   {boolean} is_buy true if this is a Buy order
	 * @param   {Number} price The price of the order
	 * @param   {Function} confirm_callback The callback that is called if a dialog is shown and the user presses Confirm
	 * @param   {Function} dismiss_callback The callback that is called if a dialog is shown and the user presses Dismiss
	 * @return  {boolean} Whether the order is validated for placement or not
	 */
	static validateOrder(instr, is_buy, price, qty, confirm_callback, dismiss_callback) {
		let need_confirm = false;
		let pct = 0.0;
		let message;

		/*	Use dead simple test for now
		 */
		if (price <= 200.0 || price >= 5000 || qty >= 10) {
			need_confirm = true;
		}

		/*
		if (is_buy) {
			let mktPrice = instr.lastPrice > 0.0 ? instr.lastPrice : instr.sell?.price;
			if (mktPrice) {
				let deviation = Math.abs(mktPrice - price) / mktPrice;
				message = `Price ${price} deviates from market price with ${deviation * 100.0} %%. Continue placing ${
					is_buy ? "bid" : "offer"
				} for ${qty} contracts at ${price} each?`;
				need_confirm = true;
			} else {
				// stupid test....
				if (price <= 200.0 || price >= 800) {
					message = `Continue placing ${is_buy ? "bid" : "offer"} for ${qty} contracts at ${price} each?`;
					need_confirm = true;
				}
			}
		} else {
			let mktPrice = instr.lastPrice > 0.0 ? instr.lastPrice : instr.buy?.price;
			if (mktPrice) {
				let deviation = Math.abs(mktPrice - price) / mktPrice;
				message = `Price ${price} deviates from market price with ${deviation * 100.0} %%. Continue placing ${
					is_buy ? "bid" : "offer"
				} for ${qty} contracts at ${price} each?`;
				need_confirm = true;
			} else {
				// stupid test....
				if (price <= 200.0 || price >= 800) {
					message = `Continue placing ${is_buy ? "bid" : "offer"} for ${qty} contracts at ${price} each?`;
					need_confirm = true;
				}
			}
		}
		*/

		if (need_confirm) {
			// return window.confirm(message);

			confirmAlert({
				title: "Confirm order",
				message: `${is_buy ? "Buy" : "Sell"} ${qty} contracts at ${price} CPM each`,
				closeOnEscape: true,
				closeOnClickOutside: false,
				buttons: [
					{
						label: "Confirm",
						onClick: confirm_callback
					},
					{
						label: "Dismiss",
						onClick: dismiss_callback
					}
				]
			});
		} else {
			confirm_callback();
		}
	}

	makeSymbol(country, publisher, url, category) {
		return (
			country +
			this.getFirstCharsOf(publisher, 3) +
			this.getFirstCharsOfURL(url, 3) +
			this.getFirstCharsOf(category, 3)
		);
	}

	static removeUrlPrefix(url) {
		let u = url.replace("https://", "");
		u = u.replace("http://", "");
		u = u.replace("www.", "");
		return u;
	}

	static getFirstCharsOfURL(url, num) {
		let u = Utils.removeUrlPrefix(url);
		return Utils.getFirstCharsOf(u, num);
	}

	static getFirstCharsOf(str, num) {
		return str.substring(0, num);
	}

	static timeStampToStr_old(timestamp) {
		//console.log(timestamp);
		let d = new Date(Date.parse(timestamp));
		//console.log(d);
		let r = d.toLocaleString("sv-SE");
		//console.log(r);
		return r;
	}

	static timeStampToStr(timestamp, format) {
		if (!format) {
			return moment(timestamp)
				.local()
				.format("YYYY-MM-DD HH:mm:ss");
		} else {
			return moment(timestamp)
				.local()
				.format(format);
		}
	}

	static timeStampToStr_v1(timestamp) {
		let dateObj = new Date(timestamp * 1000);
		let day = dateObj.getDate();
		let month = dateObj.getMonth();
		let year = dateObj.getFullYear();

		let hours = dateObj.getHours();
		let minutes = dateObj.getMinutes();
		let seconds = dateObj.getSeconds();

		let timeString =
			year.toString().padStart(4, "0") +
			"-" +
			month.toString().padStart(2, "0") +
			"-" +
			day.toString().padStart(2, "0") +
			" " +
			hours.toString().padStart(2, "0") +
			":" +
			minutes.toString().padStart(2, "0") +
			":" +
			seconds.toString().padStart(2, "0");

		//return dateObj.toUTCString();
		return timeString;
	}

	static autoSizeAllColumnsInGrid(columnApi) {
		let allColumnIds = [];
		columnApi.getAllColumns().forEach(function(column) {
			allColumnIds.push(column.colId);
		});
		columnApi.autoSizeColumns(allColumnIds);
	}

	static diffObj(o1, o2) {
		return Object.keys(o2).reduce((diff, key) => {
			if (o1[key] === o2[key]) return diff;
			return {
				...diff,
				[key]: o2[key]
			};
		}, {});
	}

	static printDiff(prefix, o1, o2) {
		console.debug(prefix + " diff:" + JSON.stringify(this.diffObj(o1, o2)));
	}
}
