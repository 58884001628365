import React, { Component } from 'react';

class LabelWithOptions extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         };
    }

    render() {
        return (
            <span className="LabelWithOptions">
                <span className="thelabel">{this.props.label}</span> <span>{this.props.options}</span>
            </span>        
        );
    }
}

export default LabelWithOptions;