import { createStore } from "redux";

const initialState = {
	view_options: {
		theme: "ag-theme-blue2",
		show_button_bar: true,
		button_bar_bg: "whitesmoke"
	},
	yax_instruments_loaded: false
};

/**
 * The reducer is the only function that modifies the state
 * @param state
 * @param action
 */
function reducer(state = initialState, action) {
	// console.log(action);
	let { yax, ...allbutyax } = action;

	switch (action.type) {
		/* Not used yet! */
		case "set_connection":
			console.log("*** MKTS *** setting connected " + JSON.stringify(allbutyax));
			return {
				...state,
				connection: {
					connected: action.connected,
					authenticated: action.authenticated
				}
			};

		case "yax_instruments_loaded": {
			console.log("*** MKTS *** yax instruments loaded " + JSON.stringify(allbutyax));
			return {
				...state,
				yax_instruments_loaded: action.loaded
			};
		}

		case "set_theme": {
			console.log("*** MKTS *** setting theme " + JSON.stringify(allbutyax));
			return {
				...state,
				view_options: {
					...state.view_options,
					theme: action.theme
				}
			};
		}

		case "set_view_options": {
			console.log("*** MKTS *** setting theme " + JSON.stringify(allbutyax));
			return {
				...state,
				view_options: action.view_options
			};
		}

		case "set_user":
			console.log("*** MKTS *** setting user " + JSON.stringify(allbutyax));
			return {
				...state,
				user: action.user
			};

		case "select_instrument":
			console.log("*** MKTS *** setting instrument " + JSON.stringify(allbutyax));
			return {
				...state,
				selectedInstrument: action.selectedInstrument // @TODO Should be selectedInstrumentId all through app!
			};

		case "select_market_order":
			console.log("*** MKTS *** setting instrument " + JSON.stringify(allbutyax));
			return {
				...state,
				selectedMarketOrder: action.selectedMarketOrder
			};
			break;

		case "select_index":
			console.log("*** MKTS *** setting index " + JSON.stringify(allbutyax));
			return {
				...state,
				selectedIndex: action.selectedIndex,
				selectedIndexName: action.selectedIndexName
			};
	
		default:
			break;
	}
	return state;
}

class MarketService {}

MarketService.STORE = createStore(reducer);

export default MarketService;
