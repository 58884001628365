import React, { Component } from 'react';
import "./StyledCheckbox.scss";

class StyledCheckbox extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         };
    }
    render() {
        return (
            <div className="StyledCheckboxWrapper">
                <label htmlFor="chk1">{this.props.label}</label>
                <input id="chk1" className="StyledCheckbox" type="checkbox" checked={this.props.checked} onChange={this.props.onChange}/>
            </div>
        );
    }
}

export default StyledCheckbox;