import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardImg, Cardbody, Cardtitle, Cardsubtitle, Cardtext, Badge, ButtonToolbar, Row, Col, Form, Button } from "react-bootstrap";

import DayPicker from 'react-day-picker/DayPicker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import ChangeUserPass from 'components/ChangeUserPass';

import moment from 'moment';
import "./PlaygroundPage.scss";
import NewUnderlying from "components/NewUnderlying";
import CreateProductPage from 'pages/CreateProductPage';

class PlaygroundPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lastTradingDate: "",
            lastSettlementDate: "",

            country: "",
            underlying: "",
            position: "",
            instrumentId: "",
            instrumentName: "",
            publisher: "",
            site: "",
            iabCodes: "",

            future_instrumentId: "",
            future_name: "",
            future_lastTradingDate: "",
            future_lastSettlementDate: "",
            future_tickSize: "",
            lastTradingDate: null,
            lastSettlementDate: null,

            underlying_dirty: false,
            future_dirty: false,

            instr: {}
        };

        this.setLastTradingDate = this.setLastTradingDate.bind(this);
        this.setLastSettlementDate = this.setLastSettlementDate.bind(this);

        this.onChangeUnderlying = this.onChangeUnderlying.bind(this);
        this.onChangePosition = this.onChangePosition.bind(this);
        this.onChangeFuture = this.onChangeFuture.bind(this);

        this.handleSaveUnderlying = this.handleSaveUnderlying.bind(this);
        this.handleModifyUnderlying = this.handleModifyUnderlying.bind(this);
        this.handleCancelSaveUnderlying = this.handleCancelSaveUnderlying.bind(this);

        this.handleNewFuture = this.handleNewFuture.bind(this);
        this.handleModifyFuture = this.handleModifyFuture.bind(this);
        this.handleSaveFuture = this.handleSaveFuture.bind(this);
        this.handleCancelSaveFuture = this.handleCancelSaveFuture.bind(this);

    }

    /* FUTURES */
    handleNewFuture(e) {
        e.preventDefault();
        this.setState({
            future_instrumentId: "",
            future_name: "",
            future_lastTradingDate: "",
            future_lastSettlementDate: "",
            future_dirty: true
        });
    }

    handleModifyFuture(e) {
        e.preventDefault();
        this.setState({ future_dirty: true });
    }

    handleSaveFuture(e) {
        e.preventDefault();
        this.setState({ future_dirty: false });
    }

    handleCancelSaveFuture(e) {
        e.preventDefault();
        this.setState({ future_dirty: false });
    }

    /* UNDERLYINGS */
    handleModifyUnderlying(e) {
        e.preventDefault();
        this.setState({ underlying_dirty: true });
    }

    handleSaveUnderlying(e) {
        e.preventDefault();
        this.setState({ underlying_dirty: false });
    }

    handleCancelSaveUnderlying(e) {
        e.preventDefault();
        this.setState({ underlying_dirty: false });
    }


    setLastTradingDate(day, { selected }) {
        alert(day);
        console.log(day);
        this.setState({
            lastTradingDate: selected ? undefined : day,
        });
    }

    setLastSettlementDate(day, { selected }) {
        alert(day);
        console.log(day);
        this.setState({
            lastSettlementDate: selected ? undefined : day,
        });
    }

    componentDidMount() {
        let ul = this.props.yax.getAllUnderlyings();
        if (ul.length > 0) {
            console.log("*** PLAY *** here we should switch to the first underlying!");
            this.switchUnderlying(ul[0].instrumentId);
        }
    }

    componentDidUpdate(previousProps, previousState) {
        console.log("*** PLAY *** componentDidUpdate " + JSON.stringify(previousState) + JSON.stringify(this.state));
    }

    onChangeUnderlying(evt) {
        if (this.state.underlying !== evt.target.value) {
            this.switchUnderlying(evt.target.value);
        }
    }

    onChangePosition(evt) {
        this.setState({
            position: evt.target.value
        });
    }

    switchUnderlying(instrumentId) {
        let instr = this.props.yax.getInstrument(instrumentId);
        let futures = this.props.yax.getAllFuturesFor(instr.instrumentId);
        let future = futures[0]; // BUG What if there are no futures!
        this.setState({

            underlying_dirty: false,

            instr: instr,
            instrumentId: instr.instrumentId,
            instrumentName: instr.name,
            underlying: instr.instrumentId,
            publisher: instr.publisher,
            site: instr.site,
            iabCodes: instr.iabCodes,
            country: instr.country,
            position: instr.position,

            future_instrumentId: future.instrumentId,
            future_name: future.name,
            future_lastTradingDate: future.lastTradingDate,
            future_lastSettlementDate: future.lastSettlementDate,
            future_tickSize: future.tickSize
        });
    }

    onChangeFuture(evt) {
        console.log("onChangeFuture");
        console.log(evt.target.value);

        let instr = this.props.yax.getInstrument(evt.target.value);
        console.log(instr);
        this.setState({
            future_instrumentId: instr.instrumentId,
            future_name: instr.name,
            lastTradingDate: moment(instr.lastTradingDate).toDate(),
            lastSettlementDate: moment(instr.lastSettlementDate).toDate(),
            future_tickSize: instr.tickSize
        });

    }

    render() {

        console.log("*** PLAY ***");
        console.log(this.state);

        let options = this.props.yax.getAllUnderlyings().map(item => {
            return <option key={item.instrumentId} value={item.instrumentId}>{item.instrumentId + " : " + item.name}</option>
        });

        let futures = this.props.yax.getAllFuturesFor(this.state.underlying).map(item => {
            return <option key={item.instrumentId} value={item.instrumentId}>{item.name}</option>
        });

        return (
            <div className="PlaygroundPage">
                <div className="header"></div>
                <div className="body">

                    {/* change user pass! */}

                    <ChangeUserPass firm="yax" username="admin" yax={this.props.yax} />

                    {/* // From Underlying
                        String      country	 
                        String[]	iabCodes	 
                        String	position	 
                        String	publisher	 
                        String	site

                        String	instrumentId	 
                        String	name	 

                        // Future
                        String	indexInstrumentId	 
                        Instant	lastSettlementDate	 
                        java.time.Instant	lastTradingDate	 
                        java.math.BigDecimal tickSize */}

                    <Form className="demoForm">

                        <div className="newUnderlyingPart">
                            <NewUnderlying />
                        </div>

                        <div className="createProductPagePart">
                            <CreateProductPage yax={this.props.yax} />
                        </div>

                        <div className="underlyingPart">

                            <Form.Row>
                                <Form.Group as={Col} controlId="formUnderlying">
                                    <Form.Label>Underlying</Form.Label>
                                    <Form.Control as="select" value={this.state.underlying} onChange={this.onChangeUnderlying}>
                                        {options}
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>



                            <Form.Row>

                                <Form.Group as={Col} controlId="formId">
                                    <Form.Label>Instrument ID</Form.Label>
                                    <Form.Control value={this.state.instrumentId} readOnly={!this.state.underlying_dirty} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control value={this.state.instrumentName} readOnly={!this.state.underlying_dirty} />
                                </Form.Group>

                            </Form.Row>

                            <Form.Row>

                                <Form.Group as={Col} controlId="formPublisher">
                                    <Form.Label>Publisher</Form.Label>
                                    <Form.Control value={this.state.publisher} readOnly={!this.state.underlying_dirty} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formSite">
                                    <Form.Label>Site</Form.Label>
                                    <Form.Control value={this.state.site} readOnly={!this.state.underlying_dirty} />
                                </Form.Group>

                            </Form.Row>

                            <Form.Row>

                                <Form.Group as={Col} controlId="formCountry">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control value={this.state.country} readOnly={!this.state.underlying_dirty} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formPosition">
                                    <Form.Label>Position</Form.Label>

                                    {/* difference "selected" and "value" */}
                                    <Form.Control as="select" value={this.state.position} onChange={this.onChangePosition} disabled={!this.state.underlying_dirty}>
                                        <option value="PANORAMA_TOP">Panorama Top</option>
                                        <option value="WELCOME_PAGE">Welcome Page</option>
                                        <option value="WALLPAPER_SCROLL">Wallpaper Scroll</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formIabCodes">
                                    <Form.Label>IAB Codes</Form.Label>
                                    <Form.Control value={this.state.iabCodes} readOnly={!this.state.underlying_dirty} />
                                </Form.Group>

                            </Form.Row>

                            {this.state.underlying_dirty ?
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formUnderlyingButtons">
                                        <Button style={{ marginRight: "10px" }} variant="primary" onClick={this.handleSaveUnderlying} type="submit">Save</Button>
                                        <Button variant="secondary" onClick={this.handleCancelSaveUnderlying} type="submit">Cancel</Button>
                                    </Form.Group>
                                </Form.Row>
                                : <>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formUnderlyingButtons">
                                            <Button variant="primary" onClick={this.handleModifyUnderlying} type="submit">Modify</Button>
                                        </Form.Group>
                                    </Form.Row>
                                </>}
                        </div>


                        <div className="futurePart">

                            <Form.Row>
                                <Form.Group as={Col} controlId="formUnderlying">
                                    <Form.Label>Future</Form.Label>
                                    <Form.Control as="select" value={this.state.future_instrumentId} onChange={this.onChangeFuture} readOnly>
                                        {futures}
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>

                                <Form.Group as={Col} controlId="formGridInstrumentId">
                                    <Form.Label>Instrument Id</Form.Label>
                                    <Form.Control value={this.state.future_instrumentId} readOnly={!this.state.future_dirty} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridInstrumentName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control value={this.state.future_name} readOnly={!this.state.future_dirty} />
                                </Form.Group>

                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridLastTradingDate">
                                    <Form.Label>Last Trading Date</Form.Label>
                                    <Form.Control value={this.state.future_lastTradingDate} placeholder="Enter last settlement date" readOnly={!this.state.future_dirty} />
                                    {/* <DayPickerInput
                                        month={this.state.lastTradingDate}
                                        selectedDays={this.state.lastTradingDate}
                                        onDayChange={this.setLastTradingDate}
                                        onDayClick={this.setLastTradingDate}

                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        format="YYYY-MM-DD"
                                        placeholder={`${formatDate(new Date(), 'YYYY-MM-DD', 'sv-SE')}`}
                                        dayPickerProps={{
                                            locale: 'sv-SE',
                                            localeUtils: MomentLocaleUtils,
                                        }}
                                    /> */}

                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridLastSettlementDate">
                                    <Form.Label>Last Settlement Date</Form.Label>
                                    <Form.Control value={this.state.future_lastSettlementDate} placeholder="Enter last settlement date" readOnly={!this.state.future_dirty} />

                                    {/* <DayPickerInput
                                        month={this.state.lastSettlementDate}
                                        selectedDays={this.state.lastSettlementDate}
                                        onDayChange={this.setLastSettlementDate}
                                        onDayClick={this.setLastSettlementDate}

                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        format="YYYY-MM-DD"
                                        placeholder={`${formatDate(new Date(), 'YYYY-MM-DD', 'sv-SE')}`}
                                        dayPickerProps={{
                                            locale: 'sv-SE',
                                            localeUtils: MomentLocaleUtils,
                                        }}
                                    /> */}

                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridTicksize">
                                    <Form.Label>Ticksize</Form.Label>
                                    <Form.Control value={this.state.future_tickSize} readOnly={!this.state.future_dirty} />
                                </Form.Group>

                            </Form.Row>

                            <Form.Row>
                                {!this.state.future_dirty ?
                                    <Form.Group as={Col} controlId="formUnderlyingButtons">
                                        <Button variant="primary" onClick={this.handleModifyFuture} type="submit">Modify</Button>
                                    </Form.Group>
                                    :
                                    <>
                                        <Form.Group as={Col} controlId="formUnderlyingButtons">
                                            <ButtonToolbar>
                                                <Button style={{ marginRight: "10px" }} variant="primary" onClick={this.handleSaveFuture} type="submit">Save</Button>
                                                <Button variant="secondary" onClick={this.handleCancelSaveFuture} type="submit">Cancel</Button>
                                            </ButtonToolbar>
                                        </Form.Group>
                                    </>}
                            </Form.Row>


                        </div>

                        {/* <Form.Group id="formGridCheckbox">
                            <Form.Check type="checkbox" label="Check me out" />
                        </Form.Group> */}

                    </Form>

                </div>
            </div >
        );
    }
}

const mapStateToProps = state => ({
    selectedInstrument: state.selectedInstrument
});
export default connect(mapStateToProps)(PlaygroundPage);
