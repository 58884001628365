import React, { Component } from "react";
import { connect } from "react-redux";
import OwnTradesHistory from 'components/OwnTradesHistory'

import "./OwnTradesHistoryPage.scss";

class OwnTradesHistoryPage extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         };
    }
    render() {

        return (
            <div className="OwnTradesHistoryPage">
                <div className="header"></div>
                <div className="trades">
                    <OwnTradesHistory yax={this.props.yax} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
	selectedInstrument: state.selectedInstrument
});
export default connect(mapStateToProps)(OwnTradesHistoryPage);
