import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Highcharts, { PlotLineOrBand } from "highcharts/highstock";
import {
	ColumnSeries,
	HighchartsStockChart,
	Chart,
	withHighcharts,
	XAxis,
	YAxis,
	Title,
	LineSeries,
	OHLCSeries,
	Navigator,
	RangeSelector,
	Tooltip
} from "react-jsx-highstock";
import { connect } from "react-redux";

// https://stackoverflow.com/questions/47046067/typeerror-moment-tz-is-not-a-function
import moment from "moment-timezone";
import Utils from "utils/utils";

window.moment = moment;

class IndexHistory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: []
		};

		this.handleTS = this.handleTS.bind(this);

		this.plotOptions = {
			series: {
				marker: {
					enabled: true
				}
			}
		};
	}

	componentDidMount() {
		console.log("*** IXHI *** IndexHistory.componentDidMount()");
		this.props.yax.queryTradeStatistics("ALL", this.props.selectedIndex, this.handleTS);
	}

	handleTS(events, obj) {
		const x = events.map(e => {
			return [
				moment(e.timestamp)
					.local()
					.valueOf(),
				e.lastPrice
			]; // valueOf == unix() * 1000.0
		});

		this.setState({
			data: x
		});
	}

	componentDidUpdate(previousProps, previousState) {
		Utils.printDiff("*** IXHI *** componentDidUpdate ", previousProps, this.props);
		if (previousProps.selectedIndex !== this.props.selectedIndex) {
			this.props.yax.queryTradeStatistics("ALL", this.props.selectedIndex, this.handleTS);
		}
	}

	render() {
		if (this.state.data.length == 0) {
			return <h5>No data yet</h5>;
		}
		let start = this.state.data[0][0];
		let stop = this.state.data[this.state.data.length - 1][0];

		return (
			<>
				<h1>{this.props.selectedIndexName}</h1>

				<HighchartsStockChart
					plotOptions={this.plotOptions}
					time={{ timezone: "Europe/Stockholm" }}
					subtitle={{ text: "Settlement price" }}>
					<Chart chart={{ backgroundColor: "#ff0000" }} height={this.props.height} zoomType="x" />

					<Title>{this.state.selectedInstrument}</Title>
					{/* <Legend>
                        <Legend.Title>Key</Legend.Title>
                    </Legend> */}
					<Tooltip />

					<XAxis type="datetime" tickPixelInterval={150} maxZoom={20 * 1000} min={start} max={stop}>
						<XAxis.Title>Date/Time</XAxis.Title>
					</XAxis>

					<YAxis ordinal={false}>
						<YAxis.Title>Price</YAxis.Title>
						<LineSeries id="price" name="Price" data={this.state.data}></LineSeries>
					</YAxis>

					{/* <YAxis opposite={true} height="40%" top="60%" min={350} max={450}>
						<YAxis.Title>Volume</YAxis.Title>
						<ColumnSeries id="volume" name="Volume" data={this.state.data2} />
					</YAxis> */}

					<RangeSelector
						inputEnabled={false}
						selected={1}
						inputDateFormat="%H:%M:%S"
						inputEditDateFormat="%H:%M:%S">
						<RangeSelector.Button count={1} type="day">
							1d
						</RangeSelector.Button>
						<RangeSelector.Button count={7} type="day">
							7d
						</RangeSelector.Button>
						<RangeSelector.Button count={1} type="month">
							1m
						</RangeSelector.Button>
						<RangeSelector.Button type="all">All</RangeSelector.Button>
						<RangeSelector.Input boxBorderColor="#7cb5ec" enabled={false} />
					</RangeSelector>

					<Navigator ordinal={false} min={this.state.start} max={this.state.stop}>
						<Navigator.Series seriesId="???" />
					</Navigator>
				</HighchartsStockChart>
			</>
		);
	}
}

// export default OrderLine;
const mapStateToProps = state => ({});

export default connect(mapStateToProps)(withHighcharts(IndexHistory, Highcharts));
