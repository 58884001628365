import React, { Component } from "react";
import "./HomeNew.scss";
import MarketGrid from "./MarketGrid";
import OwnOrders from "./OwnOrders";
import OwnTrades from "./OwnTrades";
import OwnPositions from "./OwnPositions";
import TopBar from "./TopBar";
import OrderLine from "./OrderLine";
import SelectIndex from "./SelectIndex";
import IndexPrice from "./IndexPrice";
import AccountStatus from "./AccountStatus";

import Mockup from "./Mockup";

export default class HomeNew extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mockup_marketgrid: false,
			mockup_ownorders: false,
			mockup_owntrades: false,
			mockup_trades: false,
			mockup_orders: false,
			mockup_graph: false,
			mockup_marketdepth: false
		};
	}
	render() {
		return (
			<div style={{ paddingTop: "10px" }} className={"HomeNew"}>
				<div className="box top">
					<TopBar yax={this.props.yax} />
				</div>

				{this.props.yax.isAdmin() ? (
					""
				) : (
					<>
						<div className="box orderinput">
							<OrderLine yax={this.props.yax} />
						</div>
					</>
				)}

				<div className="box selectindex">
					<SelectIndex yax={this.props.yax} />
				</div>
				<div className="box indexprice">
					<IndexPrice yax={this.props.yax} />
				</div>
				<div className="box accountstatus">
					<AccountStatus yax={this.props.yax} />
				</div>

				<div className="box marketgrid">
					{this.state.mockup_marketgrid ? (
						<Mockup cls={"MarketGrid"} styles={{ height: "300px", backgroundColor: "green" }} />
					) : (
						<MarketGrid yax={this.props.yax} />
					)}
				</div>

				{/* Skip these views if you are ADMIN! */}
				{this.props.yax.isAdmin() ? (
					""
				) : (
					<>
						<div className="box ownorders">
							{this.state.mockup_ownorders ? (
								<Mockup cls={"OwnOrders"} styles={{ height: "300px", backgroundColor: "green" }} />
							) : (
								<OwnOrders yax={this.props.yax} />
							)}
						</div>
						<div className="box owntrades">
							{this.state.mockup_owntrades ? (
								<Mockup cls={"OwnTrades"} styles={{ height: "300px", backgroundColor: "red" }} />
							) : (
								<OwnTrades yax={this.props.yax} />
							)}
						</div>
					</>
				)}

				<div className="box ownpositions">
					{this.state.mockup_owntrades ? (
						<Mockup cls={"OwnPositions"} styles={{ height: "300px", backgroundColor: "red" }} />
					) : (
						<OwnPositions yax={this.props.yax} />
					)}
				</div>
				<div className="footer"></div>
			</div>
		);
	}
}
