import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardImg, Cardbody, Cardtitle, Cardsubtitle, Cardtext, Badge, ButtonToolbar, Row, Col, Form, Button } from "react-bootstrap";

class SetUserPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                firm: "",
                username: "",
                usertype: "",
                password: ""
            },
            dirty: false
        };
    }

    componentDidMount() {
        // window.localStorage.setItem("edit-user", params.data.username);
        let f = window.localStorage.getItem("edit-user-firm");
        let u = window.localStorage.getItem("edit-user-username");

        this.setState({
            user: {
                ...this.state.user,
                firm: f,
                username: u
            }
        });
    }

    onChangePassword(evt) {
        this.setState({
            dirty: true,
            user: {
                ...this.state.user,
                password: evt.target.value
            }
        });
    }

    onSave(evt) {
        this.props.yax.sendSetUserPasswordRequest(this.state.user.firm, this.state.user.username, this.state.user.password);
        this.props.history.push("/users");
    }

    onCancel(evt) {
        this.props.history.push("/users")
    }


    render() {

        console.log("*** EDUS ***");
        console.log(this.state.user);

        return (
            <Form className="demoForm">

                <div className="underlyingPart">

                    <Form.Row>

                        {/* these 2 are readonly */}

                        <Form.Group as={Col} controlId="formFirm">
                            <Form.Label>Firm</Form.Label>
                            <Form.Control value={this.state.user.firm} readOnly />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formUsername">
                            <Form.Label>User Name</Form.Label>
                            <Form.Control value={this.state.user.username} readOnly />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" value={this.state.user.password} onChange={this.onChangePassword.bind(this)} />
                        </Form.Group>
                    </Form.Row>


                    <Form.Row>
                        {!this.state.dirty ?
                            <Form.Group as={Col} controlId="formUnderlyingButtons">
                                <Button variant="primary" onClick={this.onCancel.bind(this)} type="submit">Close</Button>
                            </Form.Group>
                            :
                            <>
                                <Form.Group as={Col} controlId="formUnderlyingButtons">
                                    <ButtonToolbar>
                                        <Button style={{ marginRight: "10px" }} variant="primary" onClick={this.onSave.bind(this)} type="submit">Save</Button>
                                        <Button variant="secondary" onClick={this.onCancel.bind(this)} type="submit">Cancel</Button>
                                    </ButtonToolbar>
                                </Form.Group>
                            </>}
                    </Form.Row>

                </div>

            </Form>
        );
    }
}


const mapStateToProps = state => ({
    selectedInstrument: state.selectedInstrument
});
export default connect(mapStateToProps)(withRouter(SetUserPassword));
