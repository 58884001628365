import React, { Component } from "react";
import { connect } from "react-redux";

/* AG-GRID COMMUNITY */
import { AgGridReact } from "@ag-grid-community/react";
// import { AllCommunityModules } from "@ag-grid-community/all-modules";

/* AG-GRID CELL RENDERERS */
import PriceBlinkRenderer from "renderers/PriceBlinkRenderer";

import { numberFormatter } from "./formatString.js";

/* CUSTOM */
import "./MarketDepth.scss";

/*  --------------------------------------------------------------
	Normalt initierar man "Fetch Data" i componentDidMount. KOmponenten
	har renderats minst en gång då.
	
	Har man en AGGRID så kanske den inte är klar, då får man vänta på
	onGridReady() först.

	

    --------------------------------------------------------------
*/
class MarketDepth extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedInstrument: "",
			rowData: [
				{ rownumber: 1, bidqty: "", bid: "", ask: "", askqty: "" },
				{ rownumber: 2, bidqty: "", bid: "", ask: "", askqty: "" },
				{ rownumber: 3, bidqty: "", bid: "", ask: "", askqty: "" },
				{ rownumber: 4, bidqty: "", bid: "", ask: "", askqty: "" },
				{ rownumber: 5, bidqty: "", bid: "", ask: "", askqty: "" }
			]
		};

		this.components = {
			priceBlinkRenderer: PriceBlinkRenderer
		};

		this.rowCache = new Map();
		this.defaultColDef = {
			resizable: true,
			cellStyle: { "text-align": "right" },
			suppressMovable: true,
			suppressMenu: true
		};
		this.columnDefs = [
			{
				headerName: "Bid Qty",
				headerTooltip: "Total Quantity of all bids at this level",
				field: "bidqty",
				//valueFormatter: numberFormatter,
				cellRenderer: "priceBlinkRenderer",
				cellStyle: { "text-align": "right" }
			},
			{
				headerName: "Bid",
				headerTooltip: "Bid price level",
				field: "bid",
				valueFormatter: numberFormatter,
				cellRenderer: "priceBlinkRenderer",
				cellStyle: { "text-align": "right" }
			},
			// { headerName: "#", field: "rownumber", cellStyle: { "text-align": "center", weight: "700" } },
			{
				headerName: "Ask",
				headerTooltip: "Ask price level",
				field: "ask",
				valueFormatter: numberFormatter,
				cellRenderer: "priceBlinkRenderer",
				cellStyle: { "text-align": "right" }
			},
			{
				headerName: "AskQty",
				headerTooltip: "Total Quantity of all offers at this level",
				field: "askqty",
				//valueFormatter: numberFormatter,
				cellRenderer: "priceBlinkRenderer",
				cellStyle: { "text-align": "right" }
			}
		];
	}

	componentDidMount() {
		console.log("*** MKTD *** componentDidMount selectedInstrument:" + this.props.selectedInstrument);
		if (this.props.selectedInstrument !== "") {
			this.switchTo(this.props.selectedInstrument);
		}
	}

	componentDidUpdate(previousProps, previousState, snapshot) {
		console.log(
			"*** MKTD *** componentDidUpdate " +
				JSON.stringify(this.props.selectedInstrument) +
				" " +
				this.state.selectedInstrument
		);
		if (
			previousProps.selectedInstrument !== this.props.selectedInstrument ||
			previousState.selectedInstrument !== this.state.selectedInstrument
		) {
			this.switchTo(this.props.selectedInstrument);
		}
	}

	switchTo(instrument) {
		this.props.yax.addMarketByLevelSubscriber(instrument, (action, mbl) => {
			console.log("*** MKTD *** marketByLevelEvent " + action + " " + JSON.stringify(mbl));

			// eslint-disable-next-line
			switch (action) {
				case "init":
					this.updateData(mbl);
					break;
				case "live":
					this.autoSizeAll();
					break;
				case "update":
					this.updateData(mbl);
					this.autoSizeAll();
					if (this.gridApi) {
						this.gridApi.sizeColumnsToFit();
					}
					break;
			}
		});
	}

	render() {
		return (
			<div className="MarketDepth">
				<h5 className="md-header">Market Depth {this.state.selectedInstrument}</h5>

				<div className={"md-grid " + this.props.view_options.theme}>
					<AgGridReact
						autoSizePadding={6}
						domLayout="autoHeight"
						/*** Definition ***/
						defaultColDef={this.defaultColDef}
						columnDefs={this.columnDefs}
						rowData={this.state.rowData}
						/*** Events ***/
						onGridReady={this.onGridReady.bind(this)}
						onCellClicked={this.onCellClicked.bind(this)}
						/*** Identification ***/
						getRowNodeId={data => {
							return data.rownumber;
						}}
						/*** Customization ***/
						components={this.components}
						context={{}}
					/>
				</div>
			</div>
		);
	}

	/*  --------------------------------------------------------------


      --------------------------------------------------------------
   */
	onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		console.log("GRID: onGridReady()");

		window.addEventListener("resize", () => this.autoSizeAll);

		if (this.props.selectedInstrument !== "") {
			this.switchTo(this.props.selectedInstrument);
		}
	};

	/*  --------------------------------------------------------------


      --------------------------------------------------------------
   */
	updateDataOld(obj) {
		console.log("*** MKTD *** updateData " + JSON.stringify(obj));

		let data = [];
		for (let i = 0; i < 5; i++) {
			data.push({
				rownumber: i + 1,
				bid: obj.buy && obj.buy[i] ? obj.buy[i].price : "",
				bidqty: obj.buy && obj.buy[i] ? obj.buy[i].quantity : "",
				ask: obj.sell && obj.sell[i] ? obj.sell[i].price : "",
				askqty: obj.sell && obj.sell[i] ? obj.sell[i].quantity : ""
			});
		}

		//this.gridApi.updateRowData({ update: [...data] });
		console.log("*** MKTD *** updateData , setState " + JSON.stringify(data));
		this.setState({
			rowData: data
		});
	}

	updateData(obj) {
		if (!this.gridApi) {
			console.log("*** MKTD *** updateData - gridApi not defined yet!" + JSON.stringify(obj));
			return;
		}
		console.log("*** MKTD *** updateData " + JSON.stringify(obj));

		for (let i = 0; i < 5; i++) {
			let line = {
				rownumber: i + 1,
				bid: obj.buy && obj.buy[i] ? obj.buy[i].price : "",
				bidqty: obj.buy && obj.buy[i] ? obj.buy[i].quantity : "",
				ask: obj.sell && obj.sell[i] ? obj.sell[i].price : "",
				askqty: obj.sell && obj.sell[i] ? obj.sell[i].quantity : ""
			};

			this.gridApi.updateRowData({ update: [line] });
		}

		//this.gridApi.updateRowData({ update: [...data] });
		// console.log("*** MKTD *** updateData , setState " + JSON.stringify(data));
		// this.setState({
		// 	rowData: data
		// });
	}

	autoSizeAll() {
		if (this.gridColumnApi) {
			let allColumnIds = [];
			this.gridColumnApi.getAllColumns().forEach(function(column) {
				allColumnIds.push(column.colId);
			});
			this.gridColumnApi.autoSizeColumns(allColumnIds);

			this.gridApi.sizeColumnsToFit();
		} else {
			console.error("*** MKTD *** autoSizeAll : gridColumnApi not available yet!");
		}
	}

	/*  --------------------------------------------------------------


      --------------------------------------------------------------
   */
	onCellClicked(evt) {
		console.log("*** MKTD *** Clicked on " + evt.column.colId + " which is " + JSON.stringify(evt.data));

		if (
			evt.column.colId === "askqty" ||
			evt.column.colId === "ask" ||
			evt.column.colId === "bid" ||
			evt.column.colId === "bidqty"
		) {
			let bidsideclicked = evt.column.colId === "bidqty" || evt.column.colId === "bid";

			let bidask;
			let price;
			let qty;

			if (bidsideclicked) {
				bidask = "bid";
				price = evt.data.bid ? evt.data.bid : 0;
				qty = evt.data.bidqty ? evt.data.bidqty : 0;
			} else {
				bidask = "ask";
				price = evt.data.ask ? evt.data.ask : 0;
				qty = evt.data.askqty ? evt.data.askqty : 0;
			}

			this.props.dispatch({
				type: "select_market_order",
				selectedMarketOrder: {
					bidask: bidask,
					price: price,
					qty: qty
				}
			});
		}
	}
}

const mapStateToProps = state => ({
	selectedInstrument: state.selectedInstrument,
	selectedMarketOrder: state.selectedMarketOrder,
	view_options: state.view_options
});
export default connect(mapStateToProps)(MarketDepth);
