import React, { Component } from "react";
import { connect } from "react-redux";

/* AG-GRID COMMUNITY */
import { AgGridReact } from "@ag-grid-community/react";
// import { AllCommunityModules } from "@ag-grid-community/all-modules";

/* AG-GRID CELL RENDERERS */
import PriceBlinkRenderer from "renderers/PriceBlinkRenderer";

/* CUSTOM */
import "./PublicTradesOneInstrument.scss";
import Utils from "utils/utils";
import { numberFormatter } from "./formatString.js";

class PublicTradesOneInstrument extends Component {
	constructor(props) {
		super(props);

		this.state = {
			rowData: [],
			components: {
					priceBlinkRenderer: PriceBlinkRenderer
			},
			rowSelection: "multiple"
		};

		this.resetData();

		this.defaultColDef = this.getDefaultColumnDefs();
		this.columnDefs = this.getColumnDefs();
	}

	resetData() {
		this.subscription_cb = null;
		this.subscribedInstrumentId = null;
	}

	/* ------------------------------------------------------------------------ */

	componentDidMount() {
		let oldsymbol = this.state.selectedInstrument;
		console.log(
			"*** PTOI *** componentDidMount props: " +
				this.props.selectedInstrument +
				" state:" +
				this.state.selectedInstrument
		);
		this.subscribeIfDifferent(oldsymbol, this.props.selectedInstrument);
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.selectedInstrument !== prevProps.selectedInstrument ||
			this.state.selectedInstrument !== prevState.selectedInstrument
		) {
			console.log(
				"*** PTOI *** componentDidUpdate props: " +
					this.props.selectedInstrument +
					" state:" +
					this.state.selectedInstrument
			);
			let oldsymbol = this.state.selectedInstrument;
			this.subscribeIfDifferent(oldsymbol, this.props.selectedInstrument);
		}
	}

	subscribeIfDifferent(oldsymbol, instrumentId) {
		console.log("*** PTOI *** subscribeIfDifferent (" + oldsymbol + "," + instrumentId + ")");
		/* change in instrument? */
		if (oldsymbol !== instrumentId && instrumentId !== undefined) {
			console.log("*** PTOI *** currentInstrument changed from " + oldsymbol + " to " + instrumentId);
			/* cancel old subscription if there was one! */
			if (this.subscription_cb) {
				console.log("*** PTOI *** removing old subscription for " + oldsymbol);
				this.props.yax.removePublicTradeSubscriber(oldsymbol, this.subscription_cb);

				this.resetData();
			}

			/* setup a new Instrument Subscriber */
			console.log("*** PTOI *** subscribing to " + instrumentId);
			this.subscribedInstrumentId = instrumentId;

			let rowData=[];
			this.subscription_cb = (action, obj) => {
				console.log("*** PTOI *** Got an update: " + JSON.stringify(obj));
				// eslint-disable-next-line
				switch (action) {
					case "init":
						console.log("*** PTOI *** Got an old trade " + JSON.stringify(obj));
						rowData.push(obj);
					break;
					case "live":
						console.log("*** PTOI *** Last trade gotten, live now!");
						this.setState({rowData: rowData}, () => {this.autoSizeAll()});
					break;
					case "add":
						console.log("*** PTOI *** Live trade, set state!");
						rowData.push(obj);
						this.setState({rowData: rowData});
					break;
				}
			};
			this.props.yax.addPublicTradeSubscriber(instrumentId, this.subscription_cb.bind(this));
		}
	}

	componentWillUnmount() {
		let oldsymbol = this.state.currentInstrument ? this.state.currentInstrument.instrumentId : "";
		if (oldsymbol && this.subscription_cb) {
			this.props.yax.removePublicTradeSubscriber(oldsymbol, this.subscription_cb);

			this.resetData();
		}
	}
	/* ------------------------------------------------------------------------ */

	render() {
		console.log("*** PTOI *** Rendering Trades!");
		return (
			<div className="PublicTradesOneInstrument">
				<h5 className="ga-header">{this.props.selectedInstrument} Trades</h5>
				<div className={"ga-grid " + this.props.view_options.theme}>
					<AgGridReact

						autoSizePadding={6}
						domLayout='autoHeight'

						rowData={this.state.rowData}
						components={this.state.components}
						columnDefs={this.columnDefs}
						defaultColDef={this.defaultColDef}
						onGridReady={this.onGridReady.bind(this)}
						getRowNodeId={data => {
							return data.tradeId;
						}}
					/>
				</div>
			</div>
		);
	}

	onGridReady = params => {
		console.log("*** PTOI *** OnGridReady");
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

		this.autoSizeAll();
	};

	autoSizeAll() {
		if (!this.gridColumnApi) {
			console.error("*** PTOI *** gridColumnApi not available yet!");
			return;
		}

		let allColumnIds = [];
		this.gridColumnApi.getAllColumns().forEach(function(column) {
			allColumnIds.push(column.colId);
		});
		this.gridColumnApi.autoSizeColumns(allColumnIds);
	}


	/* {"messageType":"PublicTradeEvent",
		"timestamp":1574678956.009103000,
		"instrumentId":"SE-SCHIB-1-[1]-21B",
		"tradeId":"20",
		"price":10,
		"quantity":100,
		"isBuyerAggressor":false,
		"buyer":{"firm":"Bambalo","marketOrderId":"298"},
		"seller":{"firm":"Bambalo","marketOrderId":"302"}
	} */

	getDefaultColumnDefs() {
		return {
			resizable: true,
			suppressSizeToFit: false,
			cellStyle: { "text-align": "right" },
			suppressMovable: true,
			suppressMenu: true
		};		
	}

	getColumnDefs() {
		return 		[
			{
				headerName: "Timestamp",
				headerTooltip: "Timestamp of last update",
				field: "timestamp",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				sort: "desc",				
				valueGetter: params => {
					return Utils.timeStampToStr(params.data.timestamp);
				}
			},
			{
				headerName: "Trade ID",
				headerTooltip: "Unique trade ID",
				field: "tradeId",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				cellStyle: { "text-align": "left" }
			},
			{
				headerName: "Instrument ID",
				headerTooltip: "Instrument ID",
				field: "instrumentId",
				cellStyle: { "text-align": "left" },
				sortable: true,
				sortingOrder: ["asc", "desc"],
			},
			{ 
				headerName: "Qty", 
				headerTooltip: "Trade quantity",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				field: "quantity" 
			},
			{
				headerName: "Price",
				headerTooltip: "Trade price",
				field: "price",
				valueFormatter: numberFormatter,
				cellRenderer: "priceBlinkRenderer",
				sortable: true,
				sortingOrder: ["asc", "desc"],
			},
			{ 
				headerName: "Aggressor", 
				headerTooltip: "Trade aggressor",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				field: "isBuyerAggressor",
				valueGetter: (params) => {
					return params.data.isBuyerAggressor ? "Buyer" : "Seller"
				},
				cellStyle: (params) => {
					return params.data.isBuyerAggressor ? {color: 'blue'} : {color: 'red'}
				}
			},
			{ 
				headerName: "Buyer", 
				headerTooltip: "Buyer firm",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				field: "buyer.firm" 
			},
			{ 
				headerName: "Seller", 
				headerTooltip: "Seller firm",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				field: "seller.firm" 
			}
		];

	}
}

const mapStateToProps = state => ({
	selectedInstrument: state.selectedInstrument,
	view_options: state.view_options
});
export default connect(mapStateToProps)(PublicTradesOneInstrument);
