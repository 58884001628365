import React, { Component } from "react";
import { connect } from "react-redux";
import OwnPositions from 'components/OwnPositions'

import "./PositionPage.css";

class PositionPage extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         };
    }
    render() {

        return (
            <div className="PositionPage">
                <div className="header"></div>
                <div className="positiongrid">
                    <OwnPositions yax={this.props.yax} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
	selectedInstrument: state.selectedInstrument
});
export default connect(mapStateToProps)(PositionPage);
