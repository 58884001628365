import React, { Component } from "react";
import { connect } from "react-redux";
import PublicTradesTicker from "components/PublicTradesTicker";
import "./PublicTradeTickerPage.scss";

class PublicTradeTickerPage extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         };
    }
    render() {

        return (
            <div className="PublicTradeTickerPage">
                <div className="tradeticker">
                    <PublicTradesTicker  yax={this.props.yax} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
	selectedInstrument: state.selectedInstrument
});
export default connect(mapStateToProps)(PublicTradeTickerPage);
