import React, { Component } from 'react';
import { Alert, ToggleButtonGroup, ToggleButton, Container, Card, CardImg, Cardbody, Cardtitle, Cardsubtitle, Cardtext, Badge, ButtonToolbar, Row, Col, Form, Button } from "react-bootstrap";

class SetTradingState extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tradingstate: ""
        };
    }

    componentDidMount() {

        this.setState({
            tradingstate: this.props.yax.getTradingState().state
        });

        this.props.yax.addMsgSubscriber("TradingStateEvent", (obj) => {
            this.setState({
                tradingstate: obj.state
            });
        });
    }

    onChangeTradingState(evt) {
        this.props.yax.sendSetTradingStateRequest(evt.target.value);
        evt.preventDefault();
    }

    onSendSetTradingState(evt) {
        this.props.yax.sendSetTradingStateRequest(this.state.tradingstate);
        evt.preventDefault();
    }

    onChangeGroup(evt) {
        console.log("*** SETS *** " + evt.target.value);
        evt.preventDefault();
    }

    render() {

        let variant = "secondary";

        if (this.state.tradingstate === "PRE_OPEN") {
            variant = "primary";
        }
        else if (this.state.tradingstate === "OPEN") {
            variant = "success";
        }
        else if (this.state.tradingstate === "HALTED") {
            variant = "warning";
        }
        else if (this.state.tradingstate === "HALTED") {
            variant = "danger";
        }

        let b = <Button variant={variant}>{this.state.tradingstate}</Button>

        return (

            <Container style={{ marginTop: "60px" }}>
                <Row>
                    <Col>
                        <Form>
                            <Row>
                                <h1>Current trading state is: {b}</h1>
                            </Row>
                            <Row>
                                <hr></hr>
                                <h5>Change State:</h5>
                                <hr></hr>
                            </Row>
                            <Row>
                                <Button variant={"primary"} style={{ marginRight: "10px" }} value={"PRE_OPEN"} onClick={this.onChangeTradingState.bind(this)}> Pre Open</Button>
                                <Button variant={"success"} style={{ marginRight: "10px" }} value={"OPEN"} onClick={this.onChangeTradingState.bind(this)} > Open</Button>
                                <Button variant={"warning"} style={{ marginRight: "10px" }} value={"HALTED"} onClick={this.onChangeTradingState.bind(this)} > Halted</Button>
                                <Button variant={"danger"} style={{ marginRight: "10px" }} value={"CLOSED"} onClick={this.onChangeTradingState.bind(this)} > Closed</Button>

                            </Row>
                            {/* <Row>
                                <Form.Group as={Col} xs={4} controlId={"modifyButton"}>
                                    <Button variant="primary" type="submit" onClick={this.onSendSetTradingState.bind(this)}>
                                        Set
                                    </Button>
                                </Form.Group>
                            </Row> */}
                        </Form>
                    </Col>
                </Row>
            </Container >
        );
    }
}

export default SetTradingState;