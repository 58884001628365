import React, { Component } from "react";
import { connect } from "react-redux";
import Users from 'components/Users';

import "./UserPage.scss";

class UserPage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <div className="UserPage">
                <div className="header"></div>
                <div className="users">
                    <Users yax={this.props.yax} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    selectedInstrument: state.selectedInstrument
});
export default connect(mapStateToProps)(UserPage);
