import React, { Component } from "react";
import { connect } from "react-redux";

/* AG-GRID COMMUNITY */
import { AgGridReact } from "@ag-grid-community/react";
// import { AllCommunityModules } from "@ag-grid-community/all-modules";

/* AG-GRID CELL RENDERERS */
import PriceBlinkRenderer from "renderers/PriceBlinkRenderer";

/* CUSTOM */
import "./PublicTradesTicker.scss";
import Utils from "utils/utils";
import { numberFormatter } from "./formatString.js";

class PublicTradesTicker extends Component {
	constructor(props) {
		super(props);

		this.rowData = [];

		this.state = {
			gridData: [],
			components: {
					priceBlinkRenderer: PriceBlinkRenderer
			},
			rowSelection: "multiple"
		};

		this.resetData();

		this.defaultColDef = {
			resizable: true,
			suppressSizeToFit: false,
			cellStyle: { "text-align": "right" },
			suppressMovable: true,
			suppressMenu: true
		};

		/* {"messageType":"PublicTradeEvent",
			"timestamp":1574678956.009103000,
			"instrumentId":"SE-SCHIB-1-[1]-21B",
			"tradeId":"20",
			"price":10,
			"quantity":100,
			"isBuyerAggressor":false,
			"buyer":{"firm":"Bambalo","marketOrderId":"298"},
			"seller":{"firm":"Bambalo","marketOrderId":"302"}
		} */
		this.columnDefs = [
			{
				headerName: "Timestamp",
				headerTooltip: "Timestamp of last update",
				sortable: true,
				sortingOrder: ["asc","desc"],
				sort: "desc",
				field: "",
				valueGetter: params => {
					return Utils.timeStampToStr(params.data.timestamp);
				},
				cellStyle: {"text-align": "center" }
			},
			{
				headerName: "Instrument ID",
				headerTooltip: "Instrument ID",
				field: "instrumentId",
				cellStyle: { "text-align": "left" },
				sortable: true,
				sortingOrder: ["asc","desc"],
			},
			{
				headerName: "Trade ID",
				headerTooltip: "Unique trade ID",
				field: "tradeId",
				sortable: true,
				sortingOrder: ["asc","desc"],
				cellStyle: { "text-align": "left" }
			},
			{ 
				headerName: "Qty",
				headerTooltip: "Trade quantity",
				sortable: true,
				sortingOrder: ["asc","desc"],
				field: "quantity"
			},
			{
				headerName: "Price",
				headerTooltip: "Trade price",
				field: "price",
				sortable: true,
				sortingOrder: ["asc","desc"],
				valueFormatter: numberFormatter,
				cellRenderer: "priceBlinkRenderer"
			},
			{ 
				headerName: "Aggressor", 
				headerTooltip: "Trade aggressor",
				field: "isBuyerAggressor",
				valueGetter: (params) => {
					return params.data.isBuyerAggressor ? "Buyer" : "Seller"
				},
				cellStyle: (params) => {
					return params.data.isBuyerAggressor ? {color: 'blue'} : {color: 'red'}
				}
			},
			{ 
				headerName: "Buyer", 
				headerTooltip: "Buyer firm",
				field: "buyer.firm",
				sortable: true,
				sortingOrder: ["asc","desc"],
			},
			{ 
				headerName: "Seller", 
				headerTooltip: "Seller firm",
				field: "seller.firm", 
				sortable: true,
				sortingOrder: ["asc","desc"],
			}
		];
	}

	resetData() {
		this.subscription_cb = null;
		this.subscribedInstrumentId = null;
	}

	/* ------------------------------------------------------------------------ */

	componentDidMount() {
		
	}

	componentDidUpdate(prevProps, prevState) {
		
	}

	subscribe() {
		this.subscription_cb = (action, obj) => {
			console.log("*** PTRT *** Got an update: " + JSON.stringify(obj));
			// eslint-disable-next-line
			switch (action) {
				case "init":
					console.log("*** PTRT *** Got an old trade " + JSON.stringify(obj));
					this.gridApi.updateRowData({ add: [obj] });
				break;
				case "live":
					console.log("*** PTRT *** Last trade gotten, live now!");
					this.autoSizeAll();
				break;
				case "add":
					console.log("*** PTRT *** Live trade, set state!");
					this.gridApi.updateRowData({ add: [obj] });
					this.autoSizeAll();
				break;
			}
		};
		this.props.yax.addAllPublictradeSubscriber(this.subscription_cb.bind(this));
	}

	componentWillUnmount() {
		this.props.yax.removeAllPublicTradeSubscriber(this.subscription_cb);
	}
	/* ------------------------------------------------------------------------ */

	render() {
		console.log("*** PTRT *** Rendering PublicTradesTicker!");
		return (
			<div>
				<h5 className="ga-header">Trade Ticker</h5>
				<div className="PublicTradesTicker">
					<div className={"ga-grid " + this.props.view_options.theme}>
						<AgGridReact

							autoSizePadding={6}
							domLayout='autoHeight'

							rowData={this.state.gridData}
							components={this.state.components}
							columnDefs={this.columnDefs}
							defaultColDef={this.defaultColDef}
							onGridReady={this.onGridReady.bind(this)}
							getRowNodeId={data => {
								return data.tradeId;
							}}
						/>
					</div>
				</div>
			</div>
		);
	}

	onGridReady = params => {
		console.log("*** PTRT *** OnGridReady");
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

		this.subscribe();
		
		this.autoSizeAll();
	};

	autoSizeAll() {
		if (!this.gridColumnApi) {
			console.error("*** PTRT *** gridColumnApi not available yet!");
			return;
		}

		let allColumnIds = [];
		this.gridColumnApi.getAllColumns().forEach(function(column) {
			allColumnIds.push(column.colId);
		});
		this.gridColumnApi.autoSizeColumns(allColumnIds);
	}
}

const mapStateToProps = state => ({
	selectedInstrument: state.selectedInstrument,
	view_options: state.view_options
});
export default connect(mapStateToProps)(PublicTradesTicker);
