import React, { Component } from 'react';
import { connect } from "react-redux";
import SelectInstrument from './SelectInstrument';
// import { Dropdown } from 'react-bootstrap';
import './MarketLine.scss';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import Flag from "react-flags";

class MarketLine extends Component {

	constructor(props) {
		super(props);
		this.state = {
            currentInstrument: null,
        }
        
        this.subscribedInstrumentId = null;
        this.subscription_cb = null;
    }

    /* ------------------------------------------------------------------------ */
    componentDidMount() {
        /*
        meaning? currentInstrument is never anything else than NULL here, right?
        */
        let oldsymbol = this.state.currentInstrument ? this.state.currentInstrument.instrumentId : "";
        this.subscribeIfDifferent(oldsymbol, this.props.selectedInstrument);
    }

    componentDidUpdate(prevProps, prevState) {
        let oldsymbol = this.state.currentInstrument ? this.state.currentInstrument.instrumentId : "";
        this.subscribeIfDifferent(oldsymbol, this.props.selectedInstrument);
    }

    subscribeIfDifferent(oldsymbol, instrumentId) {

        console.log("*** MKTL *** subscribeIfDifferent");
        /* change in instrument? */
        if (oldsymbol !== instrumentId) {

            console.log("*** MKTL *** currentInstrument changed from " + oldsymbol + " to " + instrumentId);
            /* cancel old subscription if there was one! */
            if (this.subscription_cb) {
                console.log("*** MKTL *** removing old subscription for " + oldsymbol);
                this.props.yax.removeInstrumentSubscriber(oldsymbol, this.subscription_cb);
                this.subscription_cb = null;
                this.subscribedInstrumentId = null;
            }

            /* setup a new Instrument Subscriber */
            console.log("*** MKTL *** subscribing to " + instrumentId);
            this.subscribedInstrumentId = instrumentId;
            this.subscription_cb = (action, obj) => {
                if (obj === undefined) {
                    if (action !== "live") {
                        console.error("*** MKTL *** error got undefined in callback from Instrument Subscription???")
                    }
                }
                else {
                    console.log("*** MKTL *** Got an update: " + JSON.stringify(obj));
                    this.setState({
                        currentInstrument: obj
                    });
                }
            }
            this.props.yax.addInstrumentSubscriber(instrumentId, this.subscription_cb.bind(this));
        }
    }
    
    componentWillUnmount() {
        let oldsymbol = this.state.currentInstrument ? this.state.currentInstrument.instrumentId : "";
        if (oldsymbol && this.subscription_cb) {
            this.props.yax.removeInstrumentSubscriber(oldsymbol, this.subscription_cb);
            this.subscription_cb = null;
            this.subscribedInstrumentId = null;
        }
    }
    /* ------------------------------------------------------------------------ */

    onClickedBuy() {
        this.props.productpagecb("buysell");
    }

    onClickedSell() {
        this.props.productpagecb("buysell");
    }

    onClickedBack() {
        this.props.productpagecb("overview");
    }

    formatPrice(price) {
        if (!price) {
            return "";
        }
        return parseFloat(price).toFixed(2);
    }


	render() {

        let buy,sell;
        if (this.state.currentInstrument && this.state.currentInstrument.buy) {
            buy = this.state.currentInstrument.buy.price;
        }
        else {
            buy = "";
        }

        if (this.state.currentInstrument && this.state.currentInstrument.sell) {
            sell = this.state.currentInstrument.sell.price;
        }
        else {
            sell = "";
        }

        let {lastPrice=0, highPrice=0, lowPrice=0, totalQuantity=0} = this.state.currentInstrument ? this.state.currentInstrument : {lastPrice:0, highPrice:0, lowPrice:0, totalQuantity:0};
        let prevClosingPrice = lastPrice; // @TODO: fix PREV CLOSE

        // eslint-disable-next-line
        let title, symbol;
        if (this.state.currentInstrument) {
            title = this.state.currentInstrument.name;
            symbol = this.state.currentInstrument.instrumentId;
        }
        else {
            title = "???";
            symbol = "???";
        }
		return (

            <div className="MarketLine">
                
                <div className="title">
                    
                    <Flag title="myflag" name="SE" format="png" pngSize={32} shiny={true} basePath="/img/flags" alt="Sweden"/>  
                    <span className="name">
                        {title}
                    </span>
                    {/* <span className="symbol"> ({symbol}) 
                        <FontAwesomeIcon icon={faCoffee} />  
                    </span> */}
                </div>

                <hr></hr>

                <div className="prices">
                    <table>
                        <thead>
                            <tr>
                                <th>Last</th>
                                <th>%</th>
                                <th>+/-</th>
                                <th>Bid</th>
                                <th>Ask</th>
                                <th>High</th>
                                <th>Low</th>
                                <th>Volume</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="last">{this.formatPrice(lastPrice)}</td>
                                <td>{this.formatPrice(prevClosingPrice ? (lastPrice - prevClosingPrice) / prevClosingPrice : 0)}</td>
                                <td>{this.formatPrice(lastPrice - prevClosingPrice)}</td>
                                <td>{this.formatPrice(buy)}</td>
                                <td>{this.formatPrice(sell)}</td>
                                <td>{this.formatPrice(highPrice)}</td>
                                <td>{this.formatPrice(lowPrice)}</td>
                                <td>{totalQuantity}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="instrument">
                    <SelectInstrument className="SelectInstrument" yax={this.props.yax} />
                </div>

                <div className="buysell">

                    { this.props.page === "buysell" ? <button className="btn overviewbutton"  onClick={this.onClickedBack.bind(this)}>Overview</button> : "" }
                    { this.props.page === "overview" ? <button className="btn tradebutton"     onClick={this.onClickedBuy.bind(this)}>Trade</button> : "" }
                </div>
            </div>
            
        );
	}
}

// export default OrderLine;
const mapStateToProps = (state) => ({
	selectedInstrument: state.selectedInstrument,
});
export default connect(mapStateToProps)(MarketLine);