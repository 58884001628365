/*	-------------------------------------------------------------------------------




	-------------------------------------------------------------------------------
*/
import React, { Component } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import PriceBlinkRenderer from "renderers/PriceBlinkRenderer";
import { connect } from "react-redux";
import "./FirmRiskEvents.scss";
import Utils from "utils/utils";
import { withAlert } from "react-alert";
import {
	Card,
	CardImg,
	Cardbody,
	Cardtitle,
	Cardsubtitle,
	Cardtext,
	Badge,
	ButtonToolbar,
	Row,
	Col,
	Form,
	Button
} from "react-bootstrap";

/*	-------------------------------------------------------------------------------




	-------------------------------------------------------------------------------
*/
class FirmRiskEvents extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modules: AllModules, // AllCommunityModules,
			rowData: [],
			components: {
				priceBlinkRenderer: PriceBlinkRenderer
			},
			showDepositWithdraw: false,
			selectedFirm: ""
		};

		this.firms = [];
		this.handleUpdate = this.handleUpdate.bind(this);

		this.defaultColDef = {
			resizable: true,
			cellStyle: { "text-align": "right" },
			suppressMovable: true,
			suppressMenu: true
		};

		/* MessageType":"FirmRiskUpdateEvent",
				"sequenceNumber":477,
				"source":"RCE",
				"timestamp":"2020-02-19T16:54:54.372306Z",
				"firm":"company1",
				"account":{
					"startBalance":1752,
					"deposits":0,
					"cashBalance":-235887
				},
				"fees":9831,
				"grossProfit":-299880,
				"initialMargin":0,
				"variationMargin":-72072,
				"marginRequirement":-72072
			} */

		this.columnDefs = [
			{
				headerName: "Timestamp",
				headerTooltip: "Timestamp of last update",
				field: "timestamp",
				cellStyle: { "text-align": "left" },
				valueGetter: param => {
					return Utils.timeStampToStr(param.data.timestamp);
				}
			},
			// {
			// 	headerName: "Seq",
			// 	headerTooltip: "Sequence number",
			// 	field: "sequenceNumber"
			// },
			{
				headerName: "Firm",
				headerTooltip: "Firm",
				field: "firm",
				sortable: true,
				sort: "asc",
				sortingOrder: ["asc", "desc"],
				cellStyle: { "text-align": "left" },
				comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
					return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
				}
			},
			{
				headerName: "Start",
				headerTooltip: "Starting balance",
				field: "account.startBalance",
				valueGetter: param => {
					// @TODO: Could this be initialized somehow? Always?
					if (!param.data.account || !param.data.account.startBalance) return "";
					return param.data.account.startBalance.toLocaleString(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					});
				}
			},
			{
				headerName: "Deposits",
				headerTooltip: "Deposits",
				field: "account.deposits",
				valueGetter: param => {
					// @TODO: Could this be initialized somehow? Always?
					if (!param.data.account || !param.data.account.deposits) return "";
					return param.data.account.deposits.toLocaleString(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					});
				}
			},
			{ headerName: "Fees", headerTooltip: "Fees", field: "fees" },
			{
				headerName: "Gross Profit",
				headerTooltip: "Gross Profit",
				field: "grossProfit",
				valueGetter: param => {
					return param.data.grossProfit.toLocaleString(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					});
				}
			},
			{
				headerName: "InitialMargin",
				headerTooltip: "InitialMargin",
				field: "initialMargin",
				valueGetter: param => {
					return param.data.initialMargin.toLocaleString(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					});
				}

				// cellRenderer: "priceBlinkRenderer"
			},
			{
				headerName: "VariationMargin",
				headerTooltip: "VariationMargin",
				field: "variationMargin",
				valueGetter: param => {
					return param.data.variationMargin.toLocaleString(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					});
				}
			},
			{
				headerName: "MarginRequirement",
				headerTooltip: "MarginRequirement",
				field: "marginRequirement",
				valueGetter: param => {
					return param.data.marginRequirement.toLocaleString(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					});
				}
			},
			{
				headerName: "Cash Balance",
				headerTooltip: "Cash Balance",
				field: "account.cashBalance",
				valueGetter: param => {
					// @TODO: Could this be initialized somehow? Always?
					if (!param.data.account || !param.data.account.cashBalance) return "";
					return param.data.account.cashBalance.toLocaleString(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					});
				},
				cellStyle: function(params) {
					if (params.data.account.cashBalance) {
						if (params.data.account.cashBalance < 0) {
							return { color: "white", backgroundColor: "red", "text-align": "right" };
						}
					}
					return { color: "black", backgroundColor: "transparent", "text-align": "right" };
				}
			}
		];
	}

	onChangeAmount(evt) {
		evt.preventDefault();
		this.setState({
			...this.state,
			dirty: true,
			amount: evt.target.value
		});
	}
	onDeposit(evt) {
		if (Number(this.state.amount) < 0) {
			this.props.alert.show("You can't deposit a negative amount or zero", { type: "error" });
		} else {
			this.props.yax.sendUpdateAccountRequest("DEPOSIT", this.state.selectedFirm, this.state.amount);

			this.setState({
				showDepositWithdraw: false,
				selectedFirm: "",
				amount: ""
			});
		}

		evt.preventDefault();
	}
	onWithdraw(evt) {
		if (Number(this.state.amount) <= 0) {
			this.props.alert.show("You can't withdraw a negative amount or zero", { type: "error" });
		} else {
			this.props.yax.sendUpdateAccountRequest("WITHDRAW", this.state.selectedFirm, this.state.amount);
			this.setState({
				showDepositWithdraw: false,
				selectedFirm: "",
				amount: ""
			});
		}

		evt.preventDefault();
	}
	onSetAccountValue(evt) {
		if (Number(this.state.amount) <= 0) {
			this.props.alert.show("You can't set negative or zero balance", { type: "error" });
		} else {
			this.props.yax.sendUpdateAccountRequest("SET_BALANCE", this.state.selectedFirm, this.state.amount);
			this.setState({
				showDepositWithdraw: false,
				selectedFirm: "",
				amount: ""
			});
		}

		evt.preventDefault();
	}
	onCancel(evt) {
		this.setState({
			showDepositWithdraw: false,
			selectedFirm: ""
		});
		evt.preventDefault();
	}

	render() {
		console.log(
			"*** FREV *** render() " + this.state.rowData.length + " rows of " + JSON.stringify(this.state.rowData)
		);
		return (
			<div className="FirmRiskEvents">
				<div className="ga-header">
					<h5>Current Firm Risk</h5>
				</div>

				<div
					className={"ga_deposit_withdraw"}
					style={{ display: this.state.showDepositWithdraw ? "block" : "none" }}>
					<Form className="depositWithdraw">
						<Form.Row>
							<Form.Group as={Col} xs={2} controlId="formFirm">
								<Form.Label>Firm</Form.Label>
								<Form.Control value={this.state.selectedFirm} readOnly />
							</Form.Group>

							<Form.Group as={Col} xs={2} controlId="formAmount">
								<Form.Label>Amount</Form.Label>
								<Form.Control
									autoFocus={true}
									value={this.state.amount}
									onChange={this.onChangeAmount.bind(this)}
								/>
							</Form.Group>

							<Form.Group as={Col} controlId="formButtonsDepositWithdrawCancel">
								<ButtonToolbar>
									<Button
										style={{ marginTop: "30px", marginRight: "10px" }}
										variant="success"
										onClick={this.onDeposit.bind(this)}
										type="submit">
										Deposit
									</Button>
									<Button
										style={{ marginTop: "30px", marginRight: "10px" }}
										variant="danger"
										onClick={this.onWithdraw.bind(this)}
										type="submit">
										Withdraw
									</Button>
									<Button
										style={{ marginTop: "30px", marginRight: "10px" }}
										variant="warning"
										onClick={this.onSetAccountValue.bind(this)}
										type="submit">
										Set Balance
									</Button>
									<Button
										style={{ marginTop: "30px", marginRight: "10px" }}
										variant="secondary"
										onClick={this.onCancel.bind(this)}
										type="submit">
										Cancel
									</Button>
								</ButtonToolbar>
							</Form.Group>
						</Form.Row>
					</Form>
				</div>

				<div className={"ga-grid " + this.props.view_options.theme}>
					<AgGridReact
						autoSizePadding={6}
						domLayout="autoHeight"
						/*** Definition ***/
						// modules={this.state.modules}
						defaultColDef={this.defaultColDef}
						columnDefs={this.columnDefs}
						rowData={this.state.rowData}
						/*** Events ***/
						onGridReady={this.onGridReady.bind(this)}
						// rowSelection={"multiple"}
						// rowDeselection={true}
						/*** Customization ***/
						getContextMenuItems={this.getContextMenuItems.bind(this)}
						components={this.state.components}
						// context={{}}
						getRowNodeId={data => {
							return data.firm;
						}}
					/>
				</div>
			</div>
		);
	}

	getContextMenuItems(params) {
		let menuitems = [];
		if (this.props.yax.isAdmin()) {
			menuitems = [
				{
					name: "Deposit/Widthdraw",
					action: () => {
						this.setState({
							showDepositWithdraw: true,
							selectedFirm: params.node.data.firm
						});
					}
				}
			];
		}
		return menuitems;
	}

	autoSizeAll() {
		if (!this.gridColumnApi) {
			console.error("*** FREV*** gridColumnApi not available yet!");
			return;
		}

		let allColumnIds = [];
		this.gridColumnApi.getAllColumns().forEach(function(column) {
			allColumnIds.push(column.colId);
		});
		this.gridColumnApi.autoSizeColumns(allColumnIds);

		// this.gridApi.sizeColumnsToFit();
	}

	onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		console.log("*** FREV *** onGridReady()");
		// this.gridApi.sizeColumnsToFit();
		// window.addEventListener("resize", () => this.fitColumns);
		// this.fitColumns();
		this.props.yax.addFirmRiskUpdateEventSubscriber(this.handleUpdate);
	};

	handleUpdate(action, obj) {
		// console.debug("*** FREV *** handleUpdate( " + action + ") " + JSON.stringify(obj));
		let ret;
		if (action !== "live") {
			if (this.firms.includes(obj.firm)) {
				this.gridApi.updateRowData({ update: [obj] });
			} else {
				this.gridApi.updateRowData({ add: [obj] });
				this.firms.push(obj.firm);
				this.autoSizeAll();
			}
		}
	}

	componentDidMount() {
		console.log("*** FREV *** componentDidMount");
	}

	componentDidUpdate(previousProps, previousState) {
		console.log("*** FREV *** componentDidUpdate");
	}

	componentWillUnmount() {
		console.log("*** FREV *** componentWillUnmount");
		this.props.yax.removeFirmRiskUpdateEventSubscriber(this.handleUpdate);
	}
}

// export default MarketGrid;
// definition of DummyComponent here...
const mapStateToProps = state => ({
	view_options: state.view_options
});
export default withAlert()(connect(mapStateToProps)(FirmRiskEvents));
