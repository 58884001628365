/*	-------------------------------------------------------------------------------




	-------------------------------------------------------------------------------
*/
import React, { Component } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import PriceBlinkRenderer from "renderers/PriceBlinkRenderer";
import { connect } from "react-redux";
import "./Account.scss";
import Utils from "utils/utils";

/*	-------------------------------------------------------------------------------




	-------------------------------------------------------------------------------
*/
class Account extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modules: AllModules, // AllCommunityModules,
			rowData: [],
			components: {
				priceBlinkRenderer: PriceBlinkRenderer
			}
		};

		this.firms = [];
		this.handleAccountUpdate = this.handleAccountUpdate.bind(this);

		this.defaultColDef = {
			resizable: true,
			cellStyle: { "text-align": "right" },
			suppressMovable: true,
			suppressMenu: true
		};

		this.columnDefs = [
			{
				headerName: "Timestamp",
				headerTooltip: "Timestamp of last update",
				field: "timestamp",
				cellStyle: { "text-align": "left" },
				valueGetter: param => {
					return Utils.timeStampToStr(param.data.timestamp);
				}
			},
			// { headerName: "Seq", headerTooltip: "Sequence number", field: "sequenceNumber" },
			{
				headerName: "Firm",
				sortable: true,
				sort: "asc",
				sortingOrder: ["asc", "desc"],
				headerTooltip: "Firm",
				field: "firm",
				cellStyle: { "text-align": "left" },

				comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
					return valueA.localeCompare(valueB);
				}
			},
			{
				headerName: "Start",
				headerTooltip: "Starting balance",
				field: "startBalance",
				valueGetter: param => {
					return param.data.startBalance.toLocaleString(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					});
				}
			},
			{
				headerName: "Last event",
				headerTooltip: "Text",
				field: "text",
				cellStyle: { "text-align": "left" }
			},
			{
				headerName: "Amount",
				headerTooltip: "Amount",
				field: "amount",
				valueGetter: param => {
					return param.data.amount.toLocaleString(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					});
				}
			},
			{
				headerName: "Balance",
				headerTooltip: "Current Balance",
				field: "balance",
				valueGetter: param => {
					return param.data.balance.toLocaleString(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					});
				},
				cellStyle: function(param) {
					console.log(param);
					if (param.data.balance < 0) {
						return { color: "white", backgroundColor: "red", "text-align": "right" };
					} else {
						return { color: "black", backgroundColor: "transparent", "text-align": "right" };
					}
				}
			}
		];
	}

	render() {
		return (
			<div className="Account">
				<div className="ga-header">
					<h5>{this.props.yax.isAdmin() ? "Accounts" : "Account"}</h5>
				</div>

				<div className={"ga-grid " + this.props.view_options.theme}>
					<AgGridReact
						autoSizePadding={6}
						domLayout="autoHeight"
						/*** Definition ***/
						// modules={this.state.modules}
						defaultColDef={this.defaultColDef}
						columnDefs={this.columnDefs}
						rowData={this.state.rowData}
						/*** Events ***/
						onGridReady={this.onGridReady.bind(this)}
						// rowSelection={"multiple"}
						// rowDeselection={true}
						/*** Customization ***/
						// components={this.state.components}
						// context={{}}
						getContextMenuItems={this.getContextMenuItems.bind(this)}
						getRowNodeId={data => {
							return data.firm;
						}}
					/>
				</div>
			</div>
		);
	}

	getContextMenuItems(params) {
		let menuitems = [];
		return menuitems;
	}

	autoSizeAll() {
		if (!this.gridColumnApi) {
			console.error("*** ACCT *** gridColumnApi not available yet!");
			return;
		}

		let allColumnIds = [];
		this.gridColumnApi.getAllColumns().forEach(function(column) {
			allColumnIds.push(column.colId);
		});
		this.gridColumnApi.autoSizeColumns(allColumnIds);

		// this.gridApi.sizeColumnsToFit();
	}

	onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		console.debug("*** ACCT *** onGridReady()");
		this.props.yax.addAccountSubscriber(this.handleAccountUpdate);
	};

	handleAccountUpdate(action, obj) {
		console.debug("*** ACCT *** handleAccountUpdate( " + action + ") " + JSON.stringify(obj));
		if (action !== "live") {
			if (this.firms.includes(obj.firm)) {
				this.gridApi.updateRowData({ update: [obj] });
			} else {
				this.gridApi.updateRowData({ add: [obj] });
				this.firms.push(obj.firm);
				this.autoSizeAll();
			}
		}
	}

	componentDidMount() {}

	componentDidUpdate(previousProps, previousState) {}

	componentWillUnmount() {
		this.props.yax.removeAccountSubscriber(this.handleAccountUpdate);
	}
}

const mapStateToProps = state => ({
	view_options: state.view_options
});
export default connect(mapStateToProps)(Account);
