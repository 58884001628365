import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select'

class SelectInstrument extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.instrumentList = [];
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(selectedOption) {
        this.props.dispatch(
            {
                type: "select_instrument",
                selectedInstrument: selectedOption.value
            }
        );
    }
    componentDidMount() {
        console.log(">>>> Adding instrument subscriber");
        this.props.yax.addInstrumentSubscriber("*", (action, instr) => {
            if (action === "init" && instr.instrumentType === "FUTURE") {
                console.log("*** SLCT *** >>>>>>> init " + instr.instrumentId);
                this.instrumentList.push(instr);
            }
            else if (action === "add" && instr.instrumentType === "FUTURE") {
                if (!this.instrumentList.includes(instr)) {
                    console.log("*** SLCT *** >>>>>>> add adding!" + instr.instrumentId);
                    this.instrumentList.push(instr);
                    this.forceUpdate();
                }
            }
            else if (action === "live") {
                console.log("*** SLCT *** >>>>>>> live ");
                this.forceUpdate();
            }
            else if (action === "mod" && instr.instrumentType === "FUTURE") {
                console.log("*** SLCT *** >>>>>>> mod " + instr.instrumentId);
                if (!this.instrumentList.includes(instr)) {
                    console.log("*** SLCT *** >>>>>>> mod adding!" + instr.instrumentId);
                    this.instrumentList.push(instr);
                    this.forceUpdate();
                }
            }
        });
    }


    render() {
        console.log("*** SLCT *** " + JSON.stringify(this.instrumentList));
        const options = this.instrumentList.sort((a, b) => a.instrumentId > b.instrumentId ? 1 : (a.instrumentId < b.instrumentId ? -1 : 0)).map((x) => {
            return {
                value: x.instrumentId,
                label: x.name
            }
        });
        console.log("*** SLCT *** " + JSON.stringify(options));

        return (
            <Select className={this.props.className} options={options.sort((a, b) => a.value - b.value)} onChange={this.handleChange} />
        );
    }
}

const mapStateToProps = (state) => ({

});
export default connect(mapStateToProps)(SelectInstrument);