// import Enum from "es6-enum";

export default class InstrumentDatabase {
    constructor() {
        this.data = new Map();
    }
    
    getObj(id) {
        let action = "mod";
        let obj = this.data.get(id)
        if (!obj) {
            obj = {instrumentId: id}
            this.data.set(id, obj);
            action = "add";
        }
        return {obj, action};
    }

    removeObj(id) {
        this.data.delete(id);
    }

    updateInstr(instrumentId,{
        name, 
        country, 
        publisher, 
        site, 
        position, 
        iabCodes, 
        lastSettlementDate, 
        tickSize, 
        contractSize, 
        lastTradingDate, 
        instrumentType,
        indexInstrumentId  // underlying
    } = {
        name:"", 
        country:"", 
        publisher:"", 
        site:"", 
        position:"", 
        iabCodes:"", 
        lastSettlementDate:"", 
        tickSize:"", 
        contractSize: "",
        lastTradingDate:"", 
        instrumentType:"",
        indexInstrumentId: ""
    }) {

        let {obj, action} = this.getObj(instrumentId);
        
        obj.name = name;
        obj.instrumentType = instrumentType;
        obj.country = country;
        obj.publisher = publisher;
        obj.site = site;
        obj.position = position;
        obj.iabCodes = iabCodes;
        obj.lastSettlementDate = lastSettlementDate;
        obj.tickSize = tickSize;
        obj.contractSize = contractSize;
        obj.lastTradingDate = lastTradingDate;

        obj.indexInstrumentId = indexInstrumentId;

        return {obj, action};
    }

    updatePrice(instrumentId, {buy, sell} = {buy: {} , sell: {} } ) {
        let {obj, action} = this.getObj(instrumentId);
        obj.buy  = {...buy} 
        obj.sell = {...sell};
    
        return {obj, action};
    }

    // {
    //     "messageType":"TradeStatisticsEvent",
    //     "sequenceNumber":944,
    //     "timestamp":1576145599.691572000,
    //     "instrumentId":"SE-SCHI-AFT-1-[1]-20C",
    //     "lastPrice":15,
    //     "lastQuantity":3,
    //     "highPrice":15,
    //     "lowPrice":15,
    //     "totalQuantity":3,
    //     "prevClosingPrice"
    //     "openingPrice":15
    // }
    updateTradeStatistics(instrumentId, 
        {lastPrice, lastQuantity, highPrice, lowPrice, totalQuantity, prevClosingPrice, openingPrice, diff, diffPct} = 
        {lastPrice:0, lastQuantity:0, highPrice:0, lowPrice:0, totalQuantity:0, prevClosingPrice:0, openingPrice:0, diff:0, diffPct:0}) {
        
        let {obj, action} = this.getObj(instrumentId);
        
        obj.lastPrice           = lastPrice;
        obj.lastQuantity        = lastQuantity;
        obj.highPrice           = highPrice;
        obj.lowPrice            = lowPrice;
        obj.totalQuantity       = totalQuantity;
        obj.prevClosingPrice    = prevClosingPrice;
        obj.openingPrice        = openingPrice;
        obj.diff                = diff;
        obj.diffPct             = diffPct;

        // We keep the price history in backend now!
        // if (lastPrice > 0) {
        //     if (!obj.prices) {
        //         obj.prices = []; // array of objects
        //     }
        //     obj.prices.push({
        //         timeStamp: Date.now(),  // @TODO BUG take from message instead!!
        //         price: lastPrice
        //     });
        // }

        return {obj, action};

    }

    // {  
    //     "messageType":"PositionUpdateEvent",
    //     "sequenceNumber":23,
    //     "source":"TEE",
    //     "timestamp":"2020-02-20T04:00:10.059430Z",
    //     "instrumentId":"SE-SCHI-AFT-1-[1]-20C",
    //     "firm":"company4",
    //     "startPosition":1,
    //     "position":1,
    //     "averagePrice":120.2,
    //     "lastPrice":120.2,
    //     "profit":{
    //         "realized":0,
    //         "unrealized":0,
    //         "accumulated":-5300.00
    //     }
    // }
    updatePosition(instrumentId, msg) {
        // eslint-disable-next-line
        let {obj, action} = this.getObj(instrumentId);
        // We pick some properties only to avoid circular reference (ie avoid instrument)
        const picked = (({ startPosition, position, averagePrice, profit }) => ({startPosition,position,averagePrice,profit}))(msg);
        obj.myposition = picked;
        return { obj, action };
    }

    // InstrumentRiskEvent
    // firm
    // instrumentId, sequenceNumber, source, timestamp
    // java.math.BigDecimal	bookValue	 
    // java.math.BigDecimal	grossProfit	 
    // java.lang.String	    productId	 
    // java.math.BigDecimal	riskValue	 
    updateInstrumentRisk(instrumentId, msg) {
        // eslint-disable-next-line
        let { obj, action } = this.getObj(instrumentId);

        obj.risk = {
            sequenceNumber: msg.sequenceNumber,
            source: msg.source,
            timestamp: msg.timestamp,
            bookValue: msg.bookValue,
            grossProfit: msg.grossProfit,
            productId: msg.productId,
            riskValue: msg.riskValue
        };
        
        return {obj, action}
    }

    print() {
        this.data.forEach(x => {
            console.log(x);
        });
    }

    getAllObjectsAsArray() {
        let arr = [];
        this.data.forEach(x => {
            arr.push(x);
        });
        return arr;
    }

    getInstrument(id) {
        return this.data.get(id);
    }
}

