import React, { Component } from 'react';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import preval from "preval.macro";
import "./About.scss";

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (

            <Container className="About">
                <Row>
                    <Col>
                        <h2>About {global.appVersion}</h2>
                        <h5>Version: {global.appVersion}</h5>
                        <h5>Build Date:{preval`module.exports = new Date().toLocaleString('sv-SE');`}</h5>
                        <h5>Server version:{this.props.server_version}</h5>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default About;