/*	-------------------------------------------------------------------------------




	-------------------------------------------------------------------------------
*/
import React, { Component } from "react";

import { AgGridReact } from "@ag-grid-community/react";
// import { AllCommunityModules } from "@ag-grid-community/all-modules";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import PriceBlinkRenderer from "renderers/PriceBlinkRenderer";
import { connect } from "react-redux";

import ModifyOrder from "./ModifyOrder";
import LabelWithOptions from "./LabelWithOptions";
import StyledCheckbox from "./StyledCheckbox";
import "./OwnOrders.scss";
import Utils from "utils/utils";
import { /* formatString, percentFormatter,*/ numberFormatter } from "./formatString.js";

/*	-------------------------------------------------------------------------------




	-------------------------------------------------------------------------------
*/
class OwnOrders extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modules: AllModules, // AllCommunityModules,
			rowData: [],
			components: {
				priceBlinkRenderer: PriceBlinkRenderer
			},
			buy: true,
			sell: true
		};
		this.hideCancelled = true; // will add support for showing them later!
		this.rowCache = new Map();

		this.defaultColDef = {
			resizable: true,
			cellStyle: { "text-align": "right" },
			suppressMovable: true,
			suppressMenu: true
		};

		this.columnDefs = [
			{
				headerName: "Timestamp",
				headerTooltip: "Timestamp of last update",
				field: "timestamp",
				sortable: true,
				sort: "desc",
				sortingOrder: ["desc", "asc"],
				valueGetter: param => {
					return Utils.timeStampToStr(param.data.timestamp);
				}
			},
			{
				headerName: "Instrument ID",
				headerTooltip: "Instrument ID",
				field: "instrumentId",
				sortable: true,
				sortingOrder: ["desc", "asc"]
			},
			{
				headerName: "Order ID",
				headerTooltip: "Unique order ID",
				field: "marketOrderId",
				sortable: true,
				sortingOrder: ["desc", "asc"]
			},
			// {
			// 	headerName: "##",
			// 	field: "clientOrderId",
			// 	sortable: true,
			// 	sortingOrder: ["desc","asc"],
			// },
			{
				headerName: "Firm",
				headerTooltip: "Firm responsible for the order",
				field: "firm",
				sortable: true,
				sortingOrder: ["desc", "asc"]
			},
			{
				headerName: "Trader",
				headerTooltip: "Trader who placed the order",
				field: "trader",
				sortable: true,
				sortingOrder: ["desc", "asc"]
			},
			{
				headerName: "BuySell",
				headerTooltip: "Buy or Sell order",
				field: "isBuy",
				sortable: true,
				sortingOrder: ["desc", "asc"],
				valueGetter: param => {
					return param.data.isBuy ? "Buy" : "Sell";
				}
			},
			{
				headerName: "State",
				headerTooltip: "Current state of the order",
				field: "state",
				sortable: true,
				sortingOrder: ["desc", "asc"]
			},
			{
				headerName: "Price",
				headerTooltip: "Price of the order",
				field: "price",
				sortable: true,
				sortingOrder: ["desc", "asc"],
				valueFormatter: numberFormatter,
				cellRenderer: "priceBlinkRenderer"
			},
			{
				headerName: "Org Qty",
				headerTooltip: "Original quantity of the order",
				field: "originalQuantity",
				sortingOrder: ["desc", "asc"],
				sortable: true
			},
			{
				headerName: "Rem Qty",
				headerTooltip: "Remaining quantity of the order",
				field: "remainingQuantity",
				sortable: true,
				sortingOrder: ["desc", "asc"]
			},
			{
				headerName: "Comment",
				headerTooltip: "A free text field",
				field: "text",
				sortable: true,
				sortingOrder: ["desc", "asc"],
				cellStyle: {
					"text-align": "left"
				}
			},
			{
				headerName: "Validity",
				headerTooltip: "Order validity",
				field: "timeInForce",
				sortable: true,
				sortingOrder: ["desc", "asc"],
				cellStyle: {
					"text-align": "left"
				}
			}
		];
	}

	fitColumns() {
		let allColumnIds = [];
		this.gridColumnApi.getAllColumns().forEach(function(column) {
			allColumnIds.push(column.colId);
		});
		this.gridColumnApi.autoSizeColumns(allColumnIds);
	}

	cancelAllOrders() {
		if (window.confirm("Do you want to delete ALL orders?")) {
			this.props.yax.sendCancelAllMyOrders();
		}
	}

	cancelSelected() {
		let rows = this.gridApi.getSelectedRows();
		if (window.confirm("Do you want to delete " + rows.length + " orders?")) {
			rows.forEach(row => {
				this.props.yax.cancelOrder(row.instrumentId, row.clientOrderId);
			});
		}
	}

	handleCheckSell(evt) {
		this.setState({ sell: evt.target.checked }, () => {
			let buySellFilterComponent = this.gridApi.getFilterInstance("isBuy");
			if (this.state.buy !== this.state.sell) {
				buySellFilterComponent.selectNothing();
				if (this.state.buy) {
					// This code will only show BUY
					buySellFilterComponent.selectValue("Buy");
				} else {
					// This code will only show BUY
					buySellFilterComponent.selectValue("Sell");
				}
				buySellFilterComponent.applyModel();
				this.gridApi.onFilterChanged();
			} else {
				// This code will only show BOTH
				buySellFilterComponent.selectNothing();
				buySellFilterComponent.selectValue("Buy");
				buySellFilterComponent.selectValue("Sell");
				buySellFilterComponent.applyModel();
				this.gridApi.onFilterChanged();
			}
		});
	}

	handleCheckBuy(evt) {
		this.setState({ buy: evt.target.checked }, () => {
			let buySellFilterComponent = this.gridApi.getFilterInstance("isBuy");
			if (this.state.buy !== this.state.sell) {
				buySellFilterComponent.selectNothing();
				if (this.state.buy) {
					// This code will only show BUY
					buySellFilterComponent.selectValue("Buy");
				} else {
					// This code will only show BUY
					buySellFilterComponent.selectValue("Sell");
				}
				buySellFilterComponent.applyModel();
				this.gridApi.onFilterChanged();
			} else {
				// This code will only show BOTH
				buySellFilterComponent.selectNothing();
				buySellFilterComponent.selectValue("Buy");
				buySellFilterComponent.selectValue("Sell");
				buySellFilterComponent.applyModel();
				this.gridApi.onFilterChanged();
			}
		});
	}

	render() {
		// let something_selected = false;
		if (this.gridApi) {
			// something_selected = this.gridApi.getSelectedRows().length > 0;
		}
		return (
			<div className="OwnOrders">
				<div className="ga-header">
					<h5>
						<LabelWithOptions
							label="Own Active Orders"
							options={
								<span>
									<StyledCheckbox
										label="Buy"
										checked={this.state.buy}
										onChange={this.handleCheckBuy.bind(this)}
									/>
									<StyledCheckbox
										label="Sell"
										checked={this.state.sell}
										onChange={this.handleCheckSell.bind(this)}
									/>
								</span>
							}
						/>
					</h5>
					<div className="ga-order">
						<ModifyOrder yax={this.props.yax} selectedOrder={this.state.selectedOrder} />
					</div>
				</div>

				<div className={"ga-grid " + this.props.view_options.theme}>
					<AgGridReact
						autoSizePadding={6}
						domLayout="autoHeight"
						/*** Definition ***/
						modules={this.state.modules}
						defaultColDef={this.defaultColDef}
						columnDefs={this.columnDefs}
						rowData={this.state.rowData}
						/*** Events ***/
						onGridReady={this.onGridReady.bind(this)}
						onCellClicked={this.onCellClicked.bind(this)}
						onRowSelected={this.onRowSelected.bind(this)}
						rowSelection={"multiple"}
						rowDeselection={true}
						/*** Identification ***/
						getRowNodeId={data => {
							return data.clientOrderId;
						}}
						/*** Customization ***/
						components={this.state.components}
						getContextMenuItems={this.getContextMenuItems.bind(this)}
						context={{}}
					/>
				</div>
			</div>
		);
	}

	getContextMenuItems(params) {
		var result = [
			{
				name: "Delete selected orders",
				action: () => {
					this.cancelSelected();
				},
				cssClasses: ["redFont", "bold"]
			},
			{
				name: "Delete All orders",
				action: () => {
					this.cancelAllOrders();
				},
				cssClasses: ["redFont", "bold"]
			}
		];
		return result;
	}

	updateData(obj) {
		if (!this.gridApi) {
			console.error("GridApi is not defined yet!");
			return;
		}

		/* key is marketOrderId right now */
		let key = obj.clientOrderId;

		if (!this.rowCache.get(key)) {
			if (obj.state !== "CANCELLED" && obj.state !== "FILLED") {
				let oldData = this.rowCache.get(obj.marketOrderId);
				let newData = { ...oldData, ...obj };
				console.debug("*** OWNO *** updateRowData:add:" + JSON.stringify(newData));
				this.gridApi.updateRowData({ add: [newData] });
				this.rowCache.set(key, newData);
			}
		} else {
			let oldData = this.rowCache.get(key);
			let newData = { ...oldData, ...obj };
			if (this.hideCancelled && (obj.state === "CANCELLED" || obj.state === "FILLED")) {
				console.debug("*** OWNO *** updateRowData:cancelled:" + JSON.stringify(newData));
				this.gridApi.updateRowData({ remove: [newData] });
				this.rowCache.delete(key);
			} else {
				console.debug("*** OWNO *** updateRowData:update:" + JSON.stringify(newData));
				this.gridApi.updateRowData({ update: [newData] });
				this.rowCache.set(key, newData);
			}
		}

		// deselect any selected order!
		this.setState({
			selectedOrder: undefined
		});
	}

	onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		console.debug("GRID: onGridReady()");

		this.gridApi.sizeColumnsToFit();
		window.addEventListener("resize", () => this.fitColumns);
		this.fitColumns();

		this.props.yax.getAllOwnOrders(order => {
			this.updateData(order); //
			this.fitColumns();
		});

		this.props.yax.addOwnOrdersSubscriber(order => {
			this.updateData(order); //
			this.fitColumns();
		});
	};

	onCellClicked(evt) {
		if (evt.node.selected) {
			// console.log(evt.node.data);
			// console.log(evt.node.selected);
			this.setState({ selectedOrder: evt.node.data });
		}
	}

	onRowSelected(evt) {}
}

// export default MarketGrid;
// definition of DummyComponent here...
const mapStateToProps = state => ({
	view_options: state.view_options
});
export default connect(mapStateToProps)(OwnOrders);
