import React, { Component } from 'react';
import { Card, CardImg, Cardbody, Cardtitle, Cardsubtitle, Cardtext, Badge, ButtonToolbar, Row, Col, Form, Button } from "react-bootstrap";
import { withAlert } from 'react-alert'

class ChangeUserPass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            chg_pass: false,

            // chg_password
            password: "",
            confpassword: ""
        };
        this.onChange = this.onChange.bind(this);
        this.handleStartChangePassword = this.handleStartChangePassword.bind(this);
        this.handleDoChangePassword = this.handleDoChangePassword.bind(this);
    }

    button(field, width, handler) {
        return (
            <Form.Group as={Col} xs={width} controlId={"form" + field}>
                <Button variant="primary" type="submit" onClick={handler}>
                    {field}
                </Button>
            </Form.Group>
        )
    }

    entry(field, width) {
        return (
            <Form.Group as={Col} xs={width} controlId={"form" + field}>
                <Form.Label>{field}</Form.Label>
                <Form.Control type="text" name={field} value={this.state[field]} onChange={this.onChange} />
            </Form.Group>
        )
    }

    onChange(evt) {
        console.log(evt.target);
        this.setState({
            [evt.target.name]: evt.target.value
        },
            () => {
                console.log(this.state);
            });

    }

    handleStartChangePassword(evt) {
        evt.preventDefault();
        this.setState({ chg_pass: true });
    }

    handleDoChangePassword(evt) {
        evt.preventDefault();

        if (this.state.password !== this.state.confpassword) {
            this.props.alert.show("Passwords must match!", { type: 'error' });
        }
        else {
            this.props.yax.sendSetMyPasswordRequest(this.props.firm, this.props.username, this.state.password, (obj) => {
                if (obj.success) {
                    this.props.alert.show("Password changed!");
                }
                else {
                    this.props.alert.show(obj.text);
                }
            });

            this.setState({ chg_pass: false });
        }
    }

    handleCancelChangePassword(evt) {
        evt.preventDefault();
        this.setState({ chg_pass: false });
    }


    nothing() {
        return <></>
    }

    render() {


        return (
            <Form autoComplete="off" className="CreateProduct">

                <Row>
                    <h5>User password</h5>
                </Row>
                <Row>
                    <Form.Group as={Col} xs={4} controlId={"form" + "firm"}>
                        <Form.Label>Firm</Form.Label>
                        <Form.Control type="text" name={"firm"} value={this.props["firm"]} readOnly />
                    </Form.Group>
                    <Form.Group as={Col} xs={4} controlId={"form" + "username"}>
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="text" name={"username"} value={this.props["username"]} readOnly />
                    </Form.Group>
                </Row>
                <Row>
                    {/* {this.button("Modify", 4, this.handleStartChangePassword.bind(this))} */}
                    <Form.Group as={Col} xs={4} controlId={"modifyButton"}>
                        <Button variant="primary" type="submit" onClick={this.handleStartChangePassword.bind(this)} disabled={this.state.chg_pass}>
                            Modify
                        </Button>
                    </Form.Group>
                </Row>

                {this.state.chg_pass ?
                    <>
                        < Row >
                            <Form.Group as={Col} xs={4} controlId={"form" + "password"}>
                                <Form.Label>password</Form.Label>
                                <Form.Control type="text" name={"password"} value={this.state["password"]} onChange={this.onChange} autoFocus={true} />
                            </Form.Group>
                            {this.entry("confpassword", 4)}
                        </Row>
                        < Row >
                            {this.button("Change", 2, this.handleDoChangePassword.bind(this))}
                            {this.button("Cancel", 2, this.handleCancelChangePassword.bind(this))}
                        </Row>
                    </>
                    : this.nothing()
                }
            </Form>
        );
    }
}

export default withAlert()(ChangeUserPass);