import InstrumentDatabase from "components/InstrumentDatabase";
//import moment from 'moment';
import moment from "moment-timezone";
import { typeOf } from "react-flags";
const uuid = require("uuid/v4");

/*  -------------------------------------------


    -------------------------------------------
*/
class YaxService {
	constructor(store) {
		this.store = store; // REDUX Store
		this.store.subscribe(this.handleStoreEvent.bind(this)); // @TODO somehow unsubscribe!

		this.reset();

		this.usertype = "";
		this.firm = "";
		this.trader = "";
		this.currentTradingState = {};

		// Remove this and replace with REDUX state instead!
		this.connectedCb = this.connectedCb.bind(this);
		this.disconnectedCb = this.disconnectedCb.bind(this);
		this.errorCb = this.errorCb.bind(this);

		this.connected = false;
		this.connectionSubscribers = [];
		this.authenticationSubscribers = [];
	}

	getLoginFirm() {
		return this.firm;
	}
	getLoginTrader() {
		return this.trader;
	}
	getLoginUsername() {
		return this.getLoginTrader();
	}

	getUserType() {
		return this.usertype;
	}
	isAdmin() {
		return this.getUserType() === "ADMIN";
	}
	getTradingState() {
		return this.currentTradingState;
	}

	/* utility */
	removeFromArray(label, obj, arr) {
		let index = arr.indexOf(obj);
		if (index === -1) {
			console.error("Error can't remove " + obj + " from array " + label);
		} else {
			arr.splice(index, 1);
		}
	}

	handleStoreEvent() {
		// let state = this.store.getState();
		/* */
	}

	reset() {
		/* Database */
		this.instrumentDb = new InstrumentDatabase();

		this.indexInstrLoaded = false;
		this.futureInstrLoaded = false;
		/* subscribers */

		// The Maps have an argument, usually InstrumentId
		this.instrumentSubscribers = {}; // @TODO: Convert to Map
		this.marketByLevelSubscribers = new Map();
		this.publicOrderSubscribers = new Map();
		this.publicTradeSubscribers = new Map();

		// The Arrays are just a list of subscribers
		this.allPublicTradeSubscribers = [];
		this.allPublicTrades = [];
		this.ownOrdersSubscribers = [];
		this.ownTradesSubscribers = [];
		this.positionSubscribers = [];
		this.marketAnnouncementSubscribers = [];
		this.accountSubscribers = [];
		this.firmEventSubscribers = [];
		this.userEventSubscribers = [];
		this.firmRiskUpdateEventSubscribers = [];
		this.instrumentRiskUpdateEventSubscribers = [];
		this.subscriptionEventSubscribers = [];

		// only handles TradingState...
		this.msgSubscriber = new Map();

		/* model data */
		this.positions = new Map();
		this.marketByLevel = new Map();
		this.publicOrders = new Map();
		this.publicTrades = new Map();
		this.marketAnnouncements = [];
		this.ownOrders = new Map();
		this.ownTrades = [];
		this.account = {}; // Account is just one object!
		this.firmRiskUpdateEventCache = new Map();
		this.instrumentRiskUpdateEventCache = new Map();
		this.accountEvents = [];
		this.userEvents = [];
		this.firmEvents = [];
		this.firms = new Map();
		this.users = new Map();
		this.riskSettings = {};
		this.subscriptionEvents = [];

		this.hasSubscribedToPrivateEventsFor = [];
		this.userProperties = new Map();

		/* contexts to keep track of requests/replies */
		this.reply_callbacks = new Map();
	}

	/*	--------------------------------------------------------------------------------------------------
		WEBSOCKET STUFF

		--------------------------------------------------------------------------------------------------
	*/
	isConnected() {
		return this.connected;
	}

	handleSocketClosed(e) {
		console.warn("*** YAXX *** Socket is closed. Reconnect will be attempted in 10 seconds", e.reason);

		this.reset(); // important to reset all data!

		this.ws = null;
		this.disconnectedCb();
		this.authenticationChanged(false);
		setTimeout(() => {
			this.connect_ws();
		}, 10000);
	}

	handleSocketError(err) {
		console.error("*** YAXX *** Socket encountered error: " + err.message + " details: ", JSON.stringify(err));
	}

	/*	--------------------------------------------------------------------------------------------------
		The big message switch

		--------------------------------------------------------------------------------------------------
	*/
	handleIncomingMessage(data) {
		let obj;
		try {
			obj = JSON.parse(data);

			// Skip these, so many!
			if (
				!["TradeStatisticsEvent", "FirmRiskUpdateEvent", "InstrumentRiskUpdateEvent"].includes(obj.messageType)
			) {
				if (JSON.stringify(obj).length > 800) {
					console.debug("*** YAXI *** " + obj.messageType + " too long, not logging it!");
				} else {
					console.debug("*** YAXI *** " + data);
				}
			}
		} catch (excb) {
			console.error("*** YAXX *** Exception:" + excb + " handling " + data);
			return;
		}

		/*
			Only Events have timestamps
		*/
		if (obj.messageType.includes("Event")) {
			if (!this.startingServerTimestamp) {
				this.startingServerTimestamp = moment(obj.timestamp).valueOf();
				this.startingClientTimestamp = moment().valueOf();
				this.initialDiff = this.startingClientTimestamp - this.startingServerTimestamp;
				console.log(
					"*** LAAG *** initial time is " +
						moment(obj.timestamp).format() +
						" local time is " +
						moment().format() +
						" and diff to local is " +
						this.initialDiff
				);
			}

			let diffTimestamp = moment().valueOf() - moment(obj.timestamp).valueOf();
			// console.log(
			// 	"*** LAAG *** calculating diff = (local) " +
			// 		moment().valueOf() +
			// 		" - (server) " +
			// 		moment(obj.timestamp).valueOf()
			// );
			console.log("*** LAAG *** diffTimestamp =  " + diffTimestamp);
			let realdiff = diffTimestamp - this.initialDiff;

			// If more than 20% lag, output message
			if (Math.abs(realdiff) > 300 && Math.abs(realdiff) < 120000) {
				// @TODO: send diff callback with red flag
				console.error("*** LAAG *** diffTimestamp " + realdiff);
			} else {
				// @TODO: send diff callback
			}
		}

		/*	----------------------------------
			Add some data to the message in
			certain cases.

			1) If there is an instrument ID,
			   find and add the instrument!

			   Protocol assumes that 
			   Instruments have arrived.

			----------------------------------
		*/

		if (
			obj.messageType !== "IndexInstrumentEvent" &&
			obj.messageType !== "FutureInstrumentEvent" &&
			obj.instrumentId
		) {
			let instrument = this.instrumentDb.getInstrument(obj.instrumentId);
			if (!instrument) {
				console.error("Instrument " + obj.instrumentId + " not found!");
			} else {
				obj.instrument = instrument;
			}
		}

		switch (obj.messageType) {
			/*	---------------------------------------------------------------
				Login protocol:
				1) User clicks logins which sends a LoginRequest
				2) On reception of LoginReply, we subscribe to IndexInstruments
					and FutureInstruments
				3) When both these subscriptions have entered state LIVE...
				4) ...we send subscriptions for all the other message types
				---------------------------------------------------------------
			*/
			/* REPLIES */
			case "LoginReply":
				if (obj.success) {
					/* 	---------------------------------------------------------------------------
						We are now logged in
					
						1) Query for configuration properties (and set a few defaults!)
						2) Subscribe IndexInstruments
						3) Subscribe FutureInstruments
						---------------------------------------------------------------------------
					 */

					// set some reasonable defaults
					this.userProperties.set("validateOrders", "true");
					this.userProperties.set("soundOnTrade", "false");
					this.userProperties.set("soundOnMarketAnnouncement", "false");

					this.sendQueryUserPropertiesRequest(obj => {
						console.log("*** PROP *** Loading " + obj.properties.length + " user properties!");
						obj.properties.forEach(item => {
							console.log("*** PROP *** Initializing property " + item.key + " = " + item.value);
							this.userProperties.set(item.key, item.value);
						});
					});

					this.sendMessage({
						messageType: "AddSubscriptionRequest",
						subscriptionId: "AddSubscriptionRequest-INDEX",
						subscriptionType: "SNAPSHOT_PLUS_UPDATES",
						eventType: "IndexInstrumentEvent"
					});

					this.sendMessage({
						messageType: "AddSubscriptionRequest",
						subscriptionId: "AddSubscriptionRequest-FUTURES",
						subscriptionType: "SNAPSHOT_PLUS_UPDATES",
						eventType: "FutureInstrumentEvent"
					});

					this.usertype = obj.type;
					// this.firm = obj.firm;  		@TODO ADD
					// this.trader = obj.trader;	@TODO ADD

					this.authenticationChanged(true, obj.serverVersion, obj.type); // @TODO add firm/trader
				} else {
					alert(obj.text);
				}
				break;

			/*

			 */
			case "IndexInstrumentEvent":
				obj.instrumentType = "INDEX";
				this.updateInstrument(obj.instrumentId, obj);
				break;

			case "FutureInstrumentEvent":
				obj.instrumentType = "FUTURE";
				this.updateInstrument(obj.instrumentId, obj);
				break;

			/*	---------------------------------------------------------------------------
				SubscriptionEvents have an important field: "state"

				state can be:

				* PENDING skickas ut för SNAPSHOT eller SNAPSHOT_PLUS_UPDATES när AddSubscriptionRequest accepterats
				* REPLAYING skickas ut för SNAPSHOT eller SNAPSHOT_PLUS_UPDATES före det första meddelandet i snapshot
				* DONE skickas ut för SNAPSHOT efter det sista meddelandet i snapshot
				* LIVE skickas ut för SNAPSHOT_PLUS_UPDATES  efter det sista meddelandet i snapshot


				---------------------------------------------------------------------------
			*/
			case "SubscriptionEvent":
				// eslint-disable-next-line
				switch (obj.subscriptionId) {
					case "AddSubscriptionRequest-INDEX":
						if (obj.state === "LIVE") {
							this.indexInstrLoaded = true;
						}
						break;
					case "AddSubscriptionRequest-FUTURES":
						if (obj.state === "LIVE") {
							this.futureInstrLoaded = true;
						}
						break;
				}

				/*	When indexes and futures are loaded, subscribe to all the rest! 
					@TODO: This should only be done ONCE, can we guarantee that?
				*/
				if (
					obj.subscriptionId === "AddSubscriptionRequest-INDEX" ||
					obj.subscriptionId === "AddSubscriptionRequest-FUTURES"
				) {
					if (this.indexInstrLoaded && this.futureInstrLoaded) {
						console.log("*** YAXX *** indexInstrLoaded && futureInstrLoaded -  now sending subscriptions");
						/*	inform REDUX that all instruments are loaded!
							@TODO: no-ones listening to this REDUX event right now
						 */

						this.store.dispatch({ type: "yax_instruments_loaded", loaded: true });

						// @TODO: Some of these should only be issued when usertype === TRADER
						this.sendMessage({
							messageType: "AddSubscriptionRequest",
							subscriptionId: "PrivateOrderEvent",
							subscriptionType: "SNAPSHOT_PLUS_UPDATES",
							eventType: "PrivateOrderEvent"
						});
						this.sendMessage({
							messageType: "AddSubscriptionRequest",
							subscriptionId: "PrivateTradeEvent",
							subscriptionType: "SNAPSHOT_PLUS_UPDATES",
							eventType: "PrivateTradeEvent"
						});
						this.sendMessage({
							messageType: "AddSubscriptionRequest",
							subscriptionId: "PositionUpdateEvent",
							subscriptionType: "SNAPSHOT_PLUS_UPDATES",
							eventType: "PositionUpdateEvent" // Private?
						});
						// @TODO: Someday, we might add this back!
						// this.sendMessage({
						// 	messageType: "AddSubscriptionRequest",
						// 	subscriptionId: "PublicOrderEvent",
						// 	subscriptionType: "SNAPSHOT_PLUS_UPDATES",
						// 	eventType: "PublicOrderEvent"
						// });
						this.sendMessage({
							messageType: "AddSubscriptionRequest",
							subscriptionId: "PublicTradeEvent",
							subscriptionType: "SNAPSHOT_PLUS_UPDATES",
							eventType: "PublicTradeEvent"
						});
						this.sendMessage({
							messageType: "AddSubscriptionRequest",
							subscriptionId: "MarketByLevelEvent",
							subscriptionType: "SNAPSHOT_PLUS_UPDATES",
							eventType: "MarketByLevelEvent"
						});
						this.sendMessage({
							messageType: "AddSubscriptionRequest",
							subscriptionId: "BestPriceEvent",
							subscriptionType: "SNAPSHOT_PLUS_UPDATES",
							eventType: "BestPriceEvent"
						});
						this.sendMessage({
							messageType: "AddSubscriptionRequest",
							subscriptionId: "TradeStatisticsEvent",
							subscriptionType: "SNAPSHOT_PLUS_UPDATES",
							eventType: "TradeStatisticsEvent"
						});
						this.sendMessage({
							messageType: "AddSubscriptionRequest",
							subscriptionId: "TradingStateEvent",
							subscriptionType: "SNAPSHOT_PLUS_UPDATES",
							eventType: "TradingStateEvent"
						});

						this.sendMessage({
							messageType: "AddSubscriptionRequest",
							subscriptionId: "MarketAnnouncementEvent",
							subscriptionType: "UPDATES",
							eventType: "MarketAnnouncementEvent"
						});

						/* PRIVATE EVENTS */
						this.sendMessage({
							messageType: "AddSubscriptionRequest",
							subscriptionId: "AccountUpdateEvent",
							subscriptionType: "SNAPSHOT_PLUS_UPDATES",
							eventType: "AccountUpdateEvent"
						});

						this.sendMessage({
							messageType: "AddSubscriptionRequest",
							subscriptionId: "FirmRiskUpdateEvent",
							subscriptionType: "SNAPSHOT_PLUS_UPDATES",
							eventType: "FirmRiskUpdateEvent"
						});

						this.sendMessage({
							messageType: "AddSubscriptionRequest",
							subscriptionId: "InstrumentRiskUpdateEvent",
							subscriptionType: "SNAPSHOT_PLUS_UPDATES",
							eventType: "InstrumentRiskUpdateEvent"
						});

						/* ADMIN EVENTS */

						if (this.usertype === "ADMIN") {
							this.sendMessage({
								messageType: "AddSubscriptionRequest",
								subscriptionId: "RiskSettingsEvent",
								subscriptionType: "SNAPSHOT_PLUS_UPDATES",
								eventType: "RiskSettingsEvent"
							});

							this.sendMessage({
								messageType: "AddSubscriptionRequest",
								subscriptionId: "FirmEvent",
								subscriptionType: "SNAPSHOT_PLUS_UPDATES",
								eventType: "FirmEvent"
							});

							this.sendMessage({
								messageType: "AddSubscriptionRequest",
								subscriptionId: "UserEvent",
								subscriptionType: "SNAPSHOT_PLUS_UPDATES",
								eventType: "UserEvent"
							});
						}
					}
				} // if (obj.subscriptionId === "AddSubscriptionRequest-INDEX" || obj.subscriptionId === "AddSubscriptionRequest-FUTURES")

				this.distributeSubscriptionState(obj);

				break; // SubscriptionEvent

			case "LogoutReply":
				console.log("User logged out. " + JSON.stringify(obj));
				break;

			case "ErrorReply":
				alert("Error from server: " + obj.text);
				break;

			case "InsertOrderReply":
				if (!obj.success) {
					alert("Failed to enter order: " + obj.text);
				}
				break;

			case "CancelAllOrdersReply":
				if (!obj.success) {
					alert("Failed to cancel all orders: " + obj.text);
				} else {
					alert("Succeeded to cancel all orders: " + obj.text);
				}
				break;
				break;

			case "ModifyOrderReply":
				if (!obj.success) {
					alert("Failed to modify order: " + obj.text);
				}
				break;

			case "CancelOrderReply":
				if (!obj.success) {
					alert("Failed to cancel order: " + obj.text);
				}
				break;

			case "AddSubscriptionReply":
				if (!obj.success) {
					console.error("*** YAXI *** Failed to subscribe: " + obj.text);
				} else {
					console.log("*** YAXI *** AddSubscriptionReply succeeded for " + JSON.stringify(obj));
				}
				break;

			case "QueryEventsReply":
				if (!obj.success) {
					console.error("Failed to send QueryEventsRequest: " + obj.text);
					alert("Failed to send QueryEventsRequest: " + obj.text);
				} else {
					console.debug("*** YAXI *** QueryEventsReply with " + obj.totalHits + " entries");
					// did we supply a callback with the request!?
					if (obj.context) {
						let callback = this.reply_callbacks.get(obj.context);
						callback(obj.events, obj);
						this.reply_callbacks.delete(obj.context);
					}
				}
				break;

			case "CreateFutureInstrumentReply":
				// @TODO USE CONTEXT INSTEAD!
				// this.broadcastMsg("CreateFutureInstrumentReply", obj);

				this.replyCallbackUsingContext(obj);
				break;

			/* EVENTS */
			case "TradingStateEvent":
				// @TODO Should all messages be broadcasted to subscribers
				this.currentTradingState = obj;
				this.broadcastMsg("TradingStateEvent", obj);
				break;

			case "BestPriceEvent":
				/*
				{
					"messageType":"BestPriceEvent",
					"sequenceNumber":2,
					"timestamp":1575541593.416441,
					"instrumentId":"SE-SCHI-AFT-1-[1]",
					"state":"CREATED",
					"name":"Schibstedt Aftonbladet Frontpage Segment 1 Jan 2020",
					"country":"SE",
					"publisher":"SCHI",
					"site":"AFT",
					"position":"1",
					"lotSize":100,
					"tickSize":0.1,
					"buy":{
						"price":16.6,
						"quantity":1
					},
					"sell":{
						"price":16.6,
						"quantity":1
					}
				}
				*/
				this.updateInstrumentBestPrice(obj.instrumentId, obj);
				break;

			/* {
				"messageType":"TradeStatisticsEvent",
				"sequenceNumber":7306,
				"timestamp":1576594045.855399000,
				"instrumentId":"SE-SCHI-AFT-1-[1]",
				"lastPrice":106,
				"prevClosingPrice":187
			} */
			case "TradeStatisticsEvent":
				if (obj.prevClosingPrice && obj.lastPrice) {
					obj.diff = obj.lastPrice - obj.prevClosingPrice;
					obj.diffPct = ((obj.lastPrice - obj.prevClosingPrice) / obj.prevClosingPrice) * 100.0;
				} else {
					obj.diff = "";
				}
				this.updateInstrumentTradeStatistics(obj.instrumentId, obj);

				// @TODO: Special handling of INDEX?
				let instr = this.instrumentDb.getInstrument(obj.instrumentId);
				if (instr && obj.instrumentType == "INDEX") {
					// this.updateIndex(obj);
				}

				break;

			case "PublicTradeEvent":
				this.distributePublicTrade(obj);
				break;
			/*
              "messageType":"PrivateOrderEvent",
              "timestamp":1574764374.861872000,
              "firm":"Bambalo",
              "trader":"Johan",
              "state":"ACKNOWLEDGED",
              "instrumentId":"SE-SCHI-AFT-1-[1]-20A",
              "clientOrderId":"AUTO",
              "marketOrderId":"25",
              "isBuy":false,
              "price":null,
              "remainingQuantity":null,
              "originalQuantity":null,
              "text":"From Orderline.js" 
          */
			case "PrivateOrderEvent":
				let instrument = this.instrumentDb.getInstrument(obj.instrumentId);
				if (!instrument) {
					console.error("Instrument " + obj.instrumentId + " not found!");
				} else {
					obj.instrument = instrument;
				}
				this.distributeOwnOrder(obj);
				break;

			case "PositionUpdateEvent":
				this.distributePositionUpdate(obj);
				break;

			case "PrivateTradeEvent":
				this.distributeOwnTrade(obj);
				break;

			case "PublicOrderEvent":
				this.distributePublicOrder(obj);
				break;

			case "MarketByLevelEvent":
				this.distributeMarketByLevelUpdate(obj);
				break;

			case "MarketAnnouncementEvent":
				console.error("MARKET ANNOUNCEMENT:" + obj.text);
				this.distributeMarketAnnouncement(obj);
				break;

			/*	PRIVATE EVENTS (with firm)
			 */
			case "AccountUpdateEvent":
				this.distributeAccount(obj);
				break;

			case "FirmRiskUpdateEvent":
				// @TODO: Remove Msg subscription since its a performance nightmare
				// this.distributeMsg(obj.messageType, obj);
				this.distributeFirmRiskUpdateEvent(obj);
				break;

			case "InstrumentRiskUpdateEvent":
				// this.distributeMsg(obj.messageType, obj);
				this.distributeInstrumentRiskUpdateEvent(obj);
				break;

			case "RiskSettingsEvent":
				this.riskSettings = obj;
				//this.distributeRiskSettingsEvent(obj);  @TODO Implement handler for RiskSettingsEvent
				break;

			/*
				ADMIN Events, only received if you are an ADMIN
			*/
			case "FirmEvent":
				// save all firms
				let firmKey = obj.name;
				if (!this.firms.has(firmKey)) {
					console.log("*** YAXX *** Adding firm " + firmKey);
					this.firms.set(firmKey, obj);
				} else {
					console.log("*** YAXX *** firm " + firmKey + " was already saved!");
				}

				if (!this.hasSubscribedToPrivateEventsFor.includes(obj.name)) {
					// save it so we dont subscribe more than once
					this.hasSubscribedToPrivateEventsFor.push(obj.name);

					// note that firm id called "name" in this message
					console.log("*** YAXX *** subscribing to private events for " + obj.name);

					/* PRIVATE EVENTS */

					// @TODO WHAT ABOUT THE "CURRENT" FIRM SUBSCRPTION? DUPLICATED?
					this.sendMessage({
						messageType: "AddSubscriptionRequest",
						subscriptionId: "AccountUpdateEvent" + obj.name,
						subscriptionType: "SNAPSHOT_PLUS_UPDATES",
						eventType: "AccountUpdateEvent",
						firm: obj.name
					});

					this.sendMessage({
						messageType: "AddSubscriptionRequest",
						subscriptionId: "FirmRiskUpdateEvent" + obj.name,
						subscriptionType: "SNAPSHOT_PLUS_UPDATES",
						eventType: "FirmRiskUpdateEvent",
						firm: obj.name
					});

					this.sendMessage({
						messageType: "AddSubscriptionRequest",
						subscriptionId: "PositionUpdateEvent" + obj.name,
						subscriptionType: "SNAPSHOT_PLUS_UPDATES",
						eventType: "PositionUpdateEvent",
						firm: obj.name
					});

					// Skip these since they are intermixed with Price updates
					// this.sendMessage({
					// 	messageType: "AddSubscriptionRequest",
					// 	subscriptionId: "InstrumentRiskUpdateEvent" + obj.name,
					// 	subscriptionType: "SNAPSHOT_PLUS_UPDATES",
					// 	eventType: "InstrumentRiskUpdateEvent",
					// 	firm: obj.name
					// });
				}

				this.distributeFirmEvent(obj);
				break;

			case "UserEvent":
				// save all users
				let userKey = obj.firm + "/" + obj.username;
				if (!this.users.has(userKey)) {
					console.log("*** YAXX *** Adding user " + userKey);
					this.users.set(userKey, obj);
					console.log("*** YAXX *** SIZE " + this.users.size);
				} else {
					console.log("*** YAXX *** user " + userKey + " was already saved!");
				}

				console.log(
					"*** YAXX *** UserEvent " +
						obj.username +
						" is " +
						(obj.enabled ? "enabled" : "disabled") +
						" and " +
						(obj.loggedIn ? "logged in" : "NOT logged in")
				);
				this.distributeUserEvent(obj);
				break;

			case "SetUserPasswordReply":
				this.replyCallbackUsingContext(obj);
				break;

			case "SetUserPropertyReply":
				if (obj.success) {
					//this.userProperties.set(obj.key, obj.name);
				}
				this.replyCallbackUsingContext(obj);
				break;

			case "DeleteUserPropertyReply":
				if (obj.success) {
					//this.userProperties.delete(obj.key);
				}
				this.replyCallbackUsingContext(obj);
				break;

			case "QueryUserPropertiesReply":
				this.replyCallbackUsingContext(obj);
				break;

			default:
				console.error("Unknown incoming message: " + JSON.stringify(obj));

				/* Catch Replies that we don't have coded specifically for */
				if (obj.messageType.includes("Reply")) {
					if (!obj.success) {
						alert(obj.text);
					}
				}
				break;
		}
	}

	disconnect_ws() {
		this.ws.close();
	}

	/**
	 *
	 *
	 *
	 */
	connect_ws() {
		console.log("*** YAXX *** connect_ws( " + process.env.REACT_APP_SERVERURL + ")");
		this.SERVERURL = process.env.REACT_APP_SERVERURL;

		try {
			this.ws = new WebSocket(this.SERVERURL);

			this.ws.onopen = () => {
				console.log("*** YAXX *** websocket onopen()");
				this.connectedCb();
			};

			this.ws.onmessage = e => {
				this.handleIncomingMessage(e.data);
			};
			/*  ---------------------------------------------------------------------------------------
          		Websocket events
          		---------------------------------------------------------------------------------------
      		*/
			this.ws.onclose = e => {
				console.log("*** YAXX *** websocket onclose()");
				this.handleSocketClosed(e);
			};

			this.ws.onerror = err => {
				console.log("*** YAXX *** websocket onerror()");
				this.handleSocketError(err);
			};
		} catch (excb) {
			console.error("*** YAXX *** Exception:" + excb + " handling " + excb.data);
			this.ws = null;
		}
	}

	/*	--------------------------------------------------------------------------------------------------
		END WEBSOCKET STUFF

		--------------------------------------------------------------------------------------------------
	*/

	//	--------------------------------------------------------------------------------------------------
	//	END WEBSOCKET STUFF
	//
	//	--------------------------------------------------------------------------------------------------

	/*

		There exists 2 subscription models

		A) 'the old'

		addXXXSubscriber():    adds a subscription for new messages of this kind. 
		removeXXXSubscriber(): removes a subscription
		getAllXXX(): returns all cached messages

		broadcastXXX(): sends the message to all subscribers

		Positives:	Easy to understand. No "action" parameter to the callback
		Negatives:  client must call both getAllXXX() and addXXXSubscriber(). Small timewindow could cause 
					race condition in a multithreaded environment

		B) 'the new'

		addXXXSubscriber(): adds a subscription for new messages of this kind. Also sends all cached
							with action = "init". Finishes off with a call to the callback with action = "live".
							
		removeXXXSubscriber(): removes a subscription
		broadcastXXX(): sends the message to all subscribers with action "add". (could in some cases be "update" or "delete" but "add is most common")

		Positives:  easy protocol for the client. First re-render when "live" arrives. Also rerender when "add" arrives.
		Negatives:  You have to look at the "action" parameter in the callback


		C) is not yet implemented.

		Functions as B) but a generic one, where you can subscribe to any message


		NOTE:  usually it's "init", "live" and "add" but sometimes the verb can be "mod" too!
	*/

	//	--------------------------------------------------------------------------------------------------
	//	GENERIC MESSAGE SUBSCRIPTION
	//	We use this for the odd messages that have no data model
	//	(Only used for TradingState right now - CHANGE!!)
	//	--------------------------------------------------------------------------------------------------
	addMsgSubscriber(evttype, cb) {
		if (!this.msgSubscriber.get(evttype)) {
			this.msgSubscriber.set(evttype, []);
		}
		this.msgSubscriber.get(evttype).push(cb);
	}
	broadcastMsg(evttype, msg) {
		if (this.msgSubscriber.get(evttype)) {
			this.msgSubscriber.get(evttype).forEach(cb => {
				cb(msg);
			});
		} else {
			console.warn("No subscribers for " + evttype);
		}
	}
	removeMsgSubscriber(evttype, cb) {
		let index = this.msgSubscriber.get(evttype).indexOf(cb);
		if (index === -1) {
			console.error("Error can't remove subscriber from array msgSubscriber");
		} else {
			this.msgSubscriber.get(evttype).splice(index, 1);
		}
	}

	//	--------------------------------------------------------------------------------------------------
	//	CONNECTION/AUTHENTICATION EVENTS
	//
	//	--------------------------------------------------------------------------------------------------
	addConnectionSubscriber(subscriber) {
		this.connectionSubscribers.push(subscriber);
	}
	removeConnectionSubscriber(cb) {
		this.removeFromArray("connectionSubscribers", cb, this.connectionSubscribers);
	}

	connectedCb() {
		this.connected = true;
		this.connectionSubscribers.forEach(subscriber => {
			subscriber(true, "*** YAXX *** Connected to endpoint");
		});
	}
	disconnectedCb() {
		this.connected = false;
		this.connectionSubscribers.forEach(subscriber => {
			subscriber(false, "*** YAXX *** Disconnected from endpoint");
		});
	}
	errorCb() {
		this.connected = false;
		this.connectionSubscribers.forEach(subscriber => {
			subscriber(false, "*** YAXX *** Error on connection to endpoint");
		});
	}

	addAuthenticationSubscriber(subscriber) {
		this.authenticationSubscribers.push(subscriber);
	}
	removeAuthenticationSubscriber(cb) {
		this.removeFromArray("authenticationSubscribers", cb, this.authenticationSubscribers);
	}

	authenticationChanged(auth, server_version, user_type) {
		this.authenticated = auth;
		this.authenticationSubscribers.forEach(cb => {
			cb(auth, server_version, user_type);
		});
	}

	//	--------------------------------------------------------------------------------------------------
	//	OWNORDER EVENTS
	//	STATUS: A)
	//	--------------------------------------------------------------------------------------------------
	addOwnOrdersSubscriber(cb) {
		console.debug("*** YAXX *** Adding an ownorder subscriber ");
		this.ownOrdersSubscribers.push(cb);
	}

	getAllOwnOrders(cb) {
		this.ownOrders.forEach(elem => {
			cb(elem);
		});
	}

	distributeOwnOrder(obj) {
		let key = obj.clientOrderId;
		this.ownOrders.set(key, obj);
		this.ownOrdersSubscribers.forEach(cb => {
			cb(obj);
		});
	}

	//	--------------------------------------------------------------------------------------------------
	//	OWNTRADE EVENTS
	//	STATUS: B)
	//	--------------------------------------------------------------------------------------------------
	addOwnTradesSubscriber(cb) {
		console.debug("*** YAXX *** Adding an owntrades subscriber ");
		this.ownTradesSubscribers.push(cb);

		this.ownTrades.forEach(obj => {
			cb("init", obj);
		});
		cb("live");
	}
	removeOwnTradesSubscriber(cb) {
		this.removeFromArray("OwnTradesSubscribers", cb, this.ownTradesSubscribers);
	}

	// getAllOwnTrades(cb) {
	// 	this.ownTrades.forEach(obj => {
	// 		cb(obj);
	// 	});
	// }

	distributeOwnTrade(obj) {
		this.ownTrades.push(obj);
		this.ownTradesSubscribers.forEach(cb => {
			cb("add", obj);
		});
	}

	//	--------------------------------------------------------------------------------------------------
	//	OWNPOSITION EVENTS
	//	STATUS: B)
	//	@TODO: rename to OwnPosition for consistency
	//	--------------------------------------------------------------------------------------------------
	addPositionSubscriber(cb) {
		console.debug("*** YAXX *** Adding a positions subscriber ");
		this.positionSubscribers.push(cb);

		this.positions.forEach(p => {
			cb("init", p);
		});
		cb("live");
	}

	removePositionSubscriber(cb) {
		this.removeFromArray("positionSubscribers", cb, this.positionSubscribers);
	}

	distributePositionUpdate(obj) {
		if (obj.firm === this.firm) {
			this.instrumentDb.updatePosition(obj.instrumentId, obj);
		}

		// @TODO: Should send instrument update??

		this.positions.set(obj.firm + "/" + obj.instrumentId, obj);
		this.positionSubscribers.forEach(cb => {
			cb("set", obj);
		});
	}

	getAllPositions() {
		return this.positions;
	}

	//	--------------------------------------------------------------------------------------------------
	//	PUBLICORDER EVENTS
	//	STATUS: A) (almost)
	//	--------------------------------------------------------------------------------------------------
	addPublicOrderSubscriber(symbol, cb) {
		console.debug("*** YAXX *** Adding a subscription for " + symbol);
		if (!this.publicOrderSubscribers.get(symbol)) {
			this.publicOrderSubscribers.set(symbol, []);
		}
		this.publicOrderSubscribers.get(symbol).push(cb);
	}

	removePublicOrderSubscriber(symbol, cb) {
		if (this.publicOrderSubscribers.get(symbol)) {
			console.debug("*** YAXX *** Removing a PublicOrder subscription for " + symbol);

			let arr = this.publicOrderSubscribers.get(symbol);
			let pos = arr.indexOf(cb);
			if (pos === -1) {
				console.error("*** YAXX *** No Public order subscription found for " + symbol);
			} else {
				console.debug("*** YAXX *** Public order subscription found for " + symbol + " on index " + pos);
				arr.splice(pos, 1);
			}
			// @TODO Necessary?
			this.publicOrderSubscribers.set(symbol, arr);
			this.debugPublicOrderSubscribers();
		} else {
			console.error("*** YAXX *** No Public order subscription found for " + symbol);
		}
	}

	/* All orders are stored in an array, stored in a Map keyed by InstrumentId */
	distributePublicOrder(obj) {
		let oarr = this.publicOrders.get(obj.instrumentId);
		if (!oarr) {
			this.publicOrders.set(obj.instrumentId, []);
			oarr = this.publicOrders.get(obj.instrumentId);
		}

		// We don't save CANCELLED or FILLED orders
		if (obj.state !== "CANCELLED" && obj.state !== "FILLED") {
			oarr.push(obj);
		} else {
			// since it's CANCELLED or FILLED we must remove this order!
			let index = oarr.indexOf(obj.marketOrderId);
			if (index !== -1) {
				oarr.splice(index);
			}
		}

		// Distribute it (also CANCELLED or FILLED so the view  can do its thing!)
		if (this.publicOrderSubscribers.get(obj.instrumentId)) {
			this.publicOrderSubscribers.get(obj.instrumentId).forEach(cb => {
				console.debug("*** YAXX *** Distributing Publicorder " + JSON.stringify(obj));
				cb(obj.instrumentId, obj);
			});
		}
	}

	getAllPublicOrders(instrumentId, cb) {
		if (this.publicOrders.get(instrumentId)) {
			this.publicOrders.get(instrumentId).forEach(x => {
				// skip these for performance
				if (x.state !== "CANCELLED" && x.state !== "FILLED") {
					cb(instrumentId, x);
				}
			});
		}
	}

	//	--------------------------------------------------------------------------------------------------
	//	ALLPUBLICTRADE EVENTS
	//	STATUS: B)
	//	--------------------------------------------------------------------------------------------------
	addAllPublictradeSubscriber(cb) {
		this.allPublicTradeSubscribers.push(cb);
		this.allPublicTrades.forEach(item => {
			cb("init", item);
		});
		cb("live");
	}
	removeAllPublicTradeSubscriber(cb) {
		// @TODO
	}

	//	--------------------------------------------------------------------------------------------------
	//	PUBLICTRADE EVENTS
	//	STATUS: B)
	//	--------------------------------------------------------------------------------------------------
	addPublicTradeSubscriber(symbol, cb) {
		let tarr = this.publicTrades.get(symbol);
		if (!tarr) {
			this.publicTrades.set(symbol, []);
			tarr = this.publicTrades.get(symbol);
		}

		console.debug("*** YAXX *** Adding an trades subscriber ");
		if (!this.publicTradeSubscribers.get(symbol)) {
			this.publicTradeSubscribers.set(symbol, []);
		}
		this.publicTradeSubscribers.get(symbol).push(cb);

		tarr.forEach(t => {
			cb("init", t);
		});

		cb("live");
	}

	removePublicTradeSubscriber(symbol, cb) {
		if (!symbol) {
			console.error("*** YAXX *** Invalid symbol: [" + symbol + "]");
		} else if (this.publicTradeSubscribers.get(symbol)) {
			console.debug("*** YAXX *** Removing a PublicTrade subscription for " + symbol);

			let arr = this.publicTradeSubscribers.get(symbol);
			let pos = arr.indexOf(cb);
			if (pos === -1) {
				console.error("*** YAXX *** No Public Trade subscription found for " + symbol);
			} else {
				console.debug("*** YAXX *** Public Trade subscription found for " + symbol + " on index " + pos);
				arr.splice(pos, 1);
			}
			// @TODO Necessary?
			this.publicTradeSubscribers.set(symbol, arr);
			//this.debugPublicOrderSubscribers();
		} else {
			console.error("*** YAXX *** No Public Trade subscription found for " + symbol);
		}
	}

	/* All orders are stored in an array, stored in a Map keyed by InstrumentId */
	distributePublicTrade(obj) {
		let oarr = this.publicTrades.get(obj.instrumentId);
		if (!oarr) {
			this.publicTrades.set(obj.instrumentId, []);
			oarr = this.publicTrades.get(obj.instrumentId);
		}

		// let index = oarr.indexOf(obj.tradeId);  // @TODO Check ID!
		// if (index !== -1) {
		// 	oarr.splice(index);
		// }
		oarr.push(obj);

		// Distribute it (also CANCELLED or FILLED so the view  can do its thing!)
		if (this.publicTradeSubscribers.get(obj.instrumentId)) {
			this.publicTradeSubscribers.get(obj.instrumentId).forEach(cb => {
				console.debug("*** YAXX *** Distributing PublicTrade " + JSON.stringify(obj));
				cb("add", obj);
			});
		}

		/*
			Also send to the Tickers! All save all public trades...
		*/
		this.allPublicTrades.push(obj);
		this.allPublicTradeSubscribers.forEach(cb => {
			cb("add", obj);
		});
	}

	// getAllPublicTrades(instrumentId, cb) {
	// 	if (this.publicTrades.get(instrumentId)) {
	// 		this.publicTrades.get(instrumentId).forEach(x => {
	// 			cb(instrumentId, x);
	// 		});
	// 	}
	// }

	//	--------------------------------------------------------------------------------------------------
	//	MARKETBYLEVEL EVENTS
	//	STATUS: B)
	//	--------------------------------------------------------------------------------------------------
	addMarketByLevelSubscriber(instrumentId, cb) {
		console.debug("*** YAXX *** Adding a subscription for " + instrumentId);

		// setup cache
		if (!this.marketByLevel.get(instrumentId)) {
			this.marketByLevel.set(instrumentId, {}); // reasonable default?
		}

		// add subscriber for this symbol
		if (!this.marketByLevelSubscribers.get(instrumentId)) {
			this.marketByLevelSubscribers.set(instrumentId, []);
		}
		this.marketByLevelSubscribers.get(instrumentId).push(cb);

		cb("init", this.marketByLevel.get(instrumentId));
		cb("live", null);
	}

	distributeMarketByLevelUpdate(obj) {
		// save moedl
		this.marketByLevel.set(obj.instrumentId, obj);

		// send it to all subscribers
		if (this.marketByLevelSubscribers.get(obj.instrumentId)) {
			this.marketByLevelSubscribers.get(obj.instrumentId).forEach(cb => {
				cb("update", obj);
			});
		}
	}

	// getAllMarketByLevel(instrumentId, cb) {
	// 	if (this.marketByLevel.get(instrumentId)) {
	// 		cb(instrumentId, this.marketByLevel.get(instrumentId));
	// 	}
	// }

	removeMarketByLevelSubscriber(instrumentId, cb) {
		let arr = this.marketByLevelSubscribers.get(instrumentId);
		let pos = arr.indexOf(cb);
		if (pos === -1) {
			console.error("*** YAXX *** No MarketByLevel subscription found for " + instrumentId);
		} else {
			console.debug("*** YAXX *** MarketByLevel subscription found for " + instrumentId + " on index " + pos);
			arr.splice(pos, 1);
		}
	}

	//	--------------------------------------------------------------------------------------------------
	//	INSTRUMENT EVENTS
	//	STATUS: B)
	//	SYMBOL can be "*" !
	//	--------------------------------------------------------------------------------------------------
	addInstrumentSubscriber(symbol, cb) {
		console.debug("*** YAXX *** Adding a subscription for " + symbol);
		if (!this.instrumentSubscribers[symbol]) {
			this.instrumentSubscribers[symbol] = [];
		}
		this.instrumentSubscribers[symbol].push(cb);

		// send initial data!
		if (symbol === "*") {
			let instrData = this.getAllInstruments();
			instrData.forEach(item => {
				cb("init", item);
			});
			cb("live");
		} else {
			let instr = this.instrumentDb.getInstrument(symbol);
			if (instr) {
				cb("init", instr);
			}
			cb("live");
		}
	}

	getFirstIndexInstrumentId() {
		this.getAllInstruments().forEach(item => {
			if (item.instrumentType === "INDEX") return item.instrumentId;
		});
		return "";
	}

	removeInstrumentSubscriber(symbol, cb) {
		console.debug("*** YAXX *** Removing a subscription for " + symbol);
		let instrumentSubscribers = this.instrumentSubscribers[symbol];
		if (!instrumentSubscribers) {
			console.error("*** YAXX *** Error can't remove subscriptions for symbol:" + symbol);
		} else {
			instrumentSubscribers.splice(instrumentSubscribers.indexOf(cb), 1);
		}
	}

	removeAllInstrumentSubscribers() {
		this.instrumentSubscribers = {};
	}

	distributeInstrumentUpdate(action, obj) {
		if (obj) {
			if (this.instrumentSubscribers[obj.instrumentId]) {
				this.instrumentSubscribers[obj.instrumentId].forEach(subscriber => {
					subscriber(action, obj);
				});
			}
		}

		// * means all instruments
		if (this.instrumentSubscribers["*"]) {
			this.instrumentSubscribers["*"].forEach(subscriber => {
				subscriber(action, obj);
			});
		}
	}

	updateInstrumentBestPrice(instrumentId, msg) {
		let { obj, action } = this.instrumentDb.updatePrice(instrumentId, msg);
		this.distributeInstrumentUpdate(action, obj);
	}

	updateInstrumentTradeStatistics(instrumentId, msg) {
		let { obj, action } = this.instrumentDb.updateTradeStatistics(instrumentId, msg);
		this.distributeInstrumentUpdate(action, obj);
	}

	updateInstrument(instrumentId, msg) {
		let { obj, action } = this.instrumentDb.updateInstr(instrumentId, msg);
		this.distributeInstrumentUpdate(action, obj);
	}

	getAllInstruments(instrType) {
		let list = this.instrumentDb.getAllObjectsAsArray();
		if (instrType) {
			return list.filter(instr => instr.instrumentType === instrType);
		}
		return list;
	}

	getAllUnderlyings() {
		return this.getAllIndexes();
	}

	getAllIndexes() {
		return this.getAllInstruments("INDEX");
	}

	getAllFutures() {
		return this.getAllInstruments("FUTURE");
	}

	getAllFuturesFor(underlying) {
		return this.getAllFutures().filter(item => {
			return item.indexInstrumentId === underlying;
		});
	}

	getInstrument(instrumentId) {
		return this.instrumentDb.getInstrument(instrumentId);
	}

	getRiskSettings() {
		return this.riskSettings;
	}

	//	--------------------------------------------------------------------------------------------------
	//	MARKET ANNOUNCEMENT EVENTS
	//	STATUS: B)
	//
	//	--------------------------------------------------------------------------------------------------
	addMarketAnnouncementSubscriber(cb) {
		console.debug("*** YAXX *** Adding a positions subscriber ");
		this.marketAnnouncementSubscribers.push(cb);

		console.debug("*** YAXX *** sending all cached announcements (bad idea?)");
		this.marketAnnouncements.forEach(p => {
			cb("init", p);
		});
		cb("live");
	}

	removeMarketAnnouncementSubscriber(cb) {
		this.removeFromArray("MarketAnnouncementSubscriber", cb, this.marketAnnouncementSubscribers);
	}

	distributeMarketAnnouncement(obj) {
		this.marketAnnouncementSubscribers.forEach(cb => {
			cb("add", obj);
		});
	}

	//	--------------------------------------------------------------------------------------------------
	//	ACCOUNT EVENTS
	//	STATUS: B)
	//	NOTE: Uses "MOD" not "ADD"
	//	--------------------------------------------------------------------------------------------------
	addAccountSubscriber(cb) {
		console.debug("*** YAXX *** Adding a account subscriber ");
		this.accountSubscribers.push(cb);

		this.accountEvents.forEach(obj => {
			cb("init", obj);
		});
		cb("live");
	}

	removeAccountSubscriber(cb) {
		this.removeFromArray("AccountSubscriber", cb, this.accountSubscribers);
	}

	distributeAccount(obj) {
		this.account = obj;
		this.accountEvents.push(obj);
		this.accountSubscribers.forEach(cb => {
			cb("mod", obj);
		});
	}

	//	--------------------------------------------------------------------------------------------------
	//	FIRM EVENTS
	//	STATUS: B)
	//	NOTE: Uses "MOD" not "ADD"
	//	--------------------------------------------------------------------------------------------------
	addFirmEventSubscriber(cb) {
		console.debug("*** YAXX *** Adding a firm event subscriber ");
		this.firmEventSubscribers.push(cb);

		this.firmEvents.forEach(obj => {
			cb("init", obj);
		});
		cb("live");
	}

	removeFirmEventSubscriber(cb) {
		this.removeFromArray("FirmEventSubscriber", cb, this.firmEventSubscribers);
	}

	distributeFirmEvent(obj) {
		// update firms
		this.firms.set(obj.name, obj);

		this.firmEvents.push(obj);
		this.firmEventSubscribers.forEach(cb => {
			cb("mod", obj);
		});
	}

	//	--------------------------------------------------------------------------------------------------
	//	USER EVENTS
	//	STATUS: B)
	//	NOTE: Uses "MOD" not "ADD"
	//	--------------------------------------------------------------------------------------------------
	addUserEventSubscriber(cb) {
		console.debug("*** YAXX *** Adding a user event subscriber ");
		this.userEventSubscribers.push(cb);

		console.debug(`*** YAXX *** Sending ${this.userEvents.length} cached user events`);
		// @TODO Possibly we should only send the LATEST event instead of every event in sequence
		this.userEvents.forEach(obj => {
			cb("init", obj);
		});
		console.debug(`*** YAXX *** User Events switching to live`);
		cb("live");
	}

	removeUserEventSubscriber(cb) {
		this.removeFromArray("UserEventSubscriber", cb, this.userEventSubscribers);
	}

	distributeUserEvent(obj) {
		this.userEvents.push(obj);
		// @TODO: Save latest user event:  this.userEvent.set(obj.firm, obj.username, obj);
		this.userEventSubscribers.forEach(cb => {
			cb("mod", obj);
		});
	}

	//	--------------------------------------------------------------------------------------------------
	//	FIRM RISK EVENTS
	//	STATUS: B)
	//	NOTE: Uses "MOD" not "ADD"
	//	--------------------------------------------------------------------------------------------------
	addFirmRiskUpdateEventSubscriber(cb) {
		console.debug("*** YAXX *** Adding a firm risk event subscriber ");
		this.firmRiskUpdateEventSubscribers.push(cb);
		this.firmRiskUpdateEventCache.forEach((value, key, map) => {
			// Only send LAST update, not all events!
			cb("init", value);
		});
		cb("live");
	}

	removeFirmRiskUpdateEventSubscriber(cb) {
		this.removeFromArray("FirmRiskUpdateEventSubscriber", cb, this.firmRiskUpdateEventSubscribers);
	}

	distributeFirmRiskUpdateEvent(obj) {
		//this.firmRiskEvents.push(obj);
		this.firmRiskUpdateEventCache.set(obj.firm, obj);
		this.firmRiskUpdateEventSubscribers.forEach(cb => {
			cb("mod", obj);
		});
	}

	//	--------------------------------------------------------------------------------------------------
	//	INSTRUMENT RISK EVENTS
	//	STATUS: B)
	//	NOTE: Uses "MOD" not "ADD"
	//	--------------------------------------------------------------------------------------------------
	addInstrumentRiskUpdateEventSubscriber(cb) {
		console.debug("*** YAXX *** Adding a instrument risk event subscriber ");
		this.instrumentRiskUpdateEventSubscribers.push(cb);
		this.instrumentRiskUpdateEventCache.forEach((value, key, map) => {
			// Only send LAST update, not all events!
			cb("init", value);
		});
		cb("live");
	}

	removeInstrumentRiskUpdateEventSubscriber(cb) {
		this.removeFromArray("InstrumentRiskUpdateEventSubscriber", cb, this.instrumentRiskUpdateEventSubscribers);
	}

	distributeInstrumentRiskUpdateEvent(msg) {
		// Special: update Instrument database with Instrument Risk
		let { obj, action } = this.instrumentDb.updateInstrumentRisk(msg.instrumentId, msg);
		this.distributeInstrumentUpdate(action, obj);

		this.instrumentRiskUpdateEventCache.set(msg.firm, msg);
		this.instrumentRiskUpdateEventSubscribers.forEach(cb => {
			cb("mod", msg);
		});
	}

	//	--------------------------------------------------------------------------------------------------
	//	SUBSCRPTION EVENTS
	//	STATUS:
	//	NOTE:
	//	--------------------------------------------------------------------------------------------------
	addSubscriptionEventSubscriber(cb) {
		console.debug("*** YAXX *** Adding a subscription event subscriber ");
		this.subscriptionEventSubscribers.push(cb);
		this.subscriptionEvents.forEach(event => {
			cb("init", event);
		});
		cb("live");
	}
	removeSubscriptionEventSubscriber(cb) {
		this.removeFromArray("SubscriptionEventSubscriber", cb, this.subscriptionEventSubscribers);
	}

	distributeSubscriptionState(obj) {
		this.subscriptionEvents.push(obj);
		this.subscriptionEventSubscribers.forEach(cb => {
			cb("add", obj);
		});
	}

	//	--------------------------------------------------------------------------------------------------
	//	UTILITIES
	//
	//
	//	--------------------------------------------------------------------------------------------------
	sendMessage(json_msg) {
		console.log("*** SEND *** " + JSON.stringify(json_msg));
		this.ws.send(JSON.stringify(json_msg));
	}

	sendLoginRequest(firm, trader, password, clientVersion, apiVersion) {
		this.firm = firm; // @TODO Remove when LoginReply contains this info!
		this.trader = trader;

		this.sendMessage({
			messageType: "LoginRequest",
			firm: firm,
			trader: trader,
			password: password,
			clientVersion: clientVersion,
			apiVersion: apiVersion
		});
	}

	replyCallbackUsingContext(obj) {
		if (obj.context) {
			let callback = this.reply_callbacks.get(obj.context);
			callback(obj);
			this.reply_callbacks.delete(obj.context);
		}
	}

	//	--------------------------------------------------------------------------------------------------
	//	OUTGOING TRANSACTIONS
	//
	//
	//	--------------------------------------------------------------------------------------------------

	sendInsertOrderRequest(order) {
		let { messageType, ...therest } = order; // copy everything except messageType
		this.sendMessage({
			messageType: "InsertOrderRequest",
			...therest
		});
	}

	// @TODO which fields identify an order?
	cancelOrder(instrumentId, clientOrderId) {
		this.sendMessage({
			messageType: "CancelOrderRequest",
			instrumentId: instrumentId,
			clientOrderId: clientOrderId
		});
	}

	/*
		cancel all OWN orders
	*/
	sendCancelAllMyOrders() {
		this.ownOrders.forEach(order => {
			if (order.state === "ACKNOWLEDGED" || order.state === "RESTATED") {
				this.sendMessage({
					messageType: "CancelOrderRequest",
					instrumentId: order.instrumentId,
					clientOrderId: order.clientOrderId
				});
			}
		});
	}

	sendCancelAllOrdersRequest(firm) {
		this.sendMessage({
			messageType: "CancelAllOrdersRequest"
		});
	}

	sendUpdateAccountRequest(action, firm, amount) {
		this.sendMessage({
			messageType: "UpdateAccountRequest",
			action: action,
			firm: firm,
			amount: amount
		});
	}

	sendMarketMessage(message) {
		this.sendMessage({
			messageType: "MarketAnnouncementRequest",
			text: message
		});
	}

	// @TODO test
	sendModifyOrderRequest(orderInfo) {
		this.sendMessage(orderInfo);
	}

	/* @TODO
	sendCreateIndexInstrumentRequest(props) {
		this.sendMessage({
			messageType: "CreateFutureInstrumentRequest",
			...props
		});
	}
	*/

	sendCreateFutureInstrumentRequest(props) {
		this.sendMessage({
			messageType: "CreateFutureInstrumentRequest",
			...props
		});
	}

	sendCommandRequest(action, /* optional */ engineType) {
		if (engineType === undefined) {
			this.sendMessage({
				messageType: "CommandRequest",
				action: action
			});
		} else {
			this.sendMessage({
				messageType: "CommandRequest",
				action: action,
				engineType: engineType
			});
		}
	}

	// @TODO HAndle QueryUserSettingsReply and keep cache! + Subscriptions
	sendQueryUserSettings(cb) {
		let context = uuid();
		this.reply_callbacks.set(context, cb);

		let msg = {
			messageType: "QueryUserSettingsRequest",
			context: context
		};

		this.sendMessage(msg);
	}

	/*	User settings
	 */
	sendCreateUserSettingsRequest(key, value) {
		this.sendMessage({
			messageType: "CreateUserSettingsRequest",
			key: value
		});
	}

	sendDeleteUserSettingsRequest(id) {
		this.sendMessage({
			messageType: "DeleteUserSettingsRequest",
			id: id
		});
	}

	getOwnOrder(clientOrderId) {
		let o = this.ownOrders.get(clientOrderId);
		if (!o) {
			console.error("Error: unknown client order id:" + clientOrderId);
		}
		return o;
	}

	//	--------------------------------------------------------------------------------------------------
	//	USER UTILITIES
	//
	//
	//	--------------------------------------------------------------------------------------------------
	sendCreateUserRequest(firm, username, password, usertype, enabled) {
		let msg = {
			messageType: "CreateUserRequest",
			firm: firm,
			username: username,
			password: password,
			type: usertype,
			enabled: enabled
		};
		this.sendMessage(msg);
	}

	sendModifyUserRequest(firm, username, usertype, enabled) {
		let msg = {
			messageType: "ModifyUserRequest",
			firm: firm,
			username: username,
			type: usertype,
			enabled: enabled
		};
		this.sendMessage(msg);
	}

	sendSetUserPasswordRequest(firm, username, password, cb) {
		let context = undefined;
		if (cb) {
			context = uuid();
			this.reply_callbacks.set(context, cb);
		}
		let msg = {
			messageType: "SetUserPasswordRequest",
			firm: firm,
			username: username,
			password: password,
			context: context
		};
		this.sendMessage(msg);
	}

	sendSetMyPasswordRequest(firm, username, password, cb) {
		let context = uuid();
		this.reply_callbacks.set(context, cb);
		let msg = {
			messageType: "SetUserPasswordRequest",
			firm: firm,
			username: username,
			password: password,
			context: context
		};
		this.sendMessage(msg);
	}

	sendUpdateRiskSettingsRequest(productCorrelation, timeCorrelation, volatility) {
		let msg = {
			messageType: "UpdateRiskSettingsRequest",
			productCorrelation: productCorrelation,
			timeCorrelation: timeCorrelation,
			volatility: volatility
		};
		this.sendMessage(msg);
	}

	sendCreateFirmRequest(firm, initialMargin, tradingFeePct, clearingFeePct) {
		let msg = {
			messageType: "CreateFirmRequest",
			name: firm,
			initialMargin: initialMargin,
			tradingFeePercentage: tradingFeePct,
			clearingFeePercentage: clearingFeePct
		};
		this.sendMessage(msg);
	}

	sendModifyFirmRequest(firm, initialMargin, tradingFeePct, clearingFeePct) {
		let msg = {
			messageType: "ModifyFirmRequest",
			name: firm,
			initialMargin: initialMargin,
			tradingFeePercentage: tradingFeePct,
			clearingFeePercentage: clearingFeePct
		};
		this.sendMessage(msg);
	}

	sendSetTradingStateRequest(tradingState) {
		let msg = {
			messageType: "SetTradingStateRequest",
			state: tradingState
		};
		this.sendMessage(msg);
	}

	queryOwnTradesHistory(instrumentId, cb) {
		let context = uuid();
		this.reply_callbacks.set(context, cb);

		let start = moment()
			.subtract(12, "months")
			.utc()
			.format();
		let stop = moment()
			.utc()
			.format();

		this.sendQueryEventsRequest(instrumentId, "PrivateTradeEvent", start, stop, context);
	}

	/*
		This should support 

		DAILY DATA (ie closingprices)
		INTRADAY DATA (by tick/minute/30-minute/hour)
	*/
	queryTradeStatistics(what, instrumentId, cb) {
		let context = uuid();
		this.reply_callbacks.set(context, cb);

		let start;
		// YAX API wants UTC strings
		if (what === "TODAY") {
			// implied "ALL"
			start = moment()
				.local()
				.startOf("day")
				.add(8, "hours")
				.utc()
				.format();
		} else {
			// implied "ALL"
			start = moment()
				.local()
				.subtract(1, "weeks")
				.utc()
				.format();
		}

		let stop = moment()
			.utc()
			.format();

		console.log("*** YAXR *** Requesting data from " + start + " to " + stop);

		this.sendQueryEventsRequest(instrumentId, "TradeStatisticsEvent", start, stop, context, "MINUTELY");
	}

	queryHourlyTradeStatistics(instrumentId, cb) {
		let context = uuid();
		this.reply_callbacks.set(context, cb);

		// let start = moment().utc().subtract(10, 'hours').format();
		let start = moment()
			.local()
			.startOf("day")
			.add(8, "hours")
			.subtract(2, "days")
			.utc()
			.format();
		let stop = moment()
			.utc()
			.format();

		console.log("Requesting data from " + start + " to " + stop);

		this.sendQueryEventsRequest(instrumentId, "TradeStatisticsEvent", start, stop, context, "MINUTELY");
	}

	sendQueryEventsRequest(instrumentId, evttype, fromTime, toTime, context, frequency = "ALL") {
		let msg = {
			messageType: "QueryEventsRequest",
			instrumentId: instrumentId,
			eventType: evttype,
			fromTime: fromTime,
			toTime: toTime,
			frequency: frequency,
			context: context
		};

		this.sendMessage(msg);
	}

	getAllFirmNames() {
		console.log("*** YAXX *** " + JSON.stringify(this.firms));
		return Array.from(this.firms, ([key, value]) => value);
	}

	getFirm(name) {
		return this.firms.get(name);
	}

	/*
		User Properties
	
	 */
	sendQueryUserPropertiesRequest(cb) {
		let context = uuid();
		this.reply_callbacks.set(context, cb);
		let msg = {
			messageType: "QueryUserPropertiesRequest",
			context: context
		};
		this.sendMessage(msg);
	}

	sendSetUserPropertyRequest(key, value, cb) {
		let context = uuid();
		this.reply_callbacks.set(context, cb);
		let msg = {
			messageType: "SetUserPropertyRequest",
			context: context,
			key: key,
			value: value
		};
		this.sendMessage(msg);
	}

	sendDeleteUserPropertyRequest(key, value, cb) {
		let context = uuid();
		this.reply_callbacks.set(context, cb);
		let msg = {
			messageType: "DeleteUserPropertyRequest",
			context: context,
			key: key
		};
		this.sendMessage(msg);
	}

	getUserPropertyAsString(key) {
		this.dumpUserProperties();

		console.log(
			"*** PROP *** Getting Property " +
				key +
				" which has value " +
				this.userProperties.get(key) +
				" of type " +
				typeof this.userProperties.get(key)
		);
		return this.userProperties.get(key);
	}
	getUserPropertyAsBoolean(key) {
		this.dumpUserProperties();

		console.log(
			"*** PROP *** Getting Boolean Property " +
				key +
				" which has value " +
				this.userProperties.get(key) +
				" of type " +
				typeof this.userProperties.get(key)
		);
		return this.userProperties.get(key) === "true" ? true : false;
	}

	setUserPropertyString(key, value) {
		console.log("*** PROP *** Setting Property " + key + " to " + value);
		this.sendSetUserPropertyRequest(key, value, obj => {
			console.log("*** PROP *** Property " + key + " was set to " + value + " of type " + typeof value);
			this.userProperties.set(key, value);

			this.dumpUserProperties();
		});
	}

	setUserPropertyBoolean(key, value) {
		console.log("*** PROP *** Setting Property " + key + " to " + value);
		let v = value ? "true" : "false";
		this.sendSetUserPropertyRequest(key, v, obj => {
			console.log("*** PROP *** Property " + key + " was set to " + v + " of type " + typeof v);
			this.userProperties.set(key, v);

			this.dumpUserProperties();
		});
	}

	dumpUserProperties() {
		this.userProperties.forEach((v, k) => {
			console.error("*** PROP ***" + k + " " + v + " (" + typeof v + ")");
		});
	}
}

export default YaxService;
