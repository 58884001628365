import React, { Component } from "react";
import { connect } from "react-redux";
import "./IndexPrice.scss";

/*	------------------------------------------------------------
 *	There is still a bug in this component
 *
 *	See the @TODO
 *
 *	------------------------------------------------------------
 */
class IndexPrice extends Component {
	constructor(props) {
		super(props);

		this.refreshCount = 0;
		this.state = {
			item: {},
			blink_class: ""
		};

		this.handleIndexPriceUpdated = this.handleIndexPriceUpdated.bind(this);
		this.timeoutHandler = undefined;
	}

	handleIndexPriceUpdated(action, item) {
		if (action === "init" || action === "add" || action === "mod") {
			// console.log("*** IXPR *** updated! " + item.instrumentId + " " + item.lastPrice);

			this.setState({
				item: item,
				blink_class: item.diff > 0 ? "blink-up" : "blink-dn"
			});

			/*
				@TODO Clean up this when component gets unMounted!
			*/
			this.refreshCount++;
			var refreshCountCopy = this.refreshCount;
			this.timeoutHandler = window.setTimeout(() => {
				if (refreshCountCopy === this.refreshCount) {
					this.setState({ blink_class: "" });
				}
			}, 1000); // Timeout should match blink animation time!
		}
	}

	render() {
		// console.log("*** IXPR *** render, blink-class is " + this.state.blink_class);

		let price = this.state.item.lastPrice ? this.state.item.lastPrice.toFixed(2) : "";
		let diff = this.state.item.diff ? this.state.item.diff.toFixed(2) : 0;
		let diffclass = this.state.item.diff > 0 ? "blue" : this.state.item.diff < 0 ? "red" : "";
		let diffPct = this.state.item.diffPct ? "(" + this.state.item.diffPct.toFixed(2) + "%)" : "";
		let dir = this.state.item.diff > 0 ? "▲" : this.state.item.diff < 0 ? "▼" : "";

		return (
			<div className="IndexPrice">
				<h5>Settlement Price</h5>
				<span className={"indexprice " + this.state.blink_class}>{price}</span>
				<span className={diffclass}>
					{" "}
					{dir} {diff} {diffPct}{" "}
				</span>
				<span className={"indexprice "}>{this.state.item.prevClose}</span>
			</div>
		);
	}

	componentDidMount() {
		if (this.props.selectedIndex !== undefined) {
			console.log("*** IXPR *** subscribing to! " + this.props.selectedIndex);
			this.props.yax.addInstrumentSubscriber(this.props.selectedIndex, this.handleIndexPriceUpdated);
		}
	}

	componentDidUpdate(previousProps, previousState) {
		if (this.props.selectedIndex !== previousProps.selectedIndex) {
			if (previousProps.selectedIndex) {
				console.log("*** IXPR *** unsubscribing to! " + previousProps.selectedIndex);
				this.props.yax.removeInstrumentSubscriber(previousProps.selectedIndex, this.handleIndexPriceUpdated);
			}

			console.log("*** IXPR *** subscribing to! " + this.props.selectedIndex);
			this.props.yax.addInstrumentSubscriber(this.props.selectedIndex, this.handleIndexPriceUpdated);
		}
	}

	componentWillUnmount() {
		console.log("*** IXPR *** componentWillUnmount, unsubscribing to! " + this.props.selectedIndex);
		if (this.props.selectedIndex) {
			this.props.yax.removeInstrumentSubscriber(this.props.selectedIndex, this.handleIndexPriceUpdated);
		}

		if (this.timeoutHandler) {
			window.clearTimeout(this.timeoutHandler);
		}
	}
}

const mapStateToProps = state => ({
	selectedIndex: state.selectedIndex
});
export default connect(mapStateToProps)(IndexPrice);
