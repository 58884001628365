import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import {
    ColumnSeries, HighchartsStockChart, Chart, withHighcharts, XAxis, YAxis, Title, LineSeries, OHLCSeries,
    Navigator, ScatterSeries, SplineSeries, RangeSelector, Tooltip
} from 'react-jsx-highstock';
import Highcharts from 'highcharts/highstock';
import { connect } from 'react-redux';

// https://stackoverflow.com/questions/47046067/typeerror-moment-tz-is-not-a-function
import moment from 'moment-timezone';
// import { Utils } from '@ag-grid-community/all-modules';
import Utils from "utils/utils";

window.moment = moment;

class GraphLastTrade extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            dbg: [],
            start: moment().startOf("day").add(8, "hour").valueOf(),
            stop: moment().valueOf()
        };

        this.handleTS = this.handleTS.bind(this);

        this.plotOptions = {
            tooltip: {
                enabled: true,
                formatter: function () {
                    return 'The value for <b>' + this.x +
                        '</b> is <b>' + this.y + '</b>';
                }
            },

            series: {
                marker: {
                    fillColor: 'purple',
                    enabled: true,
                    states: {
                        hover: {
                            fillColor: 'green',
                            radiusPlus: 1
                            // lineWidthPlus: 2
                        },
                        normal: {
                            fillColor: 'red',
                            radiusPlus: 3,
                            lineWidthPlus: 2
                        }
                    }
                },
                dashStyle: "Solid",
                marker: {
                    fillColor: 'yellow',
                    enabled: true,
                    symbol: "cross",
                    radiusPlus: 3,
                },
                dataLabels: {
                    enabled: true
                }
            }
        };
    }

    handleTS(events, obj) {
        const x = events.map(e => {
            return [moment(e.timestamp).local().valueOf(), e.lastPrice]   // valueOf == unix() * 1000.0
        });

        console.log("*** GLTR *** Plotting this data:");
        console.log(x);

        let key = 1;
        const xxx = events.map(e => {
            let d = new Date(e.timestamp);
            // return [d.getTime(), e.lastPrice]
            return <li key={key++}>{d.getTime()} - {moment(e.timestamp).local().unix() * 1000} - {e.timestamp} - {moment(e.timestamp).local().format('YYYY-MM-DD HH:mm:ss')} - {e.lastPrice} - {e.prevClosingPrice}</li>;
        });

        // min = 08:00:00
        // max = max of now() and 17:00:00
        this.setState({
            start: moment().startOf("day").add(8, "hour").valueOf(),
            stop: Math.max(moment().valueOf(), moment().startOf("day").add(17, "hour").valueOf()),
            data: x,
            dbg: xxx
        });
    }

    resetMinMax() {
        this.setState({
            start: moment().startOf("day").add(8, "hour").valueOf(),
            stop: Math.max(moment().valueOf(), moment().startOf("day").add(17, "hour").valueOf())
        });
    }
    getTimeStamp(input) {
        return moment(input).valueOf();
    }

    componentDidMount() {
        console.log("*** GLTR *** GraphLastTrade.componentDidMount()");
        if (this.props.selectedInstrument) {
            this.props.yax.queryTradeStatistics("TODAY", this.props.selectedInstrument, this.handleTS);
        }
    }

    componentDidUpdate(previousProps, previousState) {
        Utils.printDiff("*** GLTR ***", previousProps, this.props);
        if (previousProps.selectedInstrument !== this.props.selectedInstrument) {
            this.props.yax.queryTradeStatistics("TODAY", this.props.selectedInstrument, this.handleTS);
        }
    }

    formatTooltip(tooltip, x = this.x, y = this.y, series = this.series) {
        return `<b>${Utils.timeStampToStr(x, "HH:mm:ss")}</b><br/>${y}`;
    }

    render() {

        const oneWeek = 7 * 24 * 60 * 60 * 1000;
        const now = Date.now();
        const sixWeeksAgo = now - (10 * oneWeek);
        const fourWeeksAgo = now - (0 * oneWeek);


        // if (this.state.data.length == 0) {
        //     return <h5>No data yet</h5>;
        // }

        console.log("*** GLTR *** " + this.state.start + " " + this.state.stop);

        return (
            <>
                {/* <h1>Trade Statistics for {this.props.selectedInstrument}</h1> */}
                {/* tooltip= {{pointFormat: "UTC midnight = {point.x:%H:%M} local time"}} */}

                <HighchartsStockChart
                    plotOptions={this.plotOptions}
                    time={{ timezone: "Europe/Stockholm" }}
                    subtitle={{ text: this.props.selectedInstrument }} >

                    <Chart chart={{ backgroundColor: "#ff0000" }} height={this.props.height} zoomType="x" />

                    <Title>{this.state.selectedInstrument}</Title>

                    {/* <Legend>
                        <Legend.Title>Key</Legend.Title>
                    </Legend> */}

                    <Tooltip // positioner={tooltipPositioner}
                        borderWidth={1}
                        backgroundColor="none"
                        pointFormatter={this.formatTooltip}
                        headerFormat=""
                        shadow={false}
                        style={{ fontSize: '18px' }}
                        valueDecimals={2} />

                    <XAxis ordinal={false} min={this.state.start} max={this.state.stop} >
                        <XAxis.Title>Time</XAxis.Title>
                    </XAxis>

                    <YAxis height='60%'>
                        <YAxis.Title>Price</YAxis.Title>
                        <ScatterSeries lineWidth={5} id="price" name="Price" data={this.state.data}></ScatterSeries>
                    </YAxis>

                    <YAxis height='40%' top='60%'>
                        <YAxis.Title>Volume</YAxis.Title>
                        <ColumnSeries id="volume" name="Volume" data={this.state.data2} />
                    </YAxis>

                    <RangeSelector ordinal={false} selected={3} inputDateFormat="%H:%M:%S" inputEditDateFormat="%H:%M:%S">
                        <RangeSelector.Button count={1} type="minute">1m</RangeSelector.Button>
                        <RangeSelector.Button count={10} type="minute">10m</RangeSelector.Button>
                        <RangeSelector.Button count={1} type="hour">1h</RangeSelector.Button>
                        <RangeSelector.Button type="all">All</RangeSelector.Button>
                        <RangeSelector.Input boxBorderColor="#7cb5ec" />
                    </RangeSelector>
                    <Navigator ordinal={false} min={this.state.start} max={this.state.stop}>
                        <Navigator.Series seriesId="???" />
                    </Navigator>
                </HighchartsStockChart>
            </>
        );
    }
}

// export default OrderLine;
const mapStateToProps = (state) => ({
    selectedInstrument: state.selectedInstrument,
});

export default connect(mapStateToProps)(withHighcharts(GraphLastTrade, Highcharts));
