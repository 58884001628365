import React, { Component } from 'react';
import { Container, Card, CardImg, Cardbody, Cardtitle, Cardsubtitle, Cardtext, Badge, ButtonToolbar, Row, Col, Form, Button } from "react-bootstrap";

class MarketMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: ""
        };
    }

    onChangeMessage(evt) {
        this.setState({
            message: evt.target.value
        });
    }

    onSendMessage(evt) {
        if (this.state.message !== "") {
            this.props.yax.sendMarketMessage(this.state.message);
        }
        evt.preventDefault();
    }

    render() {
        return (

            <Container style={{ marginTop: "60px" }}>
                <Row>
                    <Col>
                        <Form className="SendMessage">

                            <Row>
                                <Form.Group as={Col} xs={8} controlId={"formMessage"}>
                                    <h5>Send Market Message</h5>
                                    {/* <Form.Label>message</Form.Label> */}
                                    <Form.Control type="text" name={"message"} onChange={this.onChangeMessage.bind(this)} />
                                </Form.Group>
                            </Row>
                            <Row>
                                {/* {this.button("Modify", 4, this.handleStartChangePassword.bind(this))} */}
                                <Form.Group as={Col} xs={4} controlId={"modifyButton"}>
                                    <Button variant="primary" type="submit" onClick={this.onSendMessage.bind(this)}>
                                        Send
                                 </Button>
                                </Form.Group>
                            </Row>

                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default MarketMessage;