// index.js
import React from 'react'
// import { render } from 'react-dom'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import App from './App'
import { Provider } from 'react-redux';
import MarketService from './services/MarketService';
import './root.scss';

const store = MarketService.STORE;

// optional cofiguration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

const Root = () => (
  <AlertProvider template={AlertTemplate} {...options}>
    <Provider store={store}>
      <App store={store} />
    </Provider>
  </AlertProvider>
)

// render(<Root />, document.getElementById('root'));
export default Root;