import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardImg, Cardbody, Cardtitle, Cardsubtitle, Cardtext, Badge, ButtonToolbar, Row, Col, Form, Button } from "react-bootstrap";

class EditFirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            clearingfeepct: 0.0,
            initialmargin: 0.0,
            tradingfeepct: 0.0,
            dirty: false
        };
    }

    componentDidMount() {
        let n = window.localStorage.getItem("edit-firm-name");
        let c = window.localStorage.getItem("edit-firm-clearingfeepct");
        let i = window.localStorage.getItem("edit-firm-initialmargin");
        let t = window.localStorage.getItem("edit-firm-tradingfeepct");

        this.setState({
            name: n,
            clearingfeepct: c,
            initialmargin: i,
            tradingfeepct: t
        });
    }

    onChangeFirmName(evt) {
        this.setState({
            ...this.state,
            dirty: true,
            name: evt.target.value
        });
    }

    onChangeClearingFeePct(evt) {
        this.setState({
            ...this.state,
            dirty: true,
            clearingfeepct: evt.target.value
        });
    }

    onChangeInitialMargin(evt) {
        this.setState({
            ...this.state,
            dirty: true,
            initialmargin: evt.target.value
        });
    }

    onChangeTradingFeePct(evt) {
        this.setState({
            ...this.state,
            dirty: true,
            tradingfeepct: evt.target.value
        });
    }

    onSave(evt) {
        this.props.yax.sendModifyFirmRequest(this.state.name, this.state.initialmargin, this.state.tradingfeepct, this.state.clearingfeepct);
        this.props.history.push("/firms");
    }

    onCancel(evt) {
        this.props.history.push("/firms")
    }


    render() {

        return (
            <Form className="demoForm">

                <div className="underlyingPart">

                    <Form.Row>

                        <Form.Group as={Col} controlId="formFirm">
                            <Form.Label>Firm</Form.Label>
                            <Form.Control value={this.state.name} readOnly onChange={this.onChangeFirmName.bind(this)} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formTradingPct">
                            <Form.Label>Trading fee %</Form.Label>
                            <Form.Control value={this.state.tradingfeepct} onChange={this.onChangeTradingFeePct.bind(this)} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formClearingPct">
                            <Form.Label>Clearing fee %</Form.Label>
                            <Form.Control value={this.state.clearingfeepct} onChange={this.onChangeClearingFeePct.bind(this)} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formInitialMargin">
                            <Form.Label>Initial Margin</Form.Label>
                            <Form.Control value={this.state.initialmargin} onChange={this.onChangeInitialMargin.bind(this)} />
                        </Form.Group>


                    </Form.Row>

                    <Form.Row>
                        {!this.state.dirty ?
                            <Form.Group as={Col} controlId="formUnderlyingButtons">
                                <Button variant="primary" onClick={this.onCancel.bind(this)} type="submit">Close</Button>
                            </Form.Group>
                            :
                            <>
                                <Form.Group as={Col} controlId="formUnderlyingButtons">
                                    <ButtonToolbar>
                                        <Button style={{ marginRight: "10px" }} variant="primary" onClick={this.onSave.bind(this)} type="submit">Save</Button>
                                        <Button variant="secondary" onClick={this.onCancel.bind(this)} type="submit">Cancel</Button>
                                    </ButtonToolbar>
                                </Form.Group>
                            </>}
                    </Form.Row>

                </div>

            </Form>
        );
    }
}


const mapStateToProps = state => ({
    selectedInstrument: state.selectedInstrument
});
export default connect(mapStateToProps)(withRouter(EditFirm));
