import React, { Component } from "react";

import { connect } from "react-redux";

/* CUSTOM */
import "./ProductPage1.css";
import MarketDepth from "components/MarketDepth";
import PublicTradesOneInstrument from "components/PublicTradesOneInstrument";
import GraphLastTrade from "components/GraphLastTrade";
import Graph from "components/Graph";
import ProductInfo from "components/ProductInfo";
import OwnPositionForInstrument from "components/OwnPositionForInstrument";

class ProductPage1 extends Component {
    // eslint-disable-next-line
	constructor(props) {
		super(props);
	}

	render() {
		return (
            <div className="ProductPage1">
                <div className="box lasttrades">
                    {/* <ProductInfo yax={this.props.yax} /> */}
                </div>
                <div className="box ownpositionforinstrument">
                    <OwnPositionForInstrument yax={this.props.yax} />
                </div>
                <div className="box marketdepth">
                    <MarketDepth yax={this.props.yax} />
                </div>
                <div className="box trades">
                    <PublicTradesOneInstrument yax={this.props.yax} />
                </div>
                <div className="box graph">
                    <GraphLastTrade height="500px" yax={this.props.yax} />
                </div>
                <div className="box productinfo">
                    <ProductInfo yax={this.props.yax} />
                </div>
            </div>
		);
	}
}

// export default OrderLine;
const mapStateToProps = (state) => ({
	selectedInstrument: state.selectedInstrument,
});
export default connect(mapStateToProps)(ProductPage1);
