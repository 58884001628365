import React, { Component } from 'react';
import { Container, Form, FormControl, Button } from 'react-bootstrap';
// import { FormErrors } from './FormErrors';
import './PlaceOrder.scss';
import { connect } from "react-redux";
import NumericInput from 'react-numeric-input';
// import { Utils } from '@ag-grid-community/all-modules';
import Utils from "utils/utils";

//import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const uuid = require("uuid/v4");

class PlaceOrder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			price: 0,
			quantity: 0,
			isBuy: false,
			clientOrderId: "",
			submit_text: "",
			timeInForce: "DAY",
			comment: "",
			visible_state: ""  // was default "hidden"
		}

		this.handleSubmitBuy = this.handleSubmitBuy.bind(this);
		this.handleSubmitSell = this.handleSubmitSell.bind(this);
	}

	handleSubmitBuy(event) {
		event.preventDefault();
		this.sendOrder(true);
	}

	handleSubmitSell(event) {
		event.preventDefault();
		this.sendOrder(false);
	}

	sendOrder(isBuy) {
		let o = {
			instrumentId: this.props.selectedInstrument,
			price: this.state.price,
			quantity: this.state.quantity,
			isBuy: isBuy,
			clientOrderId: this.generateNewClientorderId(),
			timeInForce: this.state.timeInForce,
			orderType: "LIMIT"
		};

		// NOTE: Booleans are stored as strings
		let validate = this.props.yax.getUserPropertyAsBoolean("validateOrders");
		if (validate) {
			let instr = this.props.yax.getInstrument(this.props.selectedInstrument);
			Utils.validateOrder(instr, isBuy, this.state.price, this.state.quantity,
				() => {
					this.props.yax.sendInsertOrderRequest(o);
				},
				() => {
					/* do nothing */
				});
		}
		else {
			this.props.yax.sendInsertOrderRequest(o);
		}
	}


	generateNewClientorderId() {
		return uuid();
	}

	errorClass(error) {
		// return (error.length === 0 ? '' : 'has-error');
		return "";
	}

	componentDidUpdate(previousProps, previousState, snapshot) {
		/*
			Component was updated
			If we detect a prop change, we update state from here!
			This will generate another componentDidupdate, but then we do nothing
		*/
		const newProps = this.props;
		let newState = {};
		let changed = false;

		if (previousProps.selectedInstrument !== newProps.selectedInstrument) {
			newState = {
				instrumentId: newProps.selectedInstrument,
			}
			changed = true;
		}
		if (previousProps.selectedMarketOrder !== newProps.selectedMarketOrder) {
			newState = { ...newState, price: newProps.selectedMarketOrder.price, quantity: newProps.selectedMarketOrder.qty }
			changed = true;
		}

		if (changed) {
			this.setState({ ...newState });
		}
	}

	render() {
		let instr = this.props.yax.getInstrument(this.props.selectedInstrument);
		let tickSize = instr ? instr.tickSize : 1; /* safeguard */

		return (
			<Container>
				<Form autocomplete="off" className="PlaceOrder">

					<div className="quantitylabel">Quantity</div>
					<Form.Group controlId="formQty" className={"quantity"}>
						<NumericInput className="form-control"
							name="quantity"
							step={1}
							min={0}
							precision={0}
							onChange={(valueAsNumber, valueAsString, elem) => {
								this.setState({
									quantity: valueAsNumber
								});
							}}
							value={this.state.quantity} />
					</Form.Group>

					<div className="pricelabel">Price</div>
					<Form.Group controlId="formPrice" className={"price"}>
						<NumericInput className="form-control"
							name="price"
							step={tickSize}
							min={0}
							precision={2}
							onChange={(valueAsNumber, valueAsString, elem) => {
								this.setState({
									price: valueAsNumber
								});
							}}
							value={this.state.price} />
					</Form.Group>

					<div className="validitylabel">Valid until</div>
					<Form.Group controlId="formValid" className={"validity"}>
						<Form.Control
							as="select"
							name="validity"
							value={this.state.timeInForce}
							onChange={(evt) => {
								this.setState({
									validity: evt.target.value
								});
							}}
							type="text"
						>
							<option value="DAY">Today (DAY)</option>
							<option value="GTC">Until Cancelled (GTC)</option>
							<option value="FAK">Fill and Kill (FAK)</option>
							<option value="FOK">Fill or Kill (FOK)</option>
						</Form.Control>
					</Form.Group>

					<div className="commentlabel">Comment</div>
					<Form.Group controlId="formComment" className={"comment"}>
						<FormControl
							type="text"
							name="comment"
							onChange={(evt) => {
								this.setState({
									comment: evt.target.value
								});
							}}
							value={this.state.comment} />
					</Form.Group>


					<div className="buysell">
						<Button className="btn buybutton" variant="primary" type="submit" onClick={this.handleSubmitBuy} >
							Buy
                        </Button>
						<Button className="btn sellbutton" variant="primary" type="submit" onClick={this.handleSubmitSell} >
							Sell
                        </Button>
					</div>

					{/* <div>
                            {this.state.priceValid ? "*" : "-"}
                            {this.state.quantityValid ? "*" : "-"}
                            {this.state.formValid ? "*" : "-"}
                    </div> */}
				</Form>
			</Container>
		);
	}
}

// export default OrderLine;
const mapStateToProps = (state) => ({
	selectedInstrument: state.selectedInstrument,
	selectedMarketOrder: state.selectedMarketOrder
});
export default connect(mapStateToProps)(PlaceOrder);
