
export class ButtonRenderer {
	init(params) {
        //console.log(params);
        this.button = document.createElement('button');
        this.button.innerHTML='Click me';
        this.button.style.cssText = 'font-size: 0.8em; height: 70%; line-height:70%;';
        this.button.className = "btn btn-success";
        this.button.onclick = (evt) => {
            console.log("CLICK: " + JSON.stringify(params.data));
        }
    }

    getGui() {
        return this.button; 
    }
};

export class LinkRenderer {
	init(params) {
        //console.log(params);
        this.link = document.createElement('a');
        this.link.innerHTML=params.label;
        this.link.style.cssText = 'font-size: 0.8em; height: 70%; line-height:70%;';
        this.link.href = "#"
        this.link.onclick = (evt) => {
            params.onclick(params.data);
        }
    }

    getGui() {
        return this.link;
    }
};
