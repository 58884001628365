import React, { Component } from 'react';
import Highcharts from 'highcharts/highstock';
import {
    ColumnSeries, HighchartsStockChart, Chart, withHighcharts, XAxis, YAxis, Title, LineSeries,  OHLCSeries, 
    /*Legend, AreaSplineSeries, SplineSeries, */Navigator, RangeSelector, Tooltip
} from 'react-jsx-highstock';
import { connect } from 'react-redux';
import moment from 'moment';

const uuid = require("uuid/v4");

// const plotOptions = {
//     series: {
//         pointStart: 2010
//     }
// };

class Graph extends Component {

    constructor(props) {
        super(props);

        const now = Date.now();
        this.data = [];

        this.state = {
            selectedInstrumentId: "",
            //   data1: Graph.createRandomData(100.0, now, 1e7, 500),
            //   data2: Graph.createRandomData2(now, 1e7, 500)
            data1: this.generate_ohlc1(),
            data2: this.generate_volumes(),
        };
    }

    // Round to decimals
    round(num) {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }

    generate_ohlc1() {
        let data=[];
        let start = moment().local().startOf('day').add(8,'hours');
        let stop = moment().local().startOf('day').add(8,'hours');
        let tick = start;
        let cur_cls = 120.0;
        for (let i = 0; i< 8 * 60; i++) {

            let r = Math.random(); 
            let opn, hgh, lw, cls;
            if (r < 0.28) {
                opn = cur_cls * 0.97; // 0.5% up on open
            }
            else if (r > 0.71) {
                opn = cur_cls * 1.04; // 0.5% down on open
            }
            else {
                opn = cur_cls; // no change
            }

            hgh = this.round(opn + (Math.random() * opn/10));
            lw  = this.round(opn - (Math.random() * opn/10));
            
            r = Math.random(); 
            cls = opn;  
            if (r < 0.28) {
                cls = this.round(opn * 0.99);
            }
            else if (r > 0.71) {
                cls = this.round(opn * 1.01);
            }

            cur_cls = cls;

            //console.log("*** GRPH *** data:" + moment(tick).format() + " " + opn + " " + hgh + " " + lw + " " + cls);
            data.push([tick.valueOf(), this.round(opn), hgh, lw, cls]);
            tick = start.add(1, 'minute');
        }     
        
        return data;
    }

    generate_volumes() {
        let data=[];
        let start = moment().local().startOf('day').add(8,'hours');
        let stop = moment().local().startOf('day').add(8,'hours');
        let tick = start;
        for (let i = 0; i< 8 * 60; i++) {
            data.push([tick.valueOf(), Math.random() * 1000.0]);
            tick = tick.add(1, 'minute');
        }        
        console.log("*** GRPH *** data:" + data);
        return data;
    }

    componentDidUpdate(previousProps, previousState) {
        if (this.props.selectedInstrument !== previousProps.selectedInstrument) {
            this.setState( {
                data1: this.generate_ohlc1(),
                data2: this.generate_volumes(),
            });
        }
    }

    render() {

        return (
        <div className="app">


            <HighchartsStockChart  time={{timezone: "Europe/Stockholm"}} subtitle= {{text:"SUBTITLE"}} >
                <Chart height={this.props.height} zoomType="x" />

                <Title>{this.state.selectedInstrument}</Title>

                {/* <Legend>
                    <Legend.Title>Key</Legend.Title>
                </Legend> */}

                <Tooltip />

                <XAxis type="datetime">
                    <XAxis.Title>Date/Time</XAxis.Title>
                </XAxis>

                <YAxis height='60%'>
                    <YAxis.Title>Price</YAxis.Title>
                    <OHLCSeries id="price" name="Price" data={this.state.data1} />
                    
                </YAxis>

                <YAxis  height='40%' top='60%'>
                    <YAxis.Title>Volume</YAxis.Title>
                    <ColumnSeries id="volume" name="Volume" data={this.state.data2} />
                </YAxis>

                <RangeSelector selected={0}>
                    <RangeSelector.Button count={2} type="hour">2h</RangeSelector.Button>
                    <RangeSelector.Button count={4} type="hour">4h</RangeSelector.Button>
                    <RangeSelector.Button count={1} type="day">1d</RangeSelector.Button>
                    <RangeSelector.Button type="all">All</RangeSelector.Button>
                    <RangeSelector.Input boxBorderColor="#7cb5ec" />
                </RangeSelector>

                <Navigator><Navigator.Series seriesId="profit"/></Navigator>
            </HighchartsStockChart>

        </div>
        )
    }
}

// export default OrderLine;
const mapStateToProps = (state) => ({
	selectedInstrument: state.selectedInstrument,
});
export default connect(mapStateToProps)(withHighcharts(Graph, Highcharts));
