import React, { Component } from 'react';
import './OrderLine.scss';
import { connect } from 'react-redux';
import NumericInput from 'react-numeric-input';
// import { Dropdown, Container, Row, Col, Form, Button, Control } from 'react-bootstrap';
import Flag from "react-flags";
import Utils from "utils/utils";

const uuid = require("uuid/v4");

class OrderLine extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tickSize: 1,
			price: 0,
			quantity: 0,
			isBuy: false,
			clientOrderId: "",
			submit_text: "",
			timeInForce: "DAY",
			visible_state: ""  // was default "hidden"
		}

		this.setPrice = this.setPrice.bind(this);
		this.setQuantity = this.setQuantity.bind(this);
		this.handleSubmitBuy = this.handleSubmitBuy.bind(this);
		this.handleSubmitSell = this.handleSubmitSell.bind(this);
		this.setTimeInForce = this.setTimeInForce.bind(this);
	}

	setPrice(evt) {
		if (evt >= 0) {
			this.setState({
				price: evt
			});
		}
	}

	setQuantity(evt) {
		if (evt >= 0) {
			this.setState({
				quantity: evt
			});
		}
	}

	setTimeInForce(evt) {
		console.log(evt);
		this.setState({
			timeInForce: evt.target.value
		},
			() => {
				console.log("timeinforce set");
			});
	}

	handleSubmitBuy(event) {
		event.preventDefault();
		this.sendOrder(true);
	}

	handleSubmitSell(event) {
		event.preventDefault();
		this.sendOrder(false);
	}

	sendOrder(isBuy) {
		let o = {
			instrumentId: this.props.selectedInstrument,
			price: this.state.price,
			quantity: this.state.quantity,
			isBuy: isBuy,
			clientOrderId: this.generateNewClientorderId(),
			timeInForce: this.state.timeInForce,
			orderType: "LIMIT"
		};

		// NOTE: Booleans are stored as strings
		let validate = this.props.yax.getUserPropertyAsBoolean("validateOrders");
		if (validate) {
			let instr = this.props.yax.getInstrument(this.props.selectedInstrument);
			Utils.validateOrder(instr, isBuy, this.state.price, this.state.quantity,
				() => {
					this.props.yax.sendInsertOrderRequest(o);
				},
				() => {
					/* do nothing */
				});
		}
		else {
			this.props.yax.sendInsertOrderRequest(o);
		}
	}

	generateNewClientorderId() {
		return uuid();
	}

	shouldComponentUpdate() {
		return true;
	}

	componentDidUpdate(previousProps, previousState, snapshot) {
		/*
			Component was updated
			If we detect a prop change, we update state from here!
			This will generate another componentDidupdate, but then we do nothing
		*/
		const newProps = this.props;
		let newState = {};
		let changed = false;

		if (previousProps.selectedInstrument !== newProps.selectedInstrument) {
			newState = {
				instrumentId: newProps.selectedInstrument,
				tickSize: newProps.yax.getInstrument(newProps.selectedInstrument).tickSize,
			}
			changed = true;
		}
		if (previousProps.selectedMarketOrder !== newProps.selectedMarketOrder) {
			newState = { ...newState, price: newProps.selectedMarketOrder.price, quantity: newProps.selectedMarketOrder.qty }
			changed = true;
		}

		if (changed) {
			this.setState({ ...newState });
		}
	}


	render() {

		return (
			<div className={"OrderLine " + this.state.visible_state}>
				{this.props.selectedInstrument !== undefined ?
					<form onSubmit={this.handleSubmit} className="form-inline instrument">

						<label className="instrument">
							<Flag title="myflag" name="SE" format="png" pngSize={32} shiny={true} basePath="/img/flags" alt="Sweden" />
							{this.props.selectedInstrument}
						</label>

						<label>Qty:</label>
						<NumericInput className="qtyInput"
							min={0}
							step={10}
							precision={0}
							onChange={this.setQuantity}
							value={this.state.quantity} />

						<label>Price:</label>
						<NumericInput className="priceInput"
							step={this.state.tickSize}
							min={0}
							precision={2}
							onChange={this.setPrice}
							value={this.state.price} />

						<label>TimeInForce:</label>
						<select value={this.state.timeInForce} onChange={this.setTimeInForce}>
							<option value="DAY">DAY</option>
							<option value="GTC">GTC</option>
							<option value="FAK">FAK</option>
							<option value="FOK">FOK</option>
						</select>

						<div className="buysell">
							<button className="btn buybutton" type="submit" onClick={this.handleSubmitBuy}>Buy</button>
							<button className="btn sellbutton" type="submit" onClick={this.handleSubmitSell}>Sell</button>
						</div>

					</form>
					:
					<form className="form-inline">
						<label>Quick entry area</label>
					</form>
				}

			</div>
		);
	}
}

// export default OrderLine;
const mapStateToProps = (state) => ({
	selectedInstrument: state.selectedInstrument,
	selectedMarketOrder: state.selectedMarketOrder
});
export default connect(mapStateToProps)(OrderLine);