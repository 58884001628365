import React, { Component } from 'react';
import Select from 'react-select';
import { connect } from "react-redux";

class InstrumentList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            instruments: []
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange(evt) {
        let instr = this.props.yax.getInstrument(evt.value);
		this.props.dispatch({
			type: "select_instrument",
			selectedInstrument: instr.instrumentId
		});
    }

    componentDidMount() {
        let data = this.props.yax.getAllInstruments();  // as array
        this.setState({
            instruments: data
        });
    }

    render() {

        let options = this.state.instruments.map(i => {
            return {
                label: i.instrumentId,
                value: i.instrumentId
            }
        });

        return (
            <Select name="list_box_name" size="10" options={options} onChange={this.onChange} />
        );
    }
}


const mapStateToProps = state => ({
    selectedInstrument: state.selectedInstrument
});
export default connect(mapStateToProps)(InstrumentList);
