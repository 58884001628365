import React, { Component } from "react";
import { connect } from "react-redux";
import { InputGroup, FormControl, Container, Row, Col, Form, Button, Control } from 'react-bootstrap';
// import { FormErrors } from './FormErrors';
import LabelWithOptions from 'components/LabelWithOptions';
import { withAlert } from 'react-alert'

import "./CreateProductPage.scss";
import CheckBox from "formcomponents/CheckBox";
import StyledCheckbox from "components/StyledCheckbox";
import InstrumentList from 'components/InstrumentList';


class CreateProductPage extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
        this.onChange = this.onChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    onChange(evt) {
        console.log(evt.target);
        this.setState({
            [evt.target.name]: evt.target.value
        },
            () => {
                console.log(this.state);
            });

    }

    entry(field, width) {
        return (
            <Form.Group as={Col} xs={width} controlId={"form" + field}>
                <Form.Label>{field}</Form.Label>
                <Form.Control type="text" name={field} value={this.state[field]} onChange={this.onChange} />
            </Form.Group>
        )
    }

    handleSave(evt) {
        evt.preventDefault();
        console.log(this.state);
        this.props.yax.sendCreateFutureInstrumentRequest(this.state, (msg) => {
            this.props.alert.show("Instrument created!");
        });
    }

    componentDidMount() {

        if (this.state.instrumentId !== this.props.selectedInstrument) {
            this.setTheState(this.props.selectedInstrument);
        }
    }
    componentDidUpdate() {
        if (this.state.instrumentId !== this.props.selectedInstrument) {
            this.setTheState(this.props.selectedInstrument);
        }
    }

    setTheState(i) {
        let instr = this.props.yax.getInstrument(i);
        this.setState({
            instrumentId: instr.instrumentId,
            name: instr.name,
            position: instr.position,
            publisher: instr.publisher,
            site: instr.site,
            tickSize: instr.tickSize,
            contractSize: instr.contractSize,
            country: instr.country,
            iabCodes: instr.iabCodes,
            expiryTime: instr.expiryTime
        });
    }

    render() {

        return (
            <div className="CreateProductPage">

                <div className="header">
                    {/* <StyledCheckbox label="Hejsan"/>
                    <StyledCheckbox label="Hejsan2"/>
                    <StyledCheckbox label="Hejsan3"/>
                    <LabelWithOptions label="Label" options={<StyledCheckbox label="WithLWO"/>}/> */}

                    <InstrumentList yax={this.props.yax} />
                </div>

                <div className="instrumentform">
                    <Container>
                        <Form autoComplete="off" className="CreateProduct">
                            <Row>
                                {this.entry("instrumentId", 3)}
                                {this.entry("name", 3)}
                                {this.entry("position", 3)}
                                {this.entry("publisher", 3)}
                                {this.entry("site", 3)}
                                {this.entry("tickSize", 3)}
                                {this.entry("contractSize", 3)}
                                {this.entry("country", 3)}
                                {this.entry("iabCodes", 3)}
                                {this.entry("expiryTime", 3)}
                            </Row>
                            <Row>
                                <Button variant="primary" type="submit" onClick={this.handleSave}>
                                    Save
                            </Button>
                            </Row>
                        </Form>
                    </Container>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    selectedInstrument: state.selectedInstrument
});

export default withAlert()(connect(mapStateToProps)(CreateProductPage));
