export const formatString = (field,str, decimals=2) => {
	// console.log("formatString " + field + " " + str);
	if (str === undefined || str === null || str === "") {
		return "";
	}
	var b = parseFloat(str);
	var s = b.toFixed(decimals);
	
	// console.log("returning " + s);
	return s;
};

export function percentFormatter(params) {
	if (params.value) {
		return formatString(params.colDef.field, params.value) + "%";
	}
	else {
		return "";
	}
}

export function numberFormatter(params) {
    return formatString(params.colDef.field, params.value);
}

export function numberFormatter2(number) {
    let result = Math.floor(number.value).toFixed(2);
    return result;
}


