/*	-------------------------------------------------------------------------------




	-------------------------------------------------------------------------------
*/
import React, { Component } from "react";

import { connect } from "react-redux";

/* AG-GRID COMMUNITY */
import { AgGridReact } from "@ag-grid-community/react";
// import { AllCommunityModules } from "@ag-grid-community/all-modules";

/* AG-GRID CELL RENDERERS */
import PriceBlinkRenderer from "renderers/PriceBlinkRenderer";
import LabelWithOptions from "./LabelWithOptions";
import StyledCheckbox from "./StyledCheckbox";

/* CUSTOM */
import "./OwnTrades.css";
import Utils from "utils/utils";
import { /* formatString, percentFormatter,*/ numberFormatter } from "./formatString.js";

/*	-------------------------------------------------------------------------------




	-------------------------------------------------------------------------------
*/
class OwnTrades extends Component {
	constructor(props) {
		super(props);
		this.state = {
			components: {
				priceBlinkRenderer: PriceBlinkRenderer
			},
			rowSelection: "multiple",
			rowData: [],
			buy: true,
			sell: true
		};

		this.rowCache = new Map();

		this.defaultColDef = {
			resizable: true,
			suppressSizeToFit: false,
			cellStyle: { "text-align": "right" },
			suppressMovable: true,
			suppressMenu: true
		};

		this.columnDefs = [
			{
				headerName: "Timestamp",
				field: "timestamp",
				headerTooltip: "Timestamp of last update",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				sort: "desc",
				valueGetter: param => {
					return Utils.timeStampToStr(param.data.timestamp);
				}
			},
			{
				headerName: "Instrument ID",
				headerTooltip: "Instrument ID",
				field: "instrumentId",
				cellStyle: { "text-align": "left" },
				sortable: true,
				sortingOrder: ["asc", "desc"]
			},
			{
				headerName: "Trade ID",
				headerTooltip: "Unique trade ID",
				field: "tradeId",
				sortable: true,
				sortingOrder: ["asc", "desc"]
			},
			{
				headerName: "Firm",
				headerTooltip: "Firm responsible for the trade",
				field: "firm",
				sortable: true,
				sortingOrder: ["asc", "desc"]
			},
			{
				headerName: "Trader",
				headerTooltip: "Trader who placed the order",
				field: "trader",
				sortable: true,
				sortingOrder: ["asc", "desc"]
			},
			{
				headerName: "BuySell",
				headerTooltip: "Buy or Sell transaction",
				field: "isBuy",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				valueGetter: param => {
					return param.data.isBuy ? "Buy" : "Sell";
				}
			},
			{
				headerName: "Qty",
				headerTooltip: "Trade quantity",
				field: "quantity",
				sortable: true,
				sortingOrder: ["asc", "desc"]
			},
			{
				headerName: "Price",
				headerTooltip: "Trade price",
				field: "price",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				valueFormatter: numberFormatter,
				cellRenderer: "priceBlinkRenderer"
			},
			{
				headerName: "Party",
				headerTooltip: "The counterparty of the trade",
				field: "counterpart",
				sortable: true,
				sortingOrder: ["asc", "desc"]
			}
		];
	}

	handleCheckSell(evt) {
		this.setState({ sell: evt.target.checked }, () => {
			let buySellFilterComponent = this.gridApi.getFilterInstance("isBuy");
			if (this.state.buy !== this.state.sell) {
				buySellFilterComponent.selectNothing();
				if (this.state.buy) {
					// This code will only show BUY
					buySellFilterComponent.selectValue("Buy");
				} else {
					// This code will only show BUY
					buySellFilterComponent.selectValue("Sell");
				}
				buySellFilterComponent.applyModel();
				this.gridApi.onFilterChanged();
			} else {
				// This code will only show BOTH
				buySellFilterComponent.selectNothing();
				buySellFilterComponent.selectValue("Buy");
				buySellFilterComponent.selectValue("Sell");
				buySellFilterComponent.applyModel();
				this.gridApi.onFilterChanged();
			}
		});
	}

	handleCheckBuy(evt) {
		this.setState({ buy: evt.target.checked }, () => {
			let buySellFilterComponent = this.gridApi.getFilterInstance("isBuy");
			if (this.state.buy !== this.state.sell) {
				buySellFilterComponent.selectNothing();
				if (this.state.buy) {
					// This code will only show BUY
					buySellFilterComponent.selectValue("Buy");
				} else {
					// This code will only show BUY
					buySellFilterComponent.selectValue("Sell");
				}
				buySellFilterComponent.applyModel();
				this.gridApi.onFilterChanged();
			} else {
				// This code will only show BOTH
				buySellFilterComponent.selectNothing();
				buySellFilterComponent.selectValue("Buy");
				buySellFilterComponent.selectValue("Sell");
				buySellFilterComponent.applyModel();
				this.gridApi.onFilterChanged();
			}
		});
	}

	render() {
		return (
			<div className="OwnTrades">
				<h5 className="ga-header">
					<LabelWithOptions
						label="Own Completed Trades"
						options={
							<span>
								<StyledCheckbox
									label="Buy"
									checked={this.state.buy}
									onChange={this.handleCheckBuy.bind(this)}
								/>
								<StyledCheckbox
									label="Sell"
									checked={this.state.sell}
									onChange={this.handleCheckSell.bind(this)}
								/>
							</span>
						}
					/>
				</h5>

				<div className={"ga-grid " + this.props.view_options.theme}>
					<AgGridReact
						autoSizePadding={6}
						domLayout="autoHeight"
						components={this.state.components}
						columnDefs={this.columnDefs}
						defaultColDef={this.defaultColDef}
						rowSelection={"single"}
						rowData={this.state.rowData}
						// rowMultiSelectWithClick = { true }
						// rowDeselection = { true }
						// suppressRowClickSelection = { false }
						onGridReady={this.onGridReady.bind(this)}
						// onCellClicked = { this.onCellClicked.bind(this) }
						getRowNodeId={data => {
							return data.tradeId;
						}}
					/>
				</div>
			</div>
		);
	}

	onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

		window.addEventListener("resize", () => this.fitColumns);
		this.fitColumns();

		this.props.yax.addOwnTradesSubscriber((action, obj) => {
			switch (action) {
				case "init":
					this.updateData(obj.tradeId, obj); //
					break;
				case "live":
					this.fitColumns();
					break;
				default:
				case "add":
					this.updateData(obj.tradeId, obj); //
					this.fitColumns();
					break;
			}
		});
	};

	updateData(key, obj, templateObj) {
		if (!this.gridApi) {
			console.error("GridApi is not defined yet!");
			return;
		}

		/*	RowCache is only used to find out whether updateRowData should use ADD or UPDATE
		 */
		if (!this.rowCache.get(obj.tradeId)) {
			let newData = { ...obj };
			console.debug("*** OWNT *** updateRowData:add:" + JSON.stringify(newData));
			this.gridApi.updateRowData({ add: [newData] });
			this.rowCache.set(obj.tradeId, newData);
		} else {
			let oldData = this.rowCache.get(obj.tradeId);
			let newData = { ...oldData, ...obj };
			console.debug("*** OWNT *** updateRowData:update:" + JSON.stringify(newData));
			this.gridApi.updateRowData({ update: [newData] });
			this.rowCache.set(obj.tradeId, newData);
		}
	}

	fitColumns() {
		let allColumnIds = [];
		this.gridColumnApi.getAllColumns().forEach(function(column) {
			allColumnIds.push(column.colId);
		});
		this.gridColumnApi.autoSizeColumns(allColumnIds);
	}
}

// export default MarketGrid;
// definition of DummyComponent here...
const mapStateToProps = state => ({
	view_options: state.view_options
});
export default connect(mapStateToProps)(OwnTrades);
