import React, { Component } from "react";

import { connect } from "react-redux";

/* AG-GRID COMMUNITY */
import { AgGridReact } from "@ag-grid-community/react";
// import {AllCommunityModules} from "@ag-grid-community/all-modules";

/* AG-GRID CELL RENDERERS */

import PriceBlinkRenderer from "renderers/PriceBlinkRenderer";
import { ButtonRenderer, LinkRenderer } from "renderers/Renderers";
import { /* formatString, percentFormatter,*/ numberFormatter } from "./formatString.js";

/* CUSTOM */
import "./OwnPositions.css";
import Utils from "utils/utils";

// const uuid = require("uuid/v4");

class OwnPositions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rowData: [],
			components: {
				priceBlinkRenderer: PriceBlinkRenderer,
				buttonRenderer: ButtonRenderer,
				linkRenderer: LinkRenderer
			}
		};
		this.rowCache = new Map();
		this.defaultColDef = {
			resizable: true,
			suppressMovable: true,
			suppressMenu: true
		};

		this.columnDefs = [
			{
				headerName: "Timestamp",
				headerTooltip: "Timestamp of last update",
				field: "timestamp",
				// sortable: true,
				// sortingOrder: ["asc", "desc"],
				valueGetter: param => {
					return Utils.timeStampToStr(param.data.timestamp);
				}
			},
			{
				headerName: "Firm",
				headerTooltip: "Firm",
				field: "firm",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				sort: "asc",
				comparator: (val1, val2, node1, node2, inverse) => {
					if (val1.toLowerCase() === val2.toLowerCase()) return 0;
					if (val1.toLowerCase() < val2.toLowerCase()) return -1;
					return 1;
				}
			},
			{
				headerName: "Instrument ID",
				headerTooltip: "Instrument ID",
				field: "instrumentId",
				sortable: true,
				sortingOrder: ["desc", "asc"]
			},
			{
				headerName: "Name",
				headerTooltip: "Instrument name",
				field: "instrument.name",
				sortable: true,
				sortingOrder: ["asc", "desc"]
			},
			{
				headerName: "Position",
				headerTooltip: "Net position",
				field: "position",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				cellStyle: { "text-align": "right" }
			},
			{
				headerName: "AvgPrice",
				headerTooltip: "Average price of net position",
				field: "averagePrice",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				valueFormatter: numberFormatter,
				cellStyle: { "text-align": "right" }
			},
			{
				headerName: "Last",
				headerTooltip: "Last traded price of this instrument",
				field: "lastPrice",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				valueFormatter: numberFormatter,
				cellStyle: { "text-align": "right" }
			},
			{
				headerName: "RealizedP&L",
				headerTooltip: "Realized Profit and Loss",
				field: "profit.realized",
				sortable: true,
				sortingOrder: ["asc", "desc"],

				cellStyle: { "text-align": "right" },
				//valueFormatter: numberFormatter,
				valueFormatter: param => {
					return param.data.profit.realized.toLocaleString(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					});
				}
			},
			{
				headerName: "UnrealizedP&L",
				headerTooltip: "Unrealized Profit and Loss",
				field: "profit.unrealized",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				cellStyle: { "text-align": "right" },
				// valueFormatter: numberFormatter,
				valueFormatter: param => {
					return param.data.profit.unrealized.toLocaleString(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					});
				}
			},
			{
				headerName: "AccumulatedP&L",
				headerTooltip: "Accumulated Profit and Loss",
				field: "profit.accumulated",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				cellStyle: { "text-align": "right" },
				// valueFormatter: numberFormatter,
				valueFormatter: param => {
					return param.data.profit.accumulated.toLocaleString(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					});
				}
			}
		];
	}

	render() {
		return (
			<div className={"OwnPositions"}>
				<h5 className="ga-header">{this.props.yax.isAdmin() ? "All Positions" : "Own Open Positions"}</h5>

				<div className={"ga-grid " + this.props.view_options.theme}>
					<AgGridReact
						autoSizePadding={6}
						domLayout="autoHeight"
						// debug={true}
						skipHeaderOnAutoSize={true}
						/*** Definition ***/
						defaultColDef={this.defaultColDef}
						columnDefs={this.columnDefs}
						rowData={this.state.rowData}
						/*** Events ***/
						onGridReady={this.onGridReady.bind(this)}
						onCellClicked={this.onCellClicked.bind(this)}
						/*** Identification ***/
						getRowNodeId={data => {
							return this.makeRowId(data);
						}}
						/*** Customization ***/
						components={this.state.components}
						context={{}}
						allowContextMenuWithControlKey={true}
						getContextMenuItems={this.getContextMenuItems.bind(this)}
					/>
				</div>
			</div>
		);
	}

	getContextMenuItems(params) {
		var result = [
			{
				name: "AutoSize",
				action: () => {
					this.fitColumns();
				},
				cssClasses: ["redFont", "bold"]
			}
		];
		return result;
	}

	makeRowId(data) {
		return data.firm + "/" + data.instrumentId;
	}

	updateData(obj) {
		if (!this.gridApi) {
			console.error("GridApi is not defined yet!");
			return;
		}
		if (!this.rowCache.get(this.makeRowId(obj))) {
			console.debug("*** OWNP *** adding " + this.makeRowId(obj));
			let oldData = this.rowCache.get(this.makeRowId(obj));
			let newData = { ...oldData, ...obj };
			this.gridApi.updateRowData({ add: [newData] });
			this.rowCache.set(this.makeRowId(obj), newData);
		} else {
			console.debug("*** OWNP *** updating " + this.makeRowId(obj));
			let oldData = this.rowCache.get(this.makeRowId(obj));
			let newData = { ...oldData, ...obj };
			this.gridApi.updateRowData({ update: [newData] });
			this.rowCache.set(this.makeRowId(obj), newData);
		}
	}

	onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		console.debug("*** OWNP *** onGridReady()");

		this.gridApi.sizeColumnsToFit();
		//window.addEventListener("resize", () => this.fitColumns);
		//this.fitColumns();

		this.props.yax.addPositionSubscriber((action, position) => {
			console.debug("*** OWNP *** " + JSON.stringify(position));
			if (action === "init") {
				this.updateData(position);
			} else if (action === "live") {
				this.fitColumns();
			} else if (action === "set") {
				this.updateData(position);
				this.fitColumns();
			}
		});
	};

	fitColumns() {
		let allColumnIds = [];
		this.gridColumnApi.getAllColumns().forEach(function(column) {
			allColumnIds.push(column.colId);
		});
		this.gridColumnApi.autoSizeColumns(allColumnIds);

		//this.gridApi.sizeColumnsToFit();
	}

	onCellClicked() {}
}

// export default MarketGrid;
// definition of DummyComponent here...
const mapStateToProps = state => ({
	view_options: state.view_options
});
export default connect(mapStateToProps)(OwnPositions);
