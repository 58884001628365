/*	-------------------------------------------------------------------------------




	-------------------------------------------------------------------------------
*/
import React, { Component } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import PriceBlinkRenderer from "renderers/PriceBlinkRenderer";
import { connect } from "react-redux";
import "./Firms.scss";
import { withRouter } from "react-router-dom";
import Utils from "utils/utils";
import ButtonRenderer2 from "renderers/ButtonRenderer2";

/*	-------------------------------------------------------------------------------




	-------------------------------------------------------------------------------
*/
class Firms extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modules: AllModules, // AllCommunityModules,
			rowData: [],
			components: {
				priceBlinkRenderer: PriceBlinkRenderer
			}
		};

		this.firms = [];
		this.handleFirmEventUpdate = this.handleFirmEventUpdate.bind(this);

		this.defaultColDef = {
			resizable: true,
			cellStyle: { "text-align": "right" },
			suppressMovable: true,
			suppressMenu: true
		};

		this.columnDefs = [
			{
				headerName: "Timestamp",
				headerTooltip: "Timestamp of last update",
				field: "timestamp",
				cellStyle: { "text-align": "left" },
				valueGetter: param => {
					return Utils.timeStampToStr(param.data.timestamp);
				}
			},
			// { headerName: "Seq", headerTooltip: "Sequence number", field: "sequenceNumber" },
			{
				headerName: "Firm",
				sortable: true,
				sort: "asc",
				sortingOrder: ["asc", "desc"],
				headerTooltip: "Firm",
				field: "name",
				cellStyle: { "text-align": "left" },
				comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
					return valueA.localeCompare(valueB);
				}
			},
			{
				headerName: "Trading Fee %",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				field: "tradingFeePercentage"
			},
			{
				headerName: "Clearing Fee %",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				field: "clearingFeePercentage"
			},
			{
				headerName: "Initial Risk Margin",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				field: "initialMargin"
			}
		];
	}

	render() {
		return (
			<div className="Firm">
				<div className="ga-header">
					<h5>Firms</h5>
					<a href="#" onClick={this.onNewFirm.bind(this)}>
						Create new firm
					</a>
				</div>

				<div className={"ga-grid " + this.props.view_options.theme}>
					<AgGridReact
						autoSizePadding={6}
						domLayout="autoHeight"
						/*** Definition ***/
						// modules={this.state.modules}
						defaultColDef={this.defaultColDef}
						columnDefs={this.columnDefs}
						rowData={this.state.rowData}
						/*** Events ***/
						onGridReady={this.onGridReady.bind(this)}
						// rowSelection={"multiple"}
						// rowDeselection={true}
						/*** Customization ***/
						// components={this.state.components}
						// context={{}}
						// onCellClicked={this.onGridCellClicked.bind(this)}
						onCellDoubleClicked={this.onGridCellDoubleClicked.bind(this)}
						getRowNodeId={data => {
							return data.name;
						}}
						frameworkComponents={{
							buttonRenderer2: ButtonRenderer2
						}}
					/>
				</div>

				<a>Doubleclick to edit</a>
			</div>
		);
	}

	autoSizeAll() {
		if (!this.gridColumnApi) {
			console.error("*** FIRM *** gridColumnApi not available yet!");
			return;
		}

		let allColumnIds = [];
		this.gridColumnApi.getAllColumns().forEach(function(column) {
			allColumnIds.push(column.colId);
		});
		this.gridColumnApi.autoSizeColumns(allColumnIds);

		// this.gridApi.sizeColumnsToFit();
	}

	onNewFirm(evt) {
		evt.preventDefault();
		this.props.history.push("/new-firm");
	}

	onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		console.log("*** FIRM *** onGridReady()");

		this.props.yax.addFirmEventSubscriber(this.handleFirmEventUpdate);
	};

	onGridCellDoubleClicked(params) {
		let firm = this.props.yax.getFirm(params.data.name);
		window.localStorage.setItem("edit-firm-name", firm.name);
		window.localStorage.setItem("edit-firm-clearingfeepct", firm.clearingFeePercentage);
		window.localStorage.setItem("edit-firm-initialmargin", firm.initialMargin);
		window.localStorage.setItem("edit-firm-tradingfeepct", firm.tradingFeePercentage);

		this.props.history.push("/edit-firm");
	}

	// onGridCellClicked(params) {
	// 	// if (params.column.colId === "edit") {

	// 	let firm = this.props.yax.getFirm(params.data.name);
	// 	window.localStorage.setItem("edit-firm-name", firm.name);
	// 	window.localStorage.setItem("edit-firm-clearingfeepct", firm.clearingFeePercentage);
	// 	window.localStorage.setItem("edit-firm-initialmargin", firm.initialMargin);
	// 	window.localStorage.setItem("edit-firm-tradingfeepct", firm.tradingFeePercentage);

	// 	this.props.history.push("/edit-firm");
	// 	// }
	// }

	handleFirmEventUpdate(action, obj) {
		if (action !== "live") {
			let firm = obj.name;
			if (this.firms.includes(firm)) {
				console.log("*** FIRM *** handleFirmEventUpdate( " + action + ") updating " + JSON.stringify(obj));
				this.gridApi.updateRowData({ update: [obj] });
			} else {
				console.log("*** FIRM *** handleFirmEventUpdate( " + action + ") adding " + JSON.stringify(obj));
				this.gridApi.updateRowData({ add: [obj] });
				this.firms.push(firm);
				this.autoSizeAll();
			}
		}
	}

	componentDidMount() {}

	componentDidUpdate(previousProps, previousState) {}

	componentWillUnmount() {
		this.props.yax.removeFirmEventSubscriber(this.handleFirmEventUpdate);
	}
}

const mapStateToProps = state => ({
	view_options: state.view_options
});
export default connect(mapStateToProps)(withRouter(Firms));
