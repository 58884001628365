/*
The Renderer:

interface ICellRendererComp {
    // Optional - Params for rendering. The same params that are passed to the cellRenderer function.
    init?(params: ICellRendererParams): void;
    
    // Mandatory - Return the DOM element of the component, this is what the grid puts into the cell
    getGui(): HTMLElement;
    
    // Optional - Gets called once by grid after rendering is finished - if your renderer needs to do any cleanup,
    // do it here
    destroy?(): void;
    
    // Mandatory - Get the cell to refresh. Return true if the refresh succeeded, otherwise return false.
    // If you return false, the grid will remove the component from the DOM and create
    // a new component in it's place with the new values.
    refresh(params: ICellRendererParams): boolean;
}

Params:

interface ICellRendererParams {
    value:              any, // value to be rendered
    valueFormatted:     any, // value to be rendered formatted
    getValue:           ()=> any, // convenience function to get most recent up to date value
    setValue:           (value: any) => void, // convenience to set the value
    formatValue:        (value: any) => any, // convenience to format a value using the columns formatter
    data: any,          // the rows data
    node: RowNode,      // row rows row node
    colDef: ColDef,     // the cells column definition
    column: Column,     // the cells column
    rowIndex: number,   // the current index of the row (this changes after filter and sort)
    api: GridApi,       // the grid API
    eGridCell:          HTMLElement, // the grid's cell, a DOM div element
    eParentOfValue:     HTMLElement, // the parent DOM item for the cell renderer, same as eGridCell unless using checkbox selection
    columnApi:          ColumnApi, // grid column API
    context:            any, // the grid's context
    refreshCell:        ()=>void // convenience function to refresh the cell
}
*/

import "./PriceBlinkRenderer.css";

// import _ from 'lodash';

// var ARROW_UP = '⭐';
// var ARROW_DOWN = '';
let CLASS_UPMOVE='blink-up';
let CLASS_DNMOVE='blink-dn';
let BLINK_DELAY=1200;

export default class PriceBlinkRenderer {
	init(params) {

        // console.log("PriceBlinkRenderer value:" + params.value + " formatted: " + params.valueFormatted);
        // console.log(params);

        // this.hideDeltaValue = this.hideDeltaValue.bind(this);
        this.ALWAYS_BLINK = true;

        this.refreshCount = 0;
        this.eGui = document.createElement('span');
        // params.eParentOfValue.classList
        // params.eParentOfValue.style.padding = '0px';
        // params.eParentOfValue.style.border = '1px solid black';
        // params.eParentOfValue.style.lineHeight = '14px';
        // params.eParentOfValue.style.height = '14px'; /* look at row height:  .ag-theme-fresh .ag-row */

        this.eDelta = document.createElement('span');
        this.eGui.appendChild(this.eDelta);

        this.eValue = document.createElement('span');
        this.eGui.appendChild(this.eValue);

        // set some default values
        let value = (params.valueFormatted ? params.valueFormatted : params.value);
        if (value === undefined || value === null) value = "";

        this.eValue.innerHTML = value;
        this.lastvalue = params.value;

        this.eDelta.style.textAlign = "right";
        this.eDelta.style.marginRight = "5px";
        
        this.eValue.style.marginRight = "2px";
	}

	getGui() {
		return this.eGui;
	}

    setTimerToRemoveClass(params) {
        // the refreshCount makes sure that if the value updates again while
        // the below timer is waiting, then the below timer will realise it
        // is not the most recent and will not try to remove the delta value.
        this.refreshCount++;
        var refreshCountCopy = this.refreshCount;
        window.setTimeout(() => {
            if (refreshCountCopy === this.refreshCount) {
                this.clearBg(params);
            }
        }, BLINK_DELAY);
    };

    /*
    showDelta(params, delta) {
        var absDelta = Math.abs(delta);
        var valueFormatted = params.formatValue(absDelta);
        
        var deltaUp = (delta >= 0);
        if (deltaUp) {
            this.eDelta.innerHTML = ARROW_UP + valueFormatted;
        }
        else {
            this.eDelta.innerHTML = ARROW_DOWN + valueFormatted;
        }
    };

    hideDeltaValue(params) {
        params.eParentOfValue.classList.remove(CLASS_UPMOVE);
        params.eParentOfValue.classList.remove(CLASS_DNMOVE);
    };
    */

    clearBg(params) {
        params.eParentOfValue.classList.remove(CLASS_UPMOVE);
        params.eParentOfValue.classList.remove(CLASS_DNMOVE);
    };

	// Implementation of ICellRendererParams.refresh
	refresh(params/*: ICellRendererParams */) {

        // console.log("refresh");
		/*	First test if the value has changed at all!
		*/
        var value = params.value;
        if (value === this.lastValue) {
            return;
        }

        // console.log("refresh value:" + params.value + " fmt:" + params.valueFormatted);
        value = (params.valueFormatted ? params.valueFormatted : value);
        if (value === null || value === undefined) {
            value = "";
        }

        /* If we have a formatted value, use it! */
        // let r = Math.random() > 0.6 ? '⭐' : '';
        // this.eValue.innerHTML = r + value;
        this.eValue.innerHTML = value;

        if (this.ALWAYS_BLINK || this.lastValue) {
            if (!this.lastValue || value >= this.lastValue) {
                params.eParentOfValue.classList.add(CLASS_UPMOVE);
            }
            else {
                params.eParentOfValue.classList.add(CLASS_DNMOVE);
            }
            /* value changed - set timer */
            this.setTimerToRemoveClass(params);
        }   

        this.lastValue = value;
		return true;
	}
}
