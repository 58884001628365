import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardImg, Cardbody, Cardtitle, Cardsubtitle, Cardtext, Badge, ButtonToolbar, Row, Col, Form, Button } from "react-bootstrap";

class NewUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                firm: "",
                username: "",
                password: "",
                usertype: "TRADER",
                enabled: false,
                loggedIn: false,
                sessionId: ""
            },
            dirty: false
        };

        this.firmNames = null;
        this.firmNamesAsOptions = null;
    }

    componentDidMount() {

        let firmNames = this.props.yax.getAllFirmNames();
        this.setState({
            user: {
                ...this.state.user,
                firm: firmNames[0].name
            }
        });
    }

    onSetFirm(evt) {
        if (this.state.user.firm !== evt.target.value) {
            this.setState({
                dirty: true,
                user: {
                    ...this.state.user,
                    firm: evt.target.value
                }
            });
        }
    }
    onChangeUserName(evt) {
        if (this.state.user.username !== evt.target.value) {
            this.setState({
                dirty: true,
                user: {
                    ...this.state.user,
                    username: evt.target.value
                }
            });
        }
    }
    onChangePassword(evt) {
        this.setState({
            dirty: true,
            user: {
                ...this.state.user,
                password: evt.target.value
            }
        });
    }

    onSetUserType(evt) {
        if (this.state.user.usertype !== evt.target.value) {
            this.setState({
                dirty: true,
                user: {
                    ...this.state.user,
                    usertype: evt.target.value
                }
            });
        }
    }

    onEnable(evt) {
        this.setState({
            dirty: true,
            user: {
                ...this.state.user,
                enabled: evt.target.checked
            }
        });
    }

    onSave(evt) {
        this.props.yax.sendCreateUserRequest(this.state.user.firm, this.state.user.username, this.state.user.password, this.state.user.usertype, this.state.user.enabled);
        this.props.history.push("/users");
    }

    onCancel(evt) {
        this.props.history.push("/users")
    }


    render() {

        let firmNames = this.props.yax.getAllFirmNames();

        firmNames = firmNames.map(item => {
            return item.name;
        });

        firmNames.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase));

        console.log("*** NEWU *** " + JSON.stringify(firmNames));

        let firmNamesAsOptions = firmNames.map(item => {
            return <option key={item} value={item}>{item}</option>
        });

        return (
            <Form className="demoForm">

                <div className="underlyingPart">

                    <Form.Row>

                        {/* these 2 are readonly */}

                        <Form.Group as={Col} controlId="formFirm">
                            <Form.Label>Firm</Form.Label>
                            <Form.Control as="select" defaultValue={this.state.user.firm} value={this.state.user.firm} onChange={this.onSetFirm.bind(this)}>
                                {firmNamesAsOptions}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formUsername">
                            <Form.Label>User Name</Form.Label>
                            <Form.Control value={this.state.user.username} onChange={this.onChangeUserName.bind(this)} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control value={this.state.user.password} onChange={this.onChangePassword.bind(this)} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formUserType">
                            <Form.Label>User Type</Form.Label>
                            <Form.Control as="select" selected={this.state.user.usertype} value={this.state.user.usertype} onChange={this.onSetUserType.bind(this)}>
                                <option key={1} value={"TRADER"}>Trader</option>
                                <option key={2} value={"ADMIN"}>Admin</option>
                            </Form.Control>
                        </Form.Group>

                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formEnabled">
                            <Form.Check type="checkbox" label="Enabled" checked={this.state.user.enabled} onChange={this.onEnable.bind(this)} />
                        </Form.Group>

                    </Form.Row>


                    <Form.Row>
                        {!this.state.dirty ?
                            <Form.Group as={Col} controlId="formUnderlyingButtons">
                                <Button variant="primary" onClick={this.onCancel.bind(this)} type="submit">Close</Button>
                            </Form.Group>
                            :
                            <>
                                <Form.Group as={Col} controlId="formUnderlyingButtons">
                                    <ButtonToolbar>
                                        <Button style={{ marginRight: "10px" }} variant="primary" onClick={this.onSave.bind(this)} type="submit">Save</Button>
                                        <Button variant="secondary" onClick={this.onCancel.bind(this)} type="submit">Cancel</Button>
                                    </ButtonToolbar>
                                </Form.Group>
                            </>}
                    </Form.Row>

                </div>

            </Form>
        );
    }
}


const mapStateToProps = state => ({
    selectedInstrument: state.selectedInstrument
});
export default connect(mapStateToProps)(withRouter(NewUser));
