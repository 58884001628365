import React, { Component } from "react";
import { connect } from "react-redux";

/* CUSTOM */
import "./ProductPage2.css";
// import MarketLine from "components/MarketLine";

import PlaceOrder from "components/PlaceOrder";
import MarketDepth from "components/MarketDepth";
import PublicTradesOneInstrument from "components/PublicTradesOneInstrument";
import OwnPositionForInstrument from "components/OwnPositionForInstrument";
import OwnOrders from "components/OwnOrders";

import ProductInfo from "components/ProductInfo";

class ProductPage2 extends Component {
	// eslint-disable-next-line
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="ProductPage2">
                <div className="box ownpositionforinstrument">
                    <OwnPositionForInstrument yax={this.props.yax} />
                </div>
				<div className="box marketdepth">
					<MarketDepth yax={this.props.yax} />
				</div>
				<div className="box placeorder">
					<PlaceOrder yax={this.props.yax} />
				</div>
				<div className="box ownorders">
					<OwnOrders yax={this.props.yax} />
				</div>
				<div className="box productinfo">
					<ProductInfo yax={this.props.yax} />
				</div>
				<div className="box lasttrades">
					<PublicTradesOneInstrument yax={this.props.yax} />
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	selectedInstrument: state.selectedInstrument,
});
export default connect(mapStateToProps)(ProductPage2);
