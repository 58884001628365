import React, { Component } from "react";
import { connect } from "react-redux";
import OwnOrders from 'components/OwnOrders';
import OwnTrades from 'components/OwnTrades';
import Account from 'components/Account';

import "./AccountPage.scss";
import OwnPositions from "components/OwnPositions";
import FirmRiskEvents from "components/FirmRiskEvents";
import RiskSettings from "components/RiskSettings";

class AccountPage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {

        return (
            <div className="AccountPage">

                <div className="header"></div>

                <div className="account">
                    <hr></hr>
                    <Account yax={this.props.yax} />
                </div>

                {this.props.yax.isAdmin() ?

                    <div className="risksettings">
                        <hr></hr>
                        <RiskSettings yax={this.props.yax} />
                    </div> : <></>
                }


                <div className="firmriskevents">
                    <hr></hr>
                    <FirmRiskEvents yax={this.props.yax} />
                </div>

                <div className="positions">
                    <hr></hr>
                    <OwnPositions yax={this.props.yax} />
                </div>

                {/* Skip these views if you are ADMIN! */}
                {this.props.yax.isAdmin() ? <></> :
                    <>
                        <hr></hr>
                        <div className="orders">
                            <OwnOrders yax={this.props.yax} />
                        </div>
                        <hr></hr>
                        <div className="trades">
                            <OwnTrades yax={this.props.yax} />
                        </div>
                    </>}

            </div>
        );
    }
}

const mapStateToProps = state => ({
    selectedInstrument: state.selectedInstrument
});
export default connect(mapStateToProps)(AccountPage);
