import React, { Component } from "react";
import { connect } from "react-redux";
import "./AccountStatus.scss";

/*	------------------------------------------------------------
 *	There is still a bug in this component
 *
 *	See the @TODO
 *
 *	------------------------------------------------------------
 */
class AccountStatus extends Component {
	constructor(props) {
		super(props);

		this.refreshCount = 0;
		this.state = {
			item: {},
			blink_class: ""
		};
		this.balance = 0;

		this.handleAccountStatusUpdated = this.handleAccountStatusUpdated.bind(this);
	}

	handleAccountStatusUpdated(action, item) {
		if (action === "init" || action === "add" || action === "mod") {
			let diff = item.balance - this.balance;
			this.balance = item.balance;

			this.setState({
				item: item,
				blink_class: diff > 0 ? "blink-up" : "blink-dn"
			});

			/*
				@TODO Clean up this when component gets unMounted!
			*/
			this.refreshCount++;
			var refreshCountCopy = this.refreshCount;
			window.setTimeout(() => {
				if (refreshCountCopy === this.refreshCount) {
					this.setState({ blink_class: "" });
				}
			}, 1000); // Timeout should match blink animation time!
		}
	}

	render() {
		// console.log("*** ACCS *** render, blink-class is " + this.state.blink_class);
		let amt = this.state.item?.balance ? this.state.item?.balance : "";

		var formatter = new Intl.NumberFormat("sv-SE", {
			style: "currency",
			currency: "SEK"
		});

		let amts = formatter.format(amt);

		// let diff    = this.state.item.diff    ? this.state.item.diff.toFixed(2) : 0;
		// let diffclass = this.state.item.diff > 0 ? "blue" : this.state.item.diff < 0 ? "red" : "";
		// let diffPct = this.state.item.diffPct ? "(" + this.state.item.diffPct.toFixed(2) + "%)" : "";
		// let dir = this.state.item.diff > 0 ? "▲" : this.state.item.diff < 0 ? "▼" : "";
		return (
			<div className="AccountStatus">
				<h5>Account Balance</h5>
				{/* <span className={"indexprice " + this.state.blink_class}>{this.state.item.lastPrice}</span>
				<span className={diffclass}> {dir} {diff} {diffPct} </span>
				<span className={"indexprice "}>{this.state.item.prevClose}</span> */}

				<span className={"amount " + this.state.blink_class}>{amts}</span>
			</div>
		);
	}

	componentDidMount() {
		if (this.props.selectedIndex !== undefined) {
			console.log("*** ACCS *** subscribing to! " + this.props.selectedIndex);
			this.props.yax.addAccountSubscriber(this.handleAccountStatusUpdated);
		}
	}

	componentDidUpdate(previousProps, previousState) {
		if (this.props.selectedIndex !== previousProps.selectedIndex) {
			if (previousProps.selectedIndex) {
				console.log("*** ACCS *** unsubscribing to! " + previousProps.selectedIndex);
				this.props.yax.removeAccountSubscriber(this.handleAccountStatusUpdated);
			}

			console.log("*** ACCS *** subscribing to! " + this.props.selectedIndex);
			this.props.yax.addAccountSubscriber(this.handleAccountStatusUpdated);
		}
	}

	componentWillUnmount() {
		console.log("*** ACCS *** componentWillUnmount, unsubscribing to! " + this.props.selectedIndex);
		this.props.yax.removeAccountSubscriber(this.handleAccountStatusUpdated);
	}
}

const mapStateToProps = state => ({
	selectedIndex: state.selectedIndex
});
export default connect(mapStateToProps)(AccountStatus);
