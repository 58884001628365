import React, { Component } from 'react';

class Mockup extends Component {

	render() {
		return <div style={this.props.styles} className={this.props.cls} >
			<div className="centered">This is a placeholder for the {this.props.cls} window and is muted for now</div>
		</div>;
	}
}

export default Mockup;
