import React, { Component } from "react";
import { connect } from "react-redux";

/* CUSTOM */
import "./ProductPage.css";
import MarketLine from "components/MarketLine";

import ProductPage1 from "./ProductPage1";
import ProductPage2 from "./ProductPage2";

class ProductPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: "overview"
		};
	}

	componentDidMount(prevProps) {
	}

	handlePageSwitch(where) {
		this.setState({page: where});
	}

	render() {
		return (
			<div className="ProductPage">
				<div className="box marketline">
					<MarketLine yax={this.props.yax} page={this.state.page} productpagecb={this.handlePageSwitch.bind(this)}/>
				</div>

				{ this.state.page === "overview" && 
					<ProductPage1 yax={this.props.yax}/>
				}

				{ this.state.page === "buysell" && 
					<ProductPage2 yax={this.props.yax}/>
				}

			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	selectedInstrument: state.selectedInstrument,
});
export default connect(mapStateToProps)(ProductPage);
