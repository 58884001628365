import React, { Component } from 'react';
import Select from 'react-select'
import Utils from 'utils/utils';

/*
    There is still a bug in this component.

    When it is instantiated BEFORE all instruments have arrived, it will NOT be updated
    when they arrive one by one. Also, if there is none that have arrived when 
    componentDidMount() is called, the selectIndex event won't be emitted!
*/
class PickIndex extends Component {

    constructor(props) {
        super(props);
        this.state = {
            indexList: [],
            selectedIndexName: "",
            selectedIndex: ""
        };

        // temp
        this.list = [];

        this.handleChange = this.handleChange.bind(this);
        this.instrumentHandler = this.instrumentHandler.bind(this);
    }

    handleChange(selectedOption) {
        console.log("*** PIIX *** handleChange:" + selectedOption.value + " name: " + selectedOption.label);

        this.props.select(
            selectedOption.value,
            selectedOption.label);

        this.setState({
            selectedIndex: selectedOption.value,
            selectedIndexName: selectedOption.label
        });
    }

    instrumentHandler(action, obj) {
        switch (action) {
            case "init":
                if (obj.instrumentType === "INDEX") {
                    this.list.push(obj);
                }
                break;
            // case "add":
            //     if (obj.instrumentType === "INDEX") {
            //         this.setState({ indexList: { ...this.state.indexList, obj } });
            //         this.list.push(obj);
            //     }
            //     break;
            case "live":
                if (this.list.length > 0) {
                    this.props.select(
                        this.list[0].instrumentId,
                        this.list[0].name);
                }
                this.setState({
                    indexList: this.list,
                    selectedIndex: this.list[0].instrumentId,
                    selectedIndexName: this.list[0].name
                });
                break;

            default:
                // console.warn("*** PIIX *** unhandled object action:" + action + " on " + JSON.stringify(obj)) ;
                break;
        }
    }

    componentDidMount() {
        console.log("*** PIIX *** componentDidMount");
        this.props.yax.addInstrumentSubscriber("*", this.instrumentHandler);
    }

    componentWillUnmount() {
        console.log("*** PIIX *** componentWillUnmount - removing instrument subscription");
        this.props.yax.removeInstrumentSubscriber("*", this.instrumentHandler);
    }

    componentDidUpdate(previousProps, previousState) {
        console.log("*** PIIX *** componentDidUpdate");
        Utils.printDiff("*** PIIX *** props", previousProps, this.props);
        Utils.printDiff("*** PIIX *** state", previousState, this.state);

        // Disclaimer: Switch from false to true happens only once in the application lifetime

        if (this.props.yax_instruments_loaded !== previousProps.yax_instruments_loaded) {
            this.props.yax.removeInstrumentSubscriber("*", this.instrumentHandler);
            this.props.yax.addInstrumentSubscriber("*", this.instrumentHandler);
        }
    }

    render() {
        const options = this.state.indexList.map((x) => {
            return {
                value: x.instrumentId,
                label: x.name
            }
        });

        if (options.length === 0) {
            console.log("*** PIIX *** render() Options list empty, nothing to display!!");
        }
        else {
            console.log("*** PIIX *** render(), selected index:" + this.state.selectedIndex + " name: " + this.state.selectedIndexName);
        }

        return (
            <>
                <Select className={this.props.className}
                    options={options.sort((a, b) => a.value - b.value)}
                    value={{ value: this.state.selectedIndex, label: this.state.selectedIndexName }}
                    onChange={this.handleChange} />
            </>
        );
    }
}

export default PickIndex;