import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import "./Login.scss";
//import logotype from "./Logotype";
import logotype from "images/localhost.png";
import { connect } from "react-redux";

class RestrictedInput extends Component {
	constructor(props) {
		super(props);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	onChange(e) {
		// let { onChange } = this.props;
		let value = e.target.value;

		if (value.search(this.props.illegal) === -1) {
			this.props.onChange(e, value);
		} else {
			// Yes, this is a hack. But it's where the magic happens.
			// When "rejecting" the change (by not propagating it further), React seems
			// to lose track of where the caret should be, causing the caret to jump to
			// the end of the input. We therefore reset the text selection to what it was
			// before (having cached it in the keyDown event, which fires prior to onChange).
			// We also temporarily hide the caret/selection via the color/textShadow trick
			// to prevent the user from seeing the caret jump to the end and then back.
			// This has the sideffect of causing the text to blink, but that's far less
			// jarring than a jumping caret, and one could even argue that it's a good thing
			// because it helps indicate to the user that their input was incorrect, as
			// opposed to just ignored.

			this.el.style.textShadow = "#000";
			this.el.style.color = "transparent";

			setTimeout(() => {
				this.el.selectionStart = this.cachedSelection.start;
				this.el.selectionEnd = this.cachedSelection.end;
				this.el.style.color = "";
				this.el.style.textShadow = "";
			});
		}
	}

	onKeyDown(e) {
		// Cache selection on keydown since it will have changed by the time onChange is called
		this.cachedSelection = {
			start: this.el.selectionStart,
			end: this.el.selectionEnd
		};

		if (this.props.onKeyDown) this.props.onKeyDown(e);
	}

	render() {
		let { onChange, onKeyDown, ...rest } = this.props;

		return (
			<input
				className="form-control"
				type="text"
				onChange={this.onChange}
				onKeyDown={this.onKeyDown}
				ref={el => (this.el = el)}
				{...rest}
			/>
		);
	}
}

const CharAndIntegerInput = props => <RestrictedInput illegal={/ $/g} {...props} />;

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			firm: "",
			username: "",
			password: "",
			connected: this.props.yax.isConnected(),
			authorized: false,
			form_completed: false,
			logoimage: logotype /* default */
		};

		// shorter
		this.handleChangeUsername = this.handleChangeUsername.bind(this);
		this.handleChangePassword = this.handleChangePassword.bind(this);
		this.handleChangeFirm = this.handleChangeFirm.bind(this);
		this.onConnectionChanged = this.onConnectionChanged.bind(this);
		this.onAuthenticationChanged = this.onAuthenticationChanged.bind(this);
	}

	onConnectionChanged(conn, txt) {
		console.log("Login.onConnectionChanged:" + txt + " connected:" + conn);
		this.setState({ connected: conn });
	}

	onAuthenticationChanged(auth, server_version, user_type) {
		console.log("Login.onAuthenticationChanged, authorized:" + auth);
		this.setState({ authorized: auth });
	}

	componentDidMount() {
		let firm = localStorage.getItem("firm");
		let username = localStorage.getItem("username");
		let password = localStorage.getItem("password");

		// Load an JPG image with a name that matches the hostname
		// let hostname = window.location.hostname;
		// import(`../images/${hostname}.png`).then(image => {
		// 	let obj = null;
		// 	console.dir(image);
		// 	obj = image.default;
		// 	this.setState({
		// 		logoimage: obj
		// 	});
		// });

		this.setState({
			firm: firm,
			username: username,
			password: password,
			form_completed: firm !== "" && username !== "" && password !== ""
		});

		this.props.yax.addConnectionSubscriber(this.onConnectionChanged);
		this.props.yax.addAuthenticationSubscriber(this.onAuthenticationChanged);
	}

	componentWillUnmount() {
		this.props.yax.removeConnectionSubscriber(this.onConnectionChanged);
		this.props.yax.removeAuthenticationSubscriber(this.onAuthenticationChanged);
	}

	handleChangeFirm(e) {
		if (e.target.value.match("^[a-zA-Z0-9]*$") != null) {
			this.setState({
				firm: e.target.value,
				form_completed: e.target.value !== "" && this.state.username !== "" && this.state.password !== ""
			});
			localStorage.setItem("firm", e.target.value);
		}
	}

	handleChangeUsername(e) {
		// console.log(e.target.value);
		if (e.target.value.match("^[a-zA-Z0-9]*$") != null) {
			this.setState({
				username: e.target.value,
				form_completed: e.target.value !== "" && this.state.firm !== "" && this.state.password !== ""
			});
			localStorage.setItem("username", e.target.value);
		}
	}

	handleChangePassword(e) {
		// console.log(e.target.value);
		if (e.target.value.match("^[a-zA-Z0-9()@]*$") != null) {
			this.setState({ password: e.target.value });
			localStorage.setItem("password", e.target.value);
		}
	}

	handleClick(event) {
		event.preventDefault();

		this.props.dispatch({
			type: "set_user",
			user: {
				firm: this.state.firm,
				username: this.state.username,
				password: this.state.password
			}
		});

		/* @TODO: if "remember me" filled in, save firm, username, password! */
		this.props.yax.sendLoginRequest(
			this.state.firm,
			this.state.username,
			this.state.password,
			global.appVersion,
			"0.0.0-0"
		);
	}

	render() {
		let helptext;

		// if (!this.state.form_completed) {
		// 	helptext = <h5>Please fill in form</h5>;
		// } else {
		// 	helptext = <h5>Now click the Login button</h5>;
		// }

		return (
			<div id="LoginPage">
				<div className="upper"></div>

				<div className="logo">
					<h1>
						<img src={this.state.logoimage} />
					</h1>
				</div>

				<div className="hostname">
					<h1>{window.location.hostname}</h1>
				</div>

				<div className="left"></div>
				<div className="right"></div>

				<div id="Login">
					<Form>
						<Form.Group controlId="formBasicFirm">
							<Form.Label>Firm</Form.Label>
							<CharAndIntegerInput
								value={this.state.firm}
								onChange={this.handleChangeFirm}
								placeholder="Enter firm"
							/>
						</Form.Group>

						<Form.Group controlId="formBasicUsername">
							<Form.Label>Username</Form.Label>
							<CharAndIntegerInput
								value={this.state.username}
								onChange={this.handleChangeUsername}
								placeholder="Enter username"
							/>
						</Form.Group>

						<Form.Group controlId="formBasicPassword">
							<Form.Label>Password</Form.Label>
							<CharAndIntegerInput
								type="password"
								value={this.state.password}
								onChange={this.handleChangePassword}
								placeholder="Enter password"
							/>
						</Form.Group>

						<Form.Group controlId="formBasicCheckbox">
							<Form.Check type="checkbox" label="Remember me" />
						</Form.Group>

						<Button
							disabled={!this.state.connected || !this.state.form_completed}
							variant="primary"
							type="submit"
							onClick={this.handleClick.bind(this)}>
							Login
						</Button>
					</Form>

					{helptext}
				</div>

				<div className="footer">
					<div>
						<small id="client_status">Client version: {global.appVersion}</small>
					</div>
					<div>
						<small id="backend_status">
							{"Backend " +
								process.env.REACT_APP_SERVERURL +
								" is " +
								(this.state.connected ? "Online" : "Offline")}
						</small>
					</div>
				</div>
			</div>
		);
	}
}

// export default OrderLine;
const mapStateToProps = state => ({
	user: state.user
});
export default connect(mapStateToProps)(Login);
