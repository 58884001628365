import React, { Component } from 'react';
import { Card, CardImg, Cardbody, Cardtitle, Cardsubtitle, Cardtext, Badge, ButtonToolbar, Row, Col, Form, Button } from "react-bootstrap";

class RiskSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dirty: false,
            productCorrelation: 0,
            timeCorrelation: 0,
            volatility: 0
        };
    }

    componentDidMount() {

        // @TODO: note, NOT realtime updated!
        let risk = this.props.yax.getRiskSettings();
        this.setState({
            productCorrelation: risk.productCorrelation,
            timeCorrelation: risk.timeCorrelation,
            volatility: risk.volatility
        });
    }

    onChangeProductCorrelation(evt) {
        this.setState({
            ...this.state,
            dirty: true,
            productCorrelation: evt.target.value
        });
    }
    onChangeTimeCorrelation(evt) {
        this.setState({
            ...this.state,
            dirty: true,
            timeCorrelation: evt.target.value
        });
    }
    onChangeVolatility(evt) {
        this.setState({
            ...this.state,
            dirty: true,
            volatility: evt.target.value
        });
    }

    onCancel(evt) {
        this.setState({
            ...this.state,
            dirty: false
        });
        evt.preventDefault();
    }
    onSave(evt) {

        this.props.yax.sendUpdateRiskSettingsRequest(this.state.productCorrelation, this.state.timeCorrelation, this.state.volatility)
        this.setState({
            ...this.state,
            dirty: false
        });
        evt.preventDefault();
    }

    render() {
        return (
            <Form className="riskSettings">

                <Form.Row>

                    <Form.Group as={Col} controlId="formProductCorrelation">
                        <Form.Label>Product Correlation</Form.Label>
                        <Form.Control value={this.state.productCorrelation} onChange={this.onChangeProductCorrelation.bind(this)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formTimeCorrelation">
                        <Form.Label>Time Correlation</Form.Label>
                        <Form.Control value={this.state.timeCorrelation} onChange={this.onChangeTimeCorrelation.bind(this)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formVolatility">
                        <Form.Label>Volatility</Form.Label>
                        <Form.Control value={this.state.volatility} onChange={this.onChangeVolatility.bind(this)} />
                    </Form.Group>

                </Form.Row>

                <Form.Row>
                    {!this.state.dirty ?
                        <></>
                        :
                        <>
                            <Form.Group as={Col} controlId="formButtonsSaveCance">
                                <ButtonToolbar>
                                    <Button style={{ marginRight: "10px" }} variant="primary" onClick={this.onSave.bind(this)} type="submit">Save</Button>
                                    <Button variant="secondary" onClick={this.onCancel.bind(this)} type="submit">Cancel</Button>
                                </ButtonToolbar>
                            </Form.Group>
                        </>}
                </Form.Row>

            </Form>

        );
    }
}

export default RiskSettings;