import React, { Component } from "react";
import PriceBlinkRenderer from "renderers/PriceBlinkRenderer";
import { ButtonRenderer, LinkRenderer } from "renderers/Renderers";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PlaceOrderModal from "./PlaceOrderModal";
import { Button } from "react-bootstrap";

import "./MarketGrid.css";
// import {MarketGridColumnDefs} from './MarketGridColumnDefs';
import { percentFormatter, numberFormatter } from "./formatString.js";

import Utils from "utils/utils";
import moment from "moment";
// import { clearTimeout } from "timers";

/*	------------------------------------------------------------
 *
 *
 *	------------------------------------------------------------
 */
class MarketGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			rowData: [],
			modules: AllModules,
			// these are passed on as properties to <Modal>
			showModal: false,
			showModalInstrumentId: "",
			showModalBuyOrSell: "???",
			showModalQty: 0,
			showModalPrice: 0,
			indexList: []
		};

		this.first_product_selected = false;
		this.dateComparator = this.dateComparator.bind(this);
		this.monthToComparableNumber = this.monthToComparableNumber.bind(this);

		/* AG-GRID Stuff */
		this.components = {
			/* custom cell renderer component */
			priceBlinkRenderer: PriceBlinkRenderer,
			buttonRenderer: ButtonRenderer,
			linkRenderer: LinkRenderer
		};
		this.defaultColDef = {
			resizable: true,
			suppressMovable: true,
			suppressMenu: true,
			cellStyle: { "text-align": "right" }
		};
		this.idstyle = {};

		/*
				AG GRID COLUMN DEFS 
		*/
		this.columnDefs = [
			{
				headerName: "",
				field: "Buy",
				minWidth: 1,
				maxWidth: 25,
				cellStyle: { "text-align": "left" },
				cellRenderer: "linkRenderer",
				cellRendererParams: {
					label: "Buy", // @TODO: Dynamic renderer should skip if INDEX
					onclick: evt => {
						//this.linkClicked(evt)
						this.setState({
							showModalInstrumentId: evt.instrumentId,
							showModalQty: evt.buy ? evt.sell.quantity : 0,
							showModalPrice: evt.buy ? evt.sell.price : 0,
							showModalBuyOrSell: "Buy",
							showModal: true
						});
					}
				}
			},
			{
				headerName: "",
				field: "Sell",
				minWidth: 1,
				maxWidth: 25,
				cellStyle: { "text-align": "left" },
				cellRenderer: "linkRenderer",
				cellRendererParams: {
					label: "Sell", // @TODO: Dynamic renderer should skip if INDEX
					onclick: evt => {
						// this.linkClicked(evt)
						this.setState({
							showModalInstrumentId: evt.instrumentId,
							showModalQty: evt.sell ? evt.buy.quantity : 0,
							showModalPrice: evt.sell ? evt.buy.price : 0,
							showModalBuyOrSell: "Sell",
							showModal: true
						});
					}
				}
			},
			{
				headerName: "LastTrade",
				headerTooltip: "This is the last day of trading",
				cellStyle: { "text-align": "left" },

				sort: "asc",
				sortable: true,
				sortingOrder: ["asc", "desc"],
				comparator: (value1, value2, node1, node2, inverse) => {
					// Note: value1 and 2 are undefined for some reason (because not using valueGetter?)
					if (node1.data.lastTradingDate === node2.data.lastTradingDate) return 0;
					return node1.data.lastTradingDate < node2.data.lastTradingDate ? -1 : 1;
				},
				valueFormatter: params => {
					return moment(params.data.lastTradingDate).format("DD-MMM-YY");
				}
			},
			{
				headerName: "LastDeliv",
				headerTooltip: "This is the last day of the delivery period",
				cellStyle: { "text-align": "left" },

				sortable: true,
				sortingOrder: ["asc", "desc"],
				comparator: (value1, value2, node1, node2, inverse) => {
					// Note: value1 and 2 are undefined for some reason (because not using valueGetter?)
					if (node1.data.lastSettlementDate === node2.data.lastSettlementDate) return 0;
					return node1.data.lastSettlementDate < node2.data.lastSettlementDate ? -1 : 1;
				},
				valueFormatter: params => {
					return moment(params.data.lastSettlementDate).format("DD-MMM-YY");
				}
			},
			{
				headerName: "Pos",
				headerTooltip: "Your net position",
				field: "myposition.position" /* , cellStyle: {color: 'red', 'background-color': 'green'} */,
				cellRenderer: "priceBlinkRenderer"
			},
			{
				headerName: "AvgPrice",
				headerTooltip: "Average price of position",
				field: "myposition.averagePrice" /* , cellStyle: {color: 'red', 'background-color': 'green'} */,
				valueFormatter: numberFormatter,
				cellRenderer: "priceBlinkRenderer"
			},
			{
				headerName: "Open",
				headerTooltip: "Opening Price",
				field: "openingPrice" /* , cellStyle: {color: 'red', 'background-color': 'green'} */,
				valueFormatter: numberFormatter,
				cellRenderer: "priceBlinkRenderer"
			},
			{
				headerName: "BidQty",
				headerTooltip: "Total Quantity of all best bids",
				field: "buy.quantity",
				cellRenderer: "priceBlinkRenderer",
				cellStyle: { "text-align": "right" }
			},
			{
				headerName: "Bid",
				headerTooltip: "Best bid price",
				field: "buy.price",
				valueFormatter: numberFormatter,
				cellRenderer: "priceBlinkRenderer",
				cellStyle: { "text-align": "right" }
			},
			{
				headerName: "Ask",
				headerTooltip: "Best ask price",
				field: "sell.price",
				cellRenderer: "priceBlinkRenderer",
				valueFormatter: numberFormatter,
				cellStyle: { "text-align": "right" }
			},
			{
				headerName: "AskQty",
				headerTooltip: "Total Quantity of all best asks/offers",
				field: "sell.quantity",
				cellRenderer: "priceBlinkRenderer",
				cellStyle: { "text-align": "right" }
			},
			{
				headerName: "Last",
				headerTooltip: "Price of last trade",
				field: "lastPrice",
				cellRenderer: "priceBlinkRenderer",
				valueFormatter: numberFormatter
			},
			{
				headerName: "LastQty",
				headerTooltip: "Quantity of last trade",
				field: "lastQuantity"
			},
			{
				headerName: "Diff",
				headerTooltip: "Diff against previous days' closing price",
				field: "diff",
				cellRenderer: "priceBlinkRenderer",
				valueFormatter: numberFormatter
			},
			{
				headerName: "Diff%",
				headerTooltip: "Diff against previous days' closing price in percent",
				field: "diffPct",
				cellRenderer: "priceBlinkRenderer",
				valueFormatter: percentFormatter
			},
			{
				headerName: "High",
				headerTooltip: "Highest paid price today",
				field: "highPrice",
				cellRenderer: "priceBlinkRenderer",
				valueFormatter: numberFormatter
			},
			{
				headerName: "Low",
				headerTooltip: "Lowest paid price today",
				field: "lowPrice",
				cellRenderer: "priceBlinkRenderer",
				valueFormatter: numberFormatter
			},
			{
				headerName: "PrevClose",
				headerTooltip: "Yesterdays closing price",
				field: "prevClosingPrice",
				cellRenderer: "priceBlinkRenderer",
				valueFormatter: numberFormatter
			},
			{
				headerName: "Vol",
				headerTooltip: "Total traded quantity today",
				cellRenderer: "priceBlinkRenderer",
				field: "totalQuantity"
			},
			// {
			// 	headerName: "Book",
			// 	headerTooltip: "risk.bookValue",
			// 	field: "risk.bookValue"
			// },
			{
				headerName: "P&L",
				headerTooltip: "risk.grossProfit",
				cellRenderer: "priceBlinkRenderer",
				field: "risk.grossProfit",
				valueFormatter: param => {
					// @TODO: Instrument DB should maybe initialize these fields?
					if (!param.data.risk || !param.data.risk.grossProfit) return "";
					return param.data.risk.grossProfit.toLocaleString(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					});
				}
			},
			{
				headerName: "Risk",
				headerTooltip: "risk.riskValue",
				cellRenderer: "priceBlinkRenderer",
				field: "risk.riskValue",
				valueFormatter: param => {
					// @TODO: Instrument DB should maybe initialize these fields?
					if (!param.data.risk || !param.data.risk.riskValue) return "";
					return param.data.risk.riskValue.toLocaleString(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					});
				}
			},
			{
				headerName: "Instrument ID",
				headerTooltip: "Instrument ID",
				cellStyle: { "text-align": "left" },
				sortable: true,
				sortingOrder: ["asc", "desc"],
				field: "instrumentId"
			}
		];
	}

	/*  --------------------------------------------------------------
      	REACT LIFECYCLE

      	--------------------------------------------------------------
   */
	componentDidMount() {}

	componentWillUnmount() {
		this.props.yax.removeInstrumentSubscriber("*", this.instrumentHandler);
	}

	/*	Component was updated and rendered.*/
	componentDidUpdate(previousProps, previousState) {
		// Utils.printDiff("*** GRID *** props diff ", previousProps, this.props);
		// Utils.printDiff("*** GRID *** state diff ", previousState, this.state);

		if (previousProps.selectedIndex !== this.props.selectedIndex) {
			this.switchToIndex(this.props.selectedIndex);
		}

		return true;
	}

	instrumentHandler = (action, instr) => {
		if (action === "init") {
			this.updateData(action, instr.instrumentId, instr); //
		} else if (action === "add") {
			this.updateData(action, instr.instrumentId, instr); //
		} else if (action === "live") {
			Utils.autoSizeAllColumnsInGrid(this.gridColumnApi); // @TODO: Add back later!
		} else if (action === "mod") {
			this.updateData(action, instr.instrumentId, instr); //
			Utils.autoSizeAllColumnsInGrid(this.gridColumnApi);
		}
	};

	/*  --------------------------------------------------------------

		--------------------------------------------------------------
	*/
	switchToIndex(indexInstrumentId) {
		if (!this.gridApi) {
			// special logic... we have to wait until gridApi is available!

			let interval_id = setInterval(() => {
				if (this.gridApi) {
					clearInterval(interval_id);
					// cancel old subscription
					this.props.yax.removeInstrumentSubscriber("*", this.instrumentHandler);
					// clear grid
					this.gridApi.setRowData([]);
					this.props.yax.addInstrumentSubscriber("*", this.instrumentHandler);
				}
			}, 1000);
		} else {
			// normal logic
			// cancel old subscription
			this.props.yax.removeInstrumentSubscriber("*", this.instrumentHandler);
			// clear grid
			this.gridApi.setRowData([]);
			this.props.yax.addInstrumentSubscriber("*", this.instrumentHandler);
		}
	}

	printSortStateToConsole() {
		var sortState = this.gridApi.getSortModel();
		if (sortState.length == 0) {
			console.debug("*** GRID *** No sort active");
		} else {
			console.debug("*** GRID *** State of sorting is:");
			for (var i = 0; i < sortState.length; i++) {
				var item = sortState[i];
				console.debug("*** GRID *** " + i + " = {colId: " + item.colId + ", sort: " + item.sort + "}");
			}
		}
	}

	/*  --------------------------------------------------------------

		--------------------------------------------------------------
	*/
	updateData(action, key, obj) {
		/*
			@TODO: THIS IS A HACK THAT SHOULD BE REMOVED!
		*/
		if (!this.first_product_selected && obj.instrumentType === "FUTURE") {
			this.selectInstrument(obj.instrumentId);
			this.first_product_selected = true;
		}

		if (!this.gridApi) {
			console.error("*** GRID *** GridApi is not defined yet!");
			return;
		}

		if (action === "add" || action === "init") {
			if (obj.indexInstrumentId && obj.indexInstrumentId === this.props.selectedIndex) {
				this.gridApi.updateRowData({ add: [obj] });
			} else {
				// console.log(
				// 	"*** GRID *** skipping " +
				// 		obj.instrumentId +
				// 		" since obj.indexInstrumentId " +
				// 		obj.indexInstrumentId +
				// 		" isnt = " +
				// 		this.props.selectedIndex
				// );
			}
		} else if (action === "mod") {
			if (obj.indexInstrumentId && obj.indexInstrumentId === this.props.selectedIndex) {
				this.gridApi.updateRowData({ update: [obj] });
			} else {
				// console.log(
				// 	"*** GRID *** skipping " +
				// 		obj.instrumentId +
				// 		" since obj.indexInstrumentId " +
				// 		obj.indexInstrumentId +
				// 		" isnt = " +
				// 		this.props.selectedIndex
				// );
			}
		} else console.error("*** GRID *** Invalid action:" + action);
	}

	/*  --------------------------------------------------------------
		                       U I    S T U F F



		--------------------------------------------------------------
	*/
	getContextMenuItems(params) {
		let that = this;
		var result = [
			{
				name: "Fit columns",
				action: () => {
					this.fitColumns();
				}
			},
			{
				name: "Switch to " + params.node.data.instrumentId,
				action: () => {
					that.props.history.push("/product");
				},
				cssClasses: ["redFont", "bold"]
			}
		];
		return result;
	}

	render() {
		return (
			<div className="MarketGrid">
				<h5 className="ga-header">Market</h5>

				<div className={"ga-grid " + this.props.view_options.theme}>
					<AgGridReact
						autoSizePadding={6}
						domLayout="autoHeight"
						modules={AllModules}
						components={this.components}
						rowData={this.state.rowData}
						columnDefs={this.columnDefs}
						defaultColDef={this.defaultColDef}
						rowSelection={"single"}
						rowMultiSelectWithClick={true}
						rowDeselection={true}
						suppressRowClickSelection={false}
						onGridReady={this.onGridReady.bind(this)}
						onCellDoubleClicked={this.onCellDoubleClicked.bind(this)}
						onCellClicked={this.onCellClicked.bind(this)}
						getRowNodeId={data => {
							return data.instrumentId;
						}}
						allowContextMenuWithControlKey={true}
						getContextMenuItems={this.getContextMenuItems.bind(this)}
						context={{}}
					/>

					<PlaceOrderModal
						yax={this.props.yax}
						title={this.state.showModalBuyOrSell}
						instrumentId={this.state.showModalInstrumentId}
						showModal={this.state.showModal}
						showModalQty={this.state.showModalQty}
						showModalPrice={this.state.showModalPrice}
						showMe={() => {
							this.setState({ showModal: true });
						}}
						hideMe={() => {
							this.setState({ showModal: false });
						}}
					/>
				</div>
			</div>
		);
	}

	/*  --------------------------------------------------------------
    	GRID EVENTS
    	--------------------------------------------------------------
   */
	onGridReady = params => {
		this.rowCache = new Map();
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

		window.addEventListener("resize", this.fitColumns);

		this.props.yax.addInstrumentSubscriber("*", this.instrumentHandler);
	};

	// linkClicked(e) {
	// 	this.props.dispatch({
	// 		type: "select_instrument",
	// 		selectedInstrument: e.instrumentId
	// 	});
	// 	this.props.history.push("/product");
	// }

	selectInstrument(instr) {
		this.props.dispatch({
			type: "select_instrument",
			selectedInstrument: instr // TODO: Fix ..Id
		});
	}

	selectIndex(indexId, name) {
		this.props.dispatch({
			type: "select_index",
			selectedIndex: indexId,
			selectedIndexName: name
		});
	}

	onCellDoubleClicked(evt) {
		if (evt.type === "cellDoubleClicked") {
			this.selectInstrument(evt.data.instrumentId);
			// this.props.history.push("/product");

			let buyqty = evt.data.buy ? evt.data.buy.quantity : 0;
			let buyprice = evt.data.buy ? evt.data.buy.price : 0;
			let sellqty = evt.data.sell ? evt.data.sell.quantity : 0;
			let sellprice = evt.data.sell ? evt.data.sell.price : 0;

			this.setState({
				showModalInstrumentId: evt.data.instrumentId,
				showModalBuyOrSell: evt.column.colId.startsWith("buy.") ? "Sell" : "Buy",
				showModalQty: evt.column.colId.startsWith("buy.") ? buyqty : sellqty,
				showModalPrice: evt.column.colId.startsWith("buy.") ? buyprice : sellprice,
				showModal: true
			});
		}
	}

	onCellClicked(evt) {
		console.debug("*** GRID *** Clicked on " + evt.column.colId + " which is " + JSON.stringify(evt.data));

		this.props.dispatch({
			type: "select_instrument",
			selectedInstrument: evt.data.instrumentId // TODO: Fix ..Id
		});

		if (
			evt.column.colId === "sell.quantity" ||
			evt.column.colId === "sell.price" ||
			evt.column.colId === "buy.price" ||
			evt.column.colId === "buy.quantity"
		) {
			let bidsideclicked = evt.column.colId === "buy.quantity" || evt.column.colId === "buy.price";

			let bidask;
			let price;
			let qty;

			if (bidsideclicked) {
				bidask = "bid";
				price = evt.data.buy ? evt.data.buy.price : 0;
				qty = evt.data.buy ? evt.data.buy.quantity : 0;
			} else {
				bidask = "ask";
				price = evt.data.sell ? evt.data.sell.price : 0;
				qty = evt.data.sell ? evt.data.sell.quantity : 0;
			}

			this.props.dispatch({
				type: "select_market_order",
				selectedMarketOrder: {
					bidask: bidask,
					price: price,
					qty: qty
				}
			});
		}
	}

	fitColumns = () => {
		setTimeout(() => {
			// var w = window.innerWidth;
			// this.autoSizeAll();
			Utils.autoSizeAllColumnsInGrid(this.gridColumnApi);
			// this.gridApi.sizeColumnsToFit();
		});
	};

	dateComparator(date1, date2) {
		console.debug("*** GRID *** comparing " + date1 + " and " + date2);
		var date1Number = this.monthToComparableNumber(date1);
		var date2Number = this.monthToComparableNumber(date2);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}

	monthToComparableNumber(date) {
		if (date === undefined || date === null || date.length !== 10) {
			return null;
		}
		var yearNumber = date.substring(6, 10);
		var monthNumber = date.substring(3, 5);
		var dayNumber = date.substring(0, 2);
		var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
		return result;
	}
}

// function createFlagImg(flag) {
// 	return '<img border="0" width="15" height="10" src="https://flags.fmcdn.net/data/flags/mini/' + flag + '.png"/>';
//   }

// export default MarketGrid;
// definition of DummyComponent here...
const mapStateToProps = state => ({
	yax_instruments_loaded: state.yax_instruments_loaded,
	selectedIndex: state.selectedIndex,
	selectedIndexName: state.selectedIndexName,
	selectedInstrument: state.selectedInstrument,
	selectedMarketOrder: state.selectedMarketOrder,
	view_options: state.view_options
});
export default connect(mapStateToProps)(withRouter(MarketGrid));
