import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardImg, Cardbody, Cardtitle, Cardsubtitle, Cardtext, Badge, ButtonToolbar, Row, Col, Form, Button } from "react-bootstrap";

class NewUnderlying extends Component {
    constructor(props) {
        super(props);
        this.state = {
            instrumentId: "",
            instrumentName: "",
            country: "",
            publisher: "",
            site: "",
            position: "",
            iabCodes: "",

            dirty: false
        };

        this.onChangeField = this.onChangeField.bind(this);
    }

    componentDidMount() {
    }

    /*  ONE HANDLER FOR EACH FIELD
    */
    onChangeField(evt) {
        this.setState({
            ...this.state,
            dirty: true,
            [evt.target.name]: evt.target.value
        });
    }

    onSave(evt) {
        evt.preventDefault();
    }

    onCancel(evt) {
        evt.preventDefault();
    }


    render() {

        console.log(this.state);

        return (
            <Form className="demoForm">

                <div className="underlyingPart">

                    <Form.Row>

                        <Form.Group as={Col} xs={3} controlId="formFirm">
                            <Form.Label>InstrumentId</Form.Label>
                            <Form.Control value={this.state.instrumentId} name="instrumentName" onChange={this.onChangeField} />
                        </Form.Group>

                        <Form.Group as={Col} xs={3} controlId="formTradingPct">
                            <Form.Label>Instrument Name</Form.Label>
                            <Form.Control value={this.state.instrumentName} name="instrumentName" onChange={this.onChangeField} />
                        </Form.Group>

                    </Form.Row>

                    <Form.Row>
                        {!this.state.dirty ?
                            <Form.Group as={Col} controlId="formUnderlyingButtons">
                                <Button variant="primary" onClick={this.onCancel.bind(this)} type="submit">Close</Button>
                            </Form.Group>
                            :
                            <>
                                <Form.Group as={Col} controlId="formUnderlyingButtons">
                                    <ButtonToolbar>
                                        <Button style={{ marginRight: "10px" }} variant="primary" onClick={this.onSave.bind(this)} type="submit">Save</Button>
                                        <Button variant="secondary" onClick={this.onCancel.bind(this)} type="submit">Cancel</Button>
                                    </ButtonToolbar>
                                </Form.Group>
                            </>}
                    </Form.Row>

                </div>

            </Form >
        );
    }
}


const mapStateToProps = state => ({
    selectedInstrument: state.selectedInstrument
});
export default connect(mapStateToProps)(withRouter(NewUnderlying));
