import React, { Component } from "react";
import { connect } from "react-redux";
import IndexHistory from 'components/IndexHistory'
import PickIndex from 'components/PickIndex'

import "./IndexHistoryPage.scss";

class IndexHistoryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: "",
            selectedIndexName: ""
        };
    }
    render() {

        return (
            <div className="IndexHistoryPage">
                <div className="header">
                    <h5>Settlement Price History</h5>
                </div>
                <div className="trades">
                    {/* <QueryEventsTest yax={this.props.yax}/> */}
                    <PickIndex yax={this.props.yax} select={(instrId, name) => {
                        console.log("*** PGPG *** instrumentId:" + instrId + " name:" + name);
                        this.setState({ selectedIndex: instrId, selectedIndexName: name });
                    }} />
                    <IndexHistory yax={this.props.yax} selectedIndex={this.state.selectedIndex} selectedIndexName={this.state.selectedIndexName} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    selectedInstrument: state.selectedInstrument
});
export default connect(mapStateToProps)(IndexHistoryPage);
