import React, { Component } from 'react';
import ChangeUserPass from 'components/ChangeUserPass';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import SetUserProperties from './SetUserProperties';
import "./UserProfile.scss";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <Container className="UserProfile">
                <Row>
                    <h2>User profile</h2>
                </Row>
                <hr></hr>
                <Row>
                    <Col>
                        <ChangeUserPass firm={this.props.yax.getLoginFirm()} username={this.props.yax.getLoginUsername()} yax={this.props.yax} />
                    </Col>
                </Row>
                <hr></hr>
                <Row>
                    <Col>
                        <SetUserProperties yax={this.props.yax} />
                    </Col>
                </Row>

            </Container>
        );
    }
}

export default Profile;