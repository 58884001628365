import React, { Component } from "react";
import { connect } from "react-redux";
import "./MarketPage.scss";
import MarketGrid from "components/MarketGrid";
import MarketDepth from "components/MarketDepth";

class MarketPage extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         };
    }
    render() {

        return (
            <div className="MarketPage">
                <div className="leftcol"></div>
                <div className="rightcol"></div>
                <MarketGrid  classname="MarketGrid"  yax={this.props.yax} />
                <MarketDepth classname="MarketDepth" yax={this.props.yax} />
                <div className="footer"></div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
	selectedInstrument: state.selectedInstrument
});
export default connect(mapStateToProps)(MarketPage);
