// import LogRocket from 'logrocket';
import React, { Fragment, Component } from "react";
import { NavDropdown, Navbar, Nav, Form } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Redirect, Link } from "react-router-dom";

import Login from "./components/Login";
import HomeNew from "./components/HomeNew";

import YaxService from "./services/YaxService";

import ProductPage from "./pages/ProductPage";
import MarketPage from "./pages/MarketPage";
import PositionPage from "./pages/PositionPage";
import AccountPage from "./pages/AccountPage";
import PublicTradeTickerPage from "./pages/PublicTradeTickerPage";
import OwnTradesHistoryPage from "./pages/OwnTradesHistoryPage";
import IndexHistoryPage from "./pages/IndexHistoryPage";
import PlaygroundPage from "./pages/PlaygroundPage";
import UserPage from "./pages/UserPage";

/* Should these be 'pages' instead? */
import EditUser from "./components/EditUser";
import NewUser from "./components/NewUser";
import FirmPage from "./pages/FirmPage";
import NewFirm from "./components/NewFirm";
import EditFirm from "./components/EditFirm";
import SetUserPassword from "./components/SetUserPassword";
import Profile from "components/Profile";
import About from "components/About";
import MarketMessage from "components/MarketMessage";
import SetTradingState from "components/SetTradingState";

import { connect } from "react-redux";
import preval from "preval.macro";
import { Helmet } from "react-helmet";

import "./App.css";
import "./components/Navigation.css";

/* 2 different ways of playing sound
 */
import UIfx from "uifx";
import ann1 from "sounds/ann1.mp3";
import kaching from "sounds/kaching.mp3";
import { Howl, Howler } from "howler";

const sound = new Howl({
	src: ["kaching.mp3"]
});

/*  -------------------------------------------------------------------------
 *
 *
 *  -------------------------------------------------------------------------
 */
class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			authenticated: false,
			isadmin: false,
			server_version: "",
			user_type: "",
			connected: "Backend offline",
			market_announcement: ""
			/* temporary */
			// authenticated: sessionStorage.getItem('authenticated')
		};

		this.was_connected = false;

		// LogRocket.init('uicgbm/adx');
		this.yaxService = new YaxService(props.store);
		this.onConnectionChanged = this.onConnectionChanged.bind(this);
		this.onAuthenticationChanged = this.onAuthenticationChanged.bind(this);
		this.onMarketAnnouncement = this.onMarketAnnouncement.bind(this);
		this.onPrivateTrades = this.onPrivateTrades.bind(this);
		this.onSubscriptionStateChanged = this.onSubscriptionStateChanged.bind(this);
		this.logout = this.logout.bind(this);

		this.bell = new UIfx(ann1, {
			volume: 0.4, // number between 0.0 ~ 1.0
			throttleMs: 2000
		});

		this.kaching = new UIfx(kaching, {
			volume: 0.4, // number between 0.0 ~ 1.0
			throttleMs: 40
		});
	}

	logout() {
		this.yaxService.disconnect_ws();
	}

	onSubscriptionStateChanged(action, obj) {
		if (obj) {
			console.log("onSubscriptionStateChanged(" + obj.subscriptionId + " " + obj.state);
		}

		if (obj && obj.state === "LIVE" && obj.subscriptionId === "PrivateTradeEvent") {
			this.readyToPlaySounds = true;
			// this.bell.play();
		}
	}

	onMarketAnnouncement(action, obj) {
		if (obj) {
			console.log("onMarketAnnouncement:" + obj.text);
			if (this.yaxService.getUserPropertyAsBoolean("soundOnMarketMessage")) {
				var audio = new Audio(`${process.env.PUBLIC_URL}/ann2.mp3`);
				audio.play();
			}

			this.setState({ market_announcement: obj.text });

			/* clear message after 30s */
			setTimeout(
				function() {
					this.setState({ market_announcement: "" });
				}.bind(this),
				30000
			);
		}
	}

	/*
		Listen to trades only so that we can play KACHING when it happens!
	*/
	onPrivateTrades(action, obj) {
		// We skip "init" and "live"
		if (this.readyToPlaySounds) {
			if (action === "add") {
				if (this.yaxService.getUserPropertyAsBoolean("soundOnTrade")) {
					console.log("KA-CHING!");
					sound.play();
					// this.kaching.play();

					// var audio = new Audio(`${process.env.PUBLIC_URL}/kaching.mp3`);
					// audio.play();
				}
			}
		}
	}

	onConnectionChanged(conn, txt) {
		console.log("App.onConnectionChanged:" + txt + " connected:" + conn);
		this.setState({ connected: conn });

		/**
		 * @TODO: I don't think this works...
		 */
		if (!conn && this.was_connected) {
			this.was_connected = false;
			alert("Lost connection to backend!");
			this.setState(
				{
					authenticated: false
				},
				() => {
					this.forceUpdate();
				}
			); // should re-render!
		}
	}

	onAuthenticationChanged(auth, server_version, user_type) {
		console.log("onAuthenticationChanged, authorized:" + auth);
		this.setState({
			authenticated: auth,
			isadmin: this.yaxService.isAdmin(),
			server_version: server_version,
			user_type: user_type
		});
		/* TEMPORARY */
		// sessionStorage.setItem('authenticated', auth);
	}

	componentDidMount() {
		// this.setState({ authenticated: sessionStorage.getItem('authenticated') });
		this.yaxService.addConnectionSubscriber(this.onConnectionChanged);
		this.yaxService.addAuthenticationSubscriber(this.onAuthenticationChanged);

		this.yaxService.addSubscriptionEventSubscriber(this.onSubscriptionStateChanged);
		this.yaxService.addMarketAnnouncementSubscriber(this.onMarketAnnouncement);
		this.yaxService.addOwnTradesSubscriber(this.onPrivateTrades);

		this.yaxService.connect_ws();
	}

	componentWillUnmount() {
		// this.yaxService.disconnect_ws();
		this.yaxService.removeConnectionSubscriber(this.onConnectionChanged);
		this.yaxService.removeAuthenticationSubscriber(this.onAuthenticationChanged);
	}

	render() {
		// const PrivateRoute = ({ component: Component, ...rest }) => (
		//   <Route {...rest} render={(props) => (
		//     this.state.authenticated === true
		//       ? <Component {...props} />
		//       : <Redirect to='/login' />
		//   )} />
		// )

		let admin_nav = admin => {
			if (admin) {
				return (
					<NavDropdown title="Admin" id="nav-dropdown">
						<NavDropdown.Item as={Link} to="/firms">
							Firms
						</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/users">
							Users
						</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/tradingstate">
							Set Trading State
						</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/marketmessage">
							Send Market Message
						</NavDropdown.Item>
					</NavDropdown>
				);
			}
		};

		let usersPage = () => {
			if (this.yaxService.isAdmin()) {
				return (
					<Nav.Link as={Link} to="/users">
						Users
					</Nav.Link>
				);
			} else {
				return <></>;
			}
		};

		let firmsPage = () => {
			if (this.yaxService.isAdmin()) {
				return (
					<Nav.Link as={Link} to="/firms">
						Firms
					</Nav.Link>
				);
			} else {
				return <></>;
			}
		};

		let nav = (auth, isadmin) => {
			if (auth) {
				return (
					<Fragment>
						<div className="topbar">
							<a style={{ backgroundColor: "red", color: "white" }}>{this.state.market_announcement}</a>
							<span float="right">
								{this.props.user.firm}/{this.props.user.username} ({this.state.user_type}) on{" "}
								{process.env.REACT_APP_SERVERURL}
							</span>
						</div>
						<Navbar bg="dark" variant="dark">
							<Navbar.Brand to="/home">YAX</Navbar.Brand>
							<Nav className="mr-auto">
								<Nav.Link as={Link} to="/home">
									Home
								</Nav.Link>
								<Nav.Link as={Link} to="/product">
									Product View
								</Nav.Link>
								<Nav.Link as={Link} to="/account">
									{this.yaxService.isAdmin() ? "Risk" : "Account"}
								</Nav.Link>

								{/* {firmsPage()}
								{usersPage()} */}

								<NavDropdown title="Data" id="nav-dropdown">
									<NavDropdown.Item as={Link} to="/ticker">
										Ticker
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to="/tradehistory">
										Trade History
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to="/indexhistory">
										Settlement Price History
									</NavDropdown.Item>
								</NavDropdown>

								{admin_nav(isadmin)}
							</Nav>
							<Nav>
								<Nav.Link as={Link} to="/profile">
									Profile
								</Nav.Link>

								<Nav.Link as={Link} onClick={this.logout} to="/">
									Logout
								</Nav.Link>

								<Nav.Link as={Link} to="/about">
									About {global.appVersion}
								</Nav.Link>
							</Nav>

							{/* <Form className="smallform" inline>
								Version: {global.appVersion}  Build Date:{" "}
								{preval`module.exports = new Date().toLocaleString('sv-SE');`}
								<br></br> Server version:
								{this.state.server_version}
							</Form> */}
						</Navbar>
					</Fragment>
				);
			}
		};

		let login = !this.state.authenticated ? <Redirect to="/login" /> : null;

		return (
			<div id="App" className="App">
				<Router>
					{nav(this.state.authenticated, this.state.isadmin)}

					{/* A <Switch> looks through its children <Route>s and renders the first one that matches the current URL. */}

					<Switch>
						<Route exact path="/login">
							{this.state.authenticated ? <Redirect to="/home" /> : <Login yax={this.yaxService} />}
						</Route>

						<Route exact path="/home">
							{!this.state.authenticated ? <Redirect to="/login" /> : <HomeNew yax={this.yaxService} />}
						</Route>

						<Route exact path="/homenew">
							{!this.state.authenticated ? <Redirect to="/login" /> : <HomeNew yax={this.yaxService} />}
						</Route>

						<Route exact path="/product">
							{!this.state.authenticated ? (
								<Redirect to="/login" />
							) : (
								<ProductPage yax={this.yaxService} />
							)}
						</Route>

						<Route exact path="/position">
							{!this.state.authenticated ? (
								<Redirect to="/login" />
							) : (
								<PositionPage yax={this.yaxService} />
							)}
						</Route>

						<Route exact path="/market">
							{!this.state.authenticated ? (
								<Redirect to="/login" />
							) : (
								<MarketPage yax={this.yaxService} />
							)}
						</Route>

						<Route exact path="/account">
							{!this.state.authenticated ? (
								<Redirect to="/login" />
							) : (
								<AccountPage yax={this.yaxService} />
							)}
						</Route>

						<Route exact path="/profile">
							{!this.state.authenticated ? <Redirect to="/login" /> : <Profile yax={this.yaxService} />}
						</Route>

						<Route exact path="/users">
							{!this.state.authenticated ? <Redirect to="/login" /> : <UserPage yax={this.yaxService} />}
						</Route>

						<Route exact path="/firms">
							{!this.state.authenticated ? <Redirect to="/login" /> : <FirmPage yax={this.yaxService} />}
						</Route>

						<Route exact path="/new-firm">
							{!this.state.authenticated ? <Redirect to="/login" /> : <NewFirm yax={this.yaxService} />}
						</Route>

						<Route exact path="/edit-firm">
							{!this.state.authenticated ? <Redirect to="/login" /> : <EditFirm yax={this.yaxService} />}
						</Route>

						<Route exact path="/set-user-password">
							{!this.state.authenticated ? (
								<Redirect to="/login" />
							) : (
								<SetUserPassword yax={this.yaxService} />
							)}
						</Route>

						<Route exact path="/edit-user">
							{!this.state.authenticated ? <Redirect to="/login" /> : <EditUser yax={this.yaxService} />}
						</Route>

						<Route exact path="/new-user">
							{!this.state.authenticated ? <Redirect to="/login" /> : <NewUser yax={this.yaxService} />}
						</Route>

						<Route exact path="/ticker">
							{!this.state.authenticated ? (
								<Redirect to="/login" />
							) : (
								<PublicTradeTickerPage yax={this.yaxService} />
							)}
						</Route>

						<Route exact path="/tradehistory">
							{!this.state.authenticated ? (
								<Redirect to="/login" />
							) : (
								<OwnTradesHistoryPage yax={this.yaxService} />
							)}
						</Route>

						<Route exact path="/indexhistory">
							{!this.state.authenticated ? (
								<Redirect to="/login" />
							) : (
								<IndexHistoryPage yax={this.yaxService} />
							)}
						</Route>

						<Route exact path="/about">
							{!this.state.authenticated ? (
								<Redirect to="/login" />
							) : (
								<About yax={this.yaxService} server_version={this.state.server_version} />
							)}
						</Route>

						<Route exact path="/playground">
							{!this.state.authenticated ? (
								<Redirect to="/login" />
							) : (
								<PlaygroundPage yax={this.yaxService} />
							)}
						</Route>

						<Route exact path="/marketmessage">
							{!this.state.authenticated ? (
								<Redirect to="/login" />
							) : (
								<MarketMessage yax={this.yaxService} />
							)}
						</Route>

						<Route exact path="/tradingstate">
							{!this.state.authenticated ? (
								<Redirect to="/login" />
							) : (
								<SetTradingState yax={this.yaxService} />
							)}
						</Route>

						<Route>{!this.state.authenticated ? <Redirect to="/login" /> : <Redirect to="/" />}</Route>

						{login}
					</Switch>
				</Router>
			</div>
		);
	}
}

// export default App;
const mapStateToProps = state => ({
	selectedInstrument: state.selectedInstrument,
	user: state.user
});
export default connect(mapStateToProps)(App);
